import { createStyles } from '../../../../../../../../../styles/helpers'

export default createStyles(theme => ({
  competingTable: {
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: 'none',
    borderRadius: 0,
    marginTop: 20
  },
  competingRow: {
    minHeight: '40px !important',
    fontSize: 12,
    paddingLeft: 0,
    paddingRight: 0
  },
  periodsCol: {
    width: '100%',
    whiteSpace: 'normal',
    display: 'flex',
    flexDirection: 'column'
  },
  linkIcon: {
    color: theme.brandPrimary,

    '&:hover': {
      color: theme.brandPrimaryHover
    }
  }
}))
