export const URL_FORM_PARAMS = {
  FORM_NAME: 'form',
  ITEM_ID: 'itemId'
}

// RADIO OPTIONS
export const OPTION_CUSTOM = 'option_custom'
export const OPTION_YES = 'option_yes'
export const OPTION_NO = 'option_no'

// these constants used both for redux and query params in URL for create/edit state
// (don't remove or change next line) as it is used as regex pattern in plop.js:
// PLOP_APPEND_PATTERN_ANCHOR
export const QUOTATION_CREATE = 'quotationCreate'
export const MEDIA_ORDER_CREATE_SIMPLE = 'mediaOrderCreateSimple'
export const MEDIA_ORDER_HISTORY = 'mediaOrderHistory'
export const AGENCY_EDIT = 'agencyEdit'
export const AGENCY_CREATE = 'agencyCreate'
export const PRICE_CHANGE_PERIOD_CREATE = 'priceChangePeriodCreate'
export const PRICE_CHANGE_PERIOD_EDIT = 'priceChangePeriodEdit'
export const DISCOUNT_PRESET_CREATE = 'discountPresetCreate'
export const DISCOUNT_PRESET_EDIT = 'discountPresetEdit'
export const MEDIA_PRODUCT_LOCATION_CREATE = 'mediaProductLocationCreate'
export const MEDIA_PRODUCT_LOCATION_EDIT = 'mediaProductLocationEdit'
export const MEDIA_PRODUCT_VARIABLE_CREATE = 'mediaProductVariableCreate'
export const MEDIA_PRODUCT_VARIABLE_EDIT = 'mediaProductVariableEdit'

export const DOOHLY_CAMPAIGN_CREATE = 'doohlyCampaignCreate'
export const EDIT_SELF_ACCOUNT_TAG = 'editSelfAccountTag'
export const CREATE_SELF_ACCOUNT_TAG = 'createSelfAccountTag'

export const EDIT_PRODUCT_TAGS = 'editProductTags'
export const CREATE_PRODUCT_TAGS = 'createProductTags'
export const INVOICE_INFORMATION_FORM = 'invoiceInformationForm'
export const ONSITE_DIGITAL_FORMS = 'onsiteDigitalForms'

// CAMPAIGNS:
export const CAMPAIGN_FACEBOOK_CREATE = 'campaignFacebookCreate'
export const CAMPAIGN_FACEBOOK_EDIT = 'campaignFacebookEdit'
export const CAMPAIGN_FACEBOOK_DUPLICATE = 'campaignFacebookDuplicate'
export const CAMPAIGN_GOOGLE_ADS_EDIT = 'campaignGoogleEdit'
export const CAMPAIGN_GOOGLE_ADS_CREATE = 'campaignGoogleAdsCreate'
export const CAMPAIGN_TIKTOK_CREATE = 'campaignTikTokCreate'
export const CAMPAIGN_TIKTOK_EDIT = 'campaignTikTokEdit'

// LINE ITEMS:
export const LINE_ITEM_FACEBOOK_CREATE = 'lineItemFacebookCreate'
export const LINE_ITEM_FACEBOOK_CREATE_OLD = 'lineItemFacebookCreateOld'
export const LINE_ITEM_FACEBOOK_EDIT = 'lineItemFacebookEdit'
export const LINE_ITEM_FACEBOOK_DUPLICATE = 'lineItemFacebookDuplicate'
export const LINE_ITEM_GOOGLE_ADS_CREATE = 'lineItemGoogleAdsCreate'
export const LINE_ITEM_GOOGLE_ADS_EDIT = 'lineItemGoogleAdsEdit'
export const LINE_ITEM_TIKTOK_CREATE = 'lineItemTikTokCreate'
export const LINE_ITEM_TIKTOK_EDIT = 'lineItemTikTokEdit'

// ADS:
export const AD_FACEBOOK_CREATE = 'adFacebookCreate'
export const AD_FACEBOOK_EDIT = 'adFacebookEdit'
export const ASSET_GROUP_GOOGLE_ADS_CREATE = 'performanceMaxAdCreate'
export const AD_GOOGLE_CREATE = 'adGoogleCreate'
export const AD_GOOGLE_EDIT = 'adGoogleEdit'
export const ASSET_GROUP_GOOGLE_ADS_EDIT = 'performanceMaxAdEdit'
export const AD_TIK_TOK_CREATE = 'adTikTokCreate'
export const AD_TIK_TOK_EDIT = 'adTikTokEdit'

export const DISAPPROVE_INTERNAL_AD = 'disapproveInternalAd'

// OTHER
export const REPORT_FACEBOOK_CREATE = 'reportFacebookCreate'
export const REPORT_FACEBOOK_EDIT = 'reportFacebookEdit'
export const AUDIENCE_CREATE = 'audienceCreate'
// CALENDAR PLANNER
export const PLANNER_EVENT_GROUP_CREATE = 'plannerEventGroupCreate'
export const PLANNER_EVENT_GROUP_EDIT = 'plannerEventGroupEdit'
export const PLANNER_EVENT_CREATE = 'plannerEventCreate'
export const PLANNER_EVENT_EDIT = 'plannerEventEdit'
export const PLANNER_GROUP_EDIT = 'plannerEventGroupEdit'

// MEDIA ORDER
export const UPLOAD_FACEBOOK_CREATIVE = 'uploadFacebookCreative'
export const UPLOAD_GOOGLE_AD_CREATIVE = 'uploadGoogleCreative'
export const UPLOAD_TIKTOK_AD_CREATIVE = 'uploadTikTokAdCreative'
export const UPLOAD_DV360_AD_CREATIVE = 'uploadDV360AdCreative'
export const UPLOAD_CREATIVE_CAMPAIGN_CREATE = 'uploadCreativeCampaignCreate'
export const UPLOAD_CREATIVE_GOOGLE_CAMPAIGN_DETAILS = 'uploadCreativeGoogleCampaignDetails'
export const UPLOAD_BOOKED_MEDIA_FILES = 'uploadBookedMediaFiles'
export const SIGN_CONTRACT = 'signContract'

export const UPLOAD_ON_SITE_CREATIVE = 'uploadOnSiteCreative'
// SETTINGS
export const CONTROLLER_PERMISSIONS_EDIT = 'controllerPermissionsEdit'
export const CONTROLLER_MEMBER_EDIT = 'controllerMemberEdit'
export const AGENCY_PERMISSIONS_EDIT = 'agencyPermissionsEdit'
export const SELF_ACCOUNT_PERMISSIONS_EDIT = 'selfAccountPermissionsEdit'
// LOCATION LIST
export const LOCATION_LIST_CREATE = 'locationListCreate'
export const LOCATION_LIST_EDIT = 'locationListEdit'
// TEAM MEMBERS
export const MEMBER_CHANGE_DATA = 'MEMBER_CHANGE_DATA'
export const MEMBER_CHANGE_STATUS = 'MEMBER_CHANGE_STATUS'
export const TEAM_MEMBER_CREATE = 'teamMemberCreate'
// AGENCY INVITES
export const AGENCY_INVITE_CREATE = 'agencyInviteCreate'
// CONTROLLER INVITES
export const CONTROLLER_INVITE_CREATE = 'controllerInviteCreate'
// CLIENT ACCOUNTS
export const CLIENT_ACCOUNT_CREATE = 'clientAccountCreate'
export const CLIENT_ACCOUNT_WITH_MARGINS_CREATE = 'clientAccountWithMarginsCreate'
export const CLIENT_ACCOUNT_EDIT = 'clientAccountEdit'
// TRIGGERS
export const TRIGGER_CREATE = 'triggerCreate'
export const TRIGGER_EDIT = 'triggerEdit'
// MARGINS
export const MARGIN_CREATE = 'marginCreate'

// AUTOMATION RULE
export const AUTOMATION_RULE_CREATE = 'automationRuleCreate'
// MEDIA ORDERS
export const MEDIA_ORDER_CREATE = 'mediaOrderCreate'
export const UPLOAD_MEDIA_ORDER_FILE = 'uploadMediaOrderFile'
export const MEDIA_ORDER_CONTRACT_CREATE = 'mediaOrderContractCreate'
// QUOTATIONS
export const PROPOSAL_REVIEW = 'proposalReview'
export const QUOTATION_SEND = 'quotationSend'
// AMENDMENTS
export const AMENDMENT_REVIEW = 'amendmentReview'
export const MEDIA_ORDER_AMEND = 'mediaOrderAmend'
export const AMENDMENT_CONTRACT_SEND = 'amendmentContractSend'
export const MEDIA_ORDER_AMEND_CONTRACT_CREATE = 'mediaOrderAmendContractCreate'
// PRODUCTS
export const PRODUCT_CREATE = 'productCreate'
export const PRODUCT_EDIT = 'productEdit'
export const MEDIA_PRODUCT_GROUP_CREATE = 'mediaProductGroupCreate'
export const MEDIA_PRODUCT_GROUP_EDIT = 'mediaProductGroupEdit'
// PRODUCT INVENTORY
export const INVENTORY_UPDATE = 'inventoryUpdate'
export const INVENTORY_CREATE = 'inventoryCreate'
export const INVENTORY_BATCH_UPDATE = 'inventoryBatchUpdate'
// MEDIA PACKAGES
export const MEDIA_PACKAGE_EDIT = 'mediaPackageEdit'
export const MEDIA_PACKAGE_CREATE = 'mediaPackageCreate'
// MEDIA CATEGORIES
export const MEDIA_CATEGORY_CREATE = 'mediaCategoryCreate'
export const MEDIA_CATEGORY_EDIT = 'mediaCategoryEdit'

// FACEBOOK PAGES
export const ACCESSIBLE_FACEBOOK_PAGE_CREATE = 'accessibleFacebookPageCreate'
export const REQUEST_FACEBOOK_PAGE_ACCESS = 'requestFacebookPageAccess'

// TIKTOK IDENTITIES
export const IDENTITY_TIK_TOK_CREATE = 'identityTikTokCreate'

export const FACEBOOK_PAGE = 'facebookPage'
export const PIXEL = 'pixel'

export const MANAGE_INVENTORY = 'manageInventory'
export const SHOPTIMISE_OPTIMISATION = 'shoptimiseOptimisation'

export const MANAGE_CONTROLLER = 'manageController'

export const LOGIN_MFA_FORM = 'loginMFAForm'
export const SETUP_PHONE_NUMBER_FORM = 'setupPhoneNumberForm'
export const VERIFY_PHONE_NUMBER_FORM = 'verifyPhoneNumberPhone'
export const VERIFY_TOTP_TOKEN_FORM = 'verifyTOTPTokenForm'
export const SET_MFA_TYPE_FORM = 'setMfaTypeForm'
// CATEGORY PAGES
export const CATEGORY_PAGE_CREATE = 'categoryPageCreate'
export const CATEGORY_PAGE_EDIT = 'categoryPageEdit'
export const SPECIFICATIONS_DOCUMENT_CREATE = 'specificationsDocumentCreate'
export const SPECIFICATIONS_DOCUMENT_EDIT = 'specificationsDocumentEdit'
export const PROPOSAL_MEDIA_ORDER_RECIPIENTS = 'proposalMediaOrderRecipients'
export const UPDATE_MEDIA_ORDER_RECIPIENTS = 'updateMediaOrderRecipients'

export const AUDIENCE_SUGGESTION_TYPE = {
  WEBSITE_ALL_VISITORS: 'websiteAllVisitors',
  WEBSITE_TOP_25: 'websiteTop25',
  FACEBOOK_PAGE_ENGAGERS: 'facebookPageEngagers'
}

export const MANAGE_DOOHLY_API_KEY = 'manageDoohlyApiKey'
