import { useSelector } from 'react-redux'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'

import MultiSelectBox from '../../../../../../../../features/components/Form/MultiSelectBox'

import { formatOptionsList } from '../../../../../../../../features/formatters'

import { selectedControllerIdSelector } from '../../../../../../../../modules/selectors/app'
import { getMediaProductAttachedFilesService } from '../../../../../../../../modules/services/mediaOrdersProducts'

import { ATTACHED_FILES } from '../../fields'

const formatOptions = options =>
  formatOptionsList({
    list: options,
    valueName: 'id',
    labelName: 'file_name'
  })

const AttachedFilesPaginatedMultiSelect = ({ formik }) => {
  const controllerId = useSelector(selectedControllerIdSelector)

  const { values, setFieldValue, errors, touched } = formik

  const [fetchedMediaProductAttachedFiles, setFetchedMediaProductAttachedFiles] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  const formattedMediaProductAttachedFiles = useMemo(
    () => formatOptions(fetchedMediaProductAttachedFiles),
    [fetchedMediaProductAttachedFiles]
  )

  const params = useMemo(
    () => ({
      ordering: 'name',
      controller: controllerId,
      fields: ['id', 'name'].join(',')
    }),
    [controllerId]
  )

  // const loadOptions = useMemo(
  //   () =>
  //     getLoadOptionsHandler({
  //       getOptionsService: getMediaProductAttachedFilesService,
  //       params,
  //       formatOptions,
  //       limit: 999
  //     }),
  //   [params]
  // )

  // Now BE does not support search on this endpoint, so we just fetch 999 items, and allow FE search
  // todo SHOP-2456 - implement pagination and BE search when BE support search
  const loadOptions = useCallback(async () => {
    setIsLoading(true)
    const response = await getMediaProductAttachedFilesService({
      ...params,
      limit: 999
    })
    setIsLoading(false)

    const newOptions = formatOptions(response.results)
    setFetchedMediaProductAttachedFiles(newOptions)
  }, [params])

  useEffect(() => {
    loadOptions()
  }, [params, loadOptions])

  return (
    <MultiSelectBox
      placeholder="Select attached files"
      name={ATTACHED_FILES}
      value={getIn(values, ATTACHED_FILES)}
      error={getIn(errors, ATTACHED_FILES)}
      touched={getIn(touched, ATTACHED_FILES)}
      // loadOptions={loadOptions}
      options={formattedMediaProductAttachedFiles}
      isLoading={isLoading}
      setFieldValue={setFieldValue}
      expandMenu={true}
    />
  )
}

AttachedFilesPaginatedMultiSelect.propTypes = {
  formik: PropTypes.object.isRequired
}

export default AttachedFilesPaginatedMultiSelect
