import { createStyles } from '../../../../../styles/helpers'

import { MEDIA_ORDER_ACTIONS_MAX_WIDTH } from '../../../../../pages/MediaOrderSummary/MediaOrderSummaryContent/DetailsSection/ContractDetails/ContractActions/styles'
import { tabletDown } from '../../../../../styles/const/breakpoints'

const useStyles = createStyles({
  topMargin: {
    marginTop: 32
  },
  additionalFieldsContainer: {
    display: 'flex',
    maxWidth: props =>
      props?.reservePlaceForMediaOrderActions ? `calc(100% - ${MEDIA_ORDER_ACTIONS_MAX_WIDTH}px)` : 'unset',
    marginBottom: 32,
    gap: 16
  },
  additionalFieldsInnerWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 16
  },
  brandSelect: {
    width: 200
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  errorClassName: {
    position: 'absolute' // field don't jump when the error
  },
  [`@media screen and (${tabletDown})`]: {
    additionalFieldsContainer: {
      maxWidth: 'unset'
    }
  }
})

export default useStyles
