import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import CompetingQuotations from './CompetingQuotations'
import DatePickerPeriodSelector from './EditPeriods/DatePickerPeriodSelector'
import CustomDatePickerPeriodSelector from './EditPeriods/CustomDatePeriodSelector'

import { ContractBuilderContext } from '../../ContractBuilderContext'
import { SelectPeriodsContextProvider } from '../SelectPeriodsContext'

import { generateGroupLabel } from './helpers'
import { isValidPeriods } from '../helpers/periods'

import { selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'

import { MEDIA_PRODUCTS, PRODUCT_GROUPED_PUBLICATIONS_DATES, PRODUCT_PERIODS_DATES, QUANTITY } from '../../../fields'
import { AVAILABLE_QUANTITY } from './EditPeriods/DatePickerPeriodSelector/hooks/useInventoryPeriods'

import useStyles from './styles'

const DatesCell = ({ formik, productValues, productIndex, isEditMode, checkInventory, isAmendment }) => {
  const classes = useStyles()

  const { contractId } = useContext(ContractBuilderContext)
  const selectedController = useSelector(selectedControllerDataSelector)
  const supportCustomDates = selectedController?.custom_booking_dates

  const productData = productValues.data
  const isProductSelected = !!productData
  const productPath = `${MEDIA_PRODUCTS}[${productIndex}]`
  const periodsPath = `${productPath}.${PRODUCT_PERIODS_DATES}`
  const selectedQuantity = productValues[QUANTITY]
  const selectedPeriods = productValues[PRODUCT_PERIODS_DATES]
  const selectedPeriodsGroups = productValues[PRODUCT_GROUPED_PUBLICATIONS_DATES]

  const mediaOrderProductId = productData?.mediaOrderProductId

  const competingPeriods = useMemo(() => {
    if (selectedPeriods) {
      return selectedPeriods.filter(period => {
        const competingQuantity = period.competing_quotation?.competing_quotation_quantity

        if (competingQuantity) {
          // if there are any inventory periods where selected quantity on proposal + sum of quantity of
          // competing quotations is > available inventory for that period
          return competingQuantity + selectedQuantity > period[AVAILABLE_QUANTITY]
        } else {
          return false
        }
      })
    } else {
      return []
    }
  }, [selectedPeriods, selectedQuantity])

  if (!isProductSelected) {
    return null
  }

  const hasCompetingPeriods = competingPeriods.length > 0

  return (
    <div className={classes.datesCol}>
      <div>
        {isEditMode ? (
          <SelectPeriodsContextProvider
            contractId={contractId}
            productData={productValues.data}
            allowToFetchPreviousPeriods={isAmendment}
            handleCompetingQuotations={!isAmendment}
            // mediaOrderProductId used only for Amendments to fulfill the inevntory with booked products whihin the order
            mediaOrderProductId={isAmendment ? mediaOrderProductId : null}
          >
            {supportCustomDates ? (
              <CustomDatePickerPeriodSelector
                formik={formik}
                isNewProduct={productValues.isNewProduct}
                selectedPeriods={productValues[PRODUCT_PERIODS_DATES]}
                productItemPath={productPath}
                productPublicationsDatesPath={periodsPath}
                checkInventory={checkInventory}
              />
            ) : (
              <DatePickerPeriodSelector
                formik={formik}
                isNewProduct={productValues.isNewProduct}
                selectedPeriods={productValues[PRODUCT_PERIODS_DATES]}
                productItemPath={productPath}
                productPublicationsDatesPath={periodsPath}
                checkInventory={checkInventory}
              />
            )}
          </SelectPeriodsContextProvider>
        ) : selectedPeriodsGroups?.length ? (
          <ul className={classes.selectedDatesList}>
            {selectedPeriodsGroups.map(group => {
              const isAvailable = checkInventory ? isValidPeriods(group, selectedQuantity) : true

              const label = generateGroupLabel(group)

              // the li element is used for selector of copy functionality in formatTableContentToSheet helper
              return (
                <li className={classnames({ [classes.isNotAvailable]: !isAvailable })} key={label}>
                  {label}
                </li>
              )
            })}
          </ul>
        ) : (
          '-'
        )}
      </div>
      {!isEditMode && !isAmendment && hasCompetingPeriods && (
        <CompetingQuotations competingPeriods={competingPeriods} productPeriod={productData.period} />
      )}
    </div>
  )
}

DatesCell.propTypes = {
  formik: PropTypes.object,
  productValues: PropTypes.object,
  productIndex: PropTypes.number,
  isEditMode: PropTypes.bool,
  checkInventory: PropTypes.bool,
  isAmendment: PropTypes.bool
}

export default DatesCell
