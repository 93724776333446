import { addDays } from 'date-fns'
import {
  getEndAdjustedDate,
  getStartAdjustedDate
} from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/helpers'
import { getOverlappingDays } from '../../../../../../../../../helpers/date'

export function findSinglePeriodCovering({ availablePeriods, additionalAvailableDays, dateRangeStart, dateRangeEnd }) {
  return availablePeriods.find((period, index) => {
    const startPeriodDate = new Date(period.date_start)
    startPeriodDate.setHours(0, 0, 0, 0)

    const endPeriodDate = new Date(period.date_end)
    endPeriodDate.setHours(23, 59, 59, 999)

    // Adjusted period dates with additionalAvailableDays
    const adjustedStartDate = getStartAdjustedDate(startPeriodDate, additionalAvailableDays)
    const adjustedEndDate = getEndAdjustedDate(endPeriodDate, additionalAvailableDays)

    // Check if the selected date range is entirely within adjusted period
    const isPeriodWithinSelectedRange = isWithinSelectedRange(
      adjustedStartDate,
      adjustedEndDate,
      dateRangeStart,
      dateRangeEnd
    )
    if (isPeriodWithinSelectedRange) {
      // inventory period chosen should be the one where most of the days fall in the actual(original) dates of
      // the period. In case where it has an equal foothold in both inventory periods, pick the earlier period.
      const nextPeriod = availablePeriods[index + 1]
      return nextPeriod
        ? isBestCoveringPeriod({
            nextPeriod,
            dateRangeStart,
            dateRangeEnd,
            startPeriodDate,
            endPeriodDate,
            additionalAvailableDays
          })
        : true
    } else {
      return false
    }
  })
}

export function findMultiplePeriodsCovering({
  availablePeriods,
  additionalAvailableDays,
  dateRangeStart,
  dateRangeEnd
}) {
  // There are availablePeriods - these array of periods with date_start and date_end.
  //   There is Date-range component where user may select date range between all availablePeriods.
  //   Based on selected date-range there should be selected only periods which overlaps inside the date range.
  //
  //   Also there are `additionalAvailableDays` that is amount of dates which could be added on the beginning or end of the period and also allow to cover it, for example additionalAvailableDays = 1 and there are periods:
  //   period_1: 04-12-2024 to 10-12-2024
  // period_2: 11-12-2024t to 17-12-2024
  // period_3: 18-12-2024t to 24-12-2024
  //
  // Case 1: User select date range 11-12-2024t to 20-12-2024
  // There should be selected period_2 and period_3
  //
  // Case 2: User select date range 08-12-2024t to 20-12-2024
  // There should be selected period_1 and period_2 and period_3
  //
  // Case 3: User select date range 11-12-2024t to 18-12-2024
  // As the additionalAvailableDays = 1 period_2 covers the 18-12-2024 too, so no need to select period_3
  //
  // Case 4:  User select date range 10-12-2024 to 18-12-2024
  // As the additionalAvailableDays = 1 - period_2 covers the 10-12-2024 18-12-2024 too, so no need to select period_1 and period_3
  const adjustedPeriods = availablePeriods.map(period => {
    const startPeriodDate = new Date(period.date_start)
    startPeriodDate.setHours(0, 0, 0, 0)

    const endPeriodDate = new Date(period.date_end)
    endPeriodDate.setHours(23, 59, 59, 999)

    // Adjusted period dates with additionalAvailableDays
    const adjustedStartDate = getStartAdjustedDate(startPeriodDate, additionalAvailableDays)
    const adjustedEndDate = getEndAdjustedDate(endPeriodDate, additionalAvailableDays)

    return {
      periodData: period,
      periodAvailableStartDate: adjustedStartDate,
      periodAvailableEndDate: adjustedEndDate,
      startPeriodDate, // Unadjusted start date
      endPeriodDate // Unadjusted end date
    }
  })

  // Sort the adjusted periods by their available start dates
  adjustedPeriods.sort((a, b) => a.periodAvailableStartDate - b.periodAvailableStartDate)

  const selectedPeriods = [] // Array to store the final selected periods
  let currentDate = new Date(dateRangeStart)
  currentDate.setHours(0, 0, 0, 0) // Normalize to start of the day

  let index = 0 // Index to iterate through the sorted adjusted periods

  while (currentDate <= dateRangeEnd) {
    let bestPeriod = null

    // Iterate through the adjusted periods to find the period that starts on or before the currentDate
    // and extends the coverage the farthest
    while (index < adjustedPeriods.length && adjustedPeriods[index].periodAvailableStartDate <= currentDate) {
      const period = adjustedPeriods[index]
      if (!bestPeriod || period.periodAvailableEndDate > bestPeriod.periodAvailableEndDate) {
        bestPeriod = period
      }
      index++
    }

    // If no suitable period is found to cover the currentDate, the date range cannot be fully covered
    if (!bestPeriod) {
      console.warn('No available period covers the date:', currentDate)
      break // Exit the loop as coverage is incomplete
    }

    // Add the original period (without adjusted dates) to the selectedPeriods
    selectedPeriods.push(bestPeriod)

    // Move the currentDate to the day after the bestPeriod's adjusted end date
    currentDate = addDays(bestPeriod.periodAvailableEndDate, 1)
  }
  // Return the filtered list of selected periods
  return selectedPeriods
}

function isWithinSelectedRange(adjustedStartDate, adjustedEndDate, dateRangeStart, dateRangeEnd) {
  return adjustedStartDate.getTime() <= dateRangeStart.getTime() && adjustedEndDate.getTime() >= dateRangeEnd.getTime()
}

function isBestCoveringPeriod({
  nextPeriod,
  dateRangeStart,
  dateRangeEnd,
  startPeriodDate,
  endPeriodDate,
  additionalAvailableDays
}) {
  const nextPeriodStartDate = new Date(nextPeriod?.date_start)
  const nextPeriodEndDate = new Date(nextPeriod?.date_end)

  // Adjusted next period dates with additionalAvailableDays
  const adjustedStartDate = getStartAdjustedDate(nextPeriodStartDate, additionalAvailableDays)
  const adjustedEndDate = getEndAdjustedDate(nextPeriodEndDate, additionalAvailableDays)
  const isNextPeriodWithinSelectedRange = isWithinSelectedRange(
    adjustedStartDate,
    adjustedEndDate,
    dateRangeStart,
    dateRangeEnd
  )

  if (isNextPeriodWithinSelectedRange) {
    // handle case when 2 periods may cover the same dateRange, for example there are:
    // period_1: 18 Nov - 1 Dec
    // period_2: 2 Dec - 15 Dec
    // The date range selected: 01 Dec - 03 Dec - this period is 2 days after the start of period_2 and 1 day at
    // period_1, but <= the “before percentage days” which covers id. In this case we check the original periods,
    // so it has 2 days in original dates in period_2 and only 1 day in period_1 - so we select period_2: 2 Dec - 15 Dec

    const coveredDaysInPeriod = getOverlappingDays(
      { start: dateRangeStart, end: dateRangeEnd },
      { start: startPeriodDate, end: endPeriodDate }
    )
    const coveredDaysInNextPeriod = getOverlappingDays(
      { start: dateRangeStart, end: dateRangeEnd },
      { start: nextPeriodStartDate, end: nextPeriodEndDate }
    )

    // where it has an equal foothold in both inventory periods - pick the earlier inventory period(>=).
    return coveredDaysInPeriod >= coveredDaysInNextPeriod
  } else {
    return true
  }
}
