import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Dropdown from '../Dropdown'
import PseudoSelect from '../PseudoSelect'

import { useStyles } from '../Select/styles'

// SelectDropdown inherits all Select styles
const SelectDropdown = ({
  className,
  dropdownClassName,
  value,
  title,
  children,
  isSmall = true,
  onVisibilityChange,
  isManuallyOpened,
  isDisabled,
  isHighlighted,
  ...props
}) => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = useState(isManuallyOpened || false)
  const [isHovered, setIsHovered] = useState(false)

  const handleDropdownVisibility = useCallback(
    show => {
      // handle Manual(external controlling)
      if (onVisibilityChange) {
        onVisibilityChange(show)
      } else {
        setIsOpen(show)
      }
    },
    [setIsOpen, onVisibilityChange]
  )

  return (
    <Dropdown
      // selects should always show the body at the bottom
      allowPlacementFlip={false}
      placement="bottom-end"
      className={dropdownClassName}
      isOpen={isOpen || isManuallyOpened}
      onOpenChange={handleDropdownVisibility}
      isDisabled={isDisabled}
      triggerElement={
        <div
          className={classnames(classes.select, className)}
          // todo make reusable for all PseudoSelects
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <PseudoSelect
            title={title}
            value={
              value
                ? {
                    value: value,
                    label: value
                  }
                : ''
            }
            isOpen={isOpen || isManuallyOpened}
            isSmall={isSmall}
            isHovered={isHovered}
            isDisabled={isDisabled}
            isClearable={false}
            isHighlighted={isHighlighted}
            {...props}
          />
        </div>
      }
    >
      {children}
    </Dropdown>
  )
}

SelectDropdown.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  onVisibilityChange: PropTypes.func,
  isManuallyOpened: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  isHighlighted: PropTypes.bool
}

export default SelectDropdown
