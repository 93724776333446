import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'

import Table from '../../../../../../../../../components/Table'
import Chip from '../../../../../../../../../components/Chip'
import RecipientActions from './RecipientActions'

import { showToasts } from '../../../../../../../../../helpers/toasts'

import {
  mediaOrderResendConfirmationEmailIsLoadingSelector,
  mediaOrderResendConfirmationEmailRecipientRowIdSelector,
  mediaOrderResendConfirmationEmailWasResentSelector,
  mediaOrderSelector
} from '../../../../../../../../../modules/selectors/mediaOrders'
import { clearMediaOrderResendConfirmationEmail } from '../../../../../../../../../modules/actions/mediaOrders'

import { CHIP_COLORS, TOAST_TYPE } from '../../../../../../../../../constants/other'
import {
  FIRST_NAME,
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS
} from '../../../../../../../../../features/components/notificationFormFields/fields'

import { phonesDownSize } from '../../../../../../../../../styles/const/breakpoints'
import useStyles from './styles'

export const tableColumnsSize = {
  name: 120,
  status: 65,
  actions: 30
}

const RecipientsTable = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const dispatch = useDispatch()

  const isMobile = useMediaQuery({ maxWidth: phonesDownSize })

  const { [NOTIFICATION_EMAILS]: notificationEmails = [], [NOTIFICATION_USERS]: notificationUsers = [] } =
    useSelector(mediaOrderSelector)
  const mediaOrderResendConfirmationEmailWasResent = useSelector(mediaOrderResendConfirmationEmailWasResentSelector)
  const mediaOrderResendConfirmationEmailIsLoading = useSelector(mediaOrderResendConfirmationEmailIsLoadingSelector)
  const mediaOrderResendConfirmationEmailRecipientRowId = useSelector(
    mediaOrderResendConfirmationEmailRecipientRowIdSelector
  )

  const recipientsColumns = useMemo(() => {
    return [
      {
        header: 'Name',
        Cell: ({ name }) => name,
        style: { maxWidth: tableColumnsSize.name, paddingLeft: 0, paddingRight: 6 }
      },
      {
        header: 'Email',
        Cell: ({ email }) => email
      },
      {
        header: 'Status',
        Cell: () => <Chip text="Sent" color={CHIP_COLORS.green} />,
        style: { maxWidth: tableColumnsSize.status, paddingRight: 0 }
      },
      {
        Cell: data => <RecipientActions data={data} />,
        style: isMobile ? { padding: 0 } : { maxWidth: tableColumnsSize.actions },
        showOnMobile: false
      }
    ]
  }, [isMobile])

  const combinedRecipients = useMemo(() => {
    const formattedNotificationEmails = notificationEmails.map(email => ({
      name: email[FIRST_NAME],
      email: email.email
    }))

    const formattedNotificationUsers = notificationUsers.map(user => ({
      userId: user.id,
      name: user.full_name,
      email: user.email
    }))

    return [...formattedNotificationEmails, ...formattedNotificationUsers].map(recipientItem => ({
      ...recipientItem,
      // we add id to each recipient item to be able to track loading state of each item
      id: uuidv4()
    }))
  }, [notificationEmails, notificationUsers])

  useEffect(() => {
    if (mediaOrderResendConfirmationEmailWasResent) {
      showToasts({
        type: TOAST_TYPE.success,
        message: t('Booking confirmation email sent successfully')
      })
      dispatch(clearMediaOrderResendConfirmationEmail())
    }
  }, [dispatch, mediaOrderResendConfirmationEmailWasResent, t])

  return (
    <Table
      data={combinedRecipients}
      cols={recipientsColumns}
      className={classes.recipientsTable}
      hideFooterRow
      itemUpdatingId={
        mediaOrderResendConfirmationEmailIsLoading ? mediaOrderResendConfirmationEmailRecipientRowId : null
      }
    />
  )
}

export default RecipientsTable
