import axios from 'axios'
import Qs from 'qs'

import { isInternalRequest } from '../../helpers/api'
import { getLocalStorage } from '../../helpers/storage'
import { getSelectedLanguage } from '../../helpers/other'
import { checkAuthToken, getToken } from '../../helpers/api/getToken'

import { BE_LANGUAGE_VALUES } from '../../constants/selectLists/languagesList'
import { NOT_PLATFORM_USER_TOKEN } from '../../constants/api'

const BaseAxiosInstance = axios.create({
  responseType: 'json',
  paramsSerializer: {
    // transforms array to the multiple similar keys with different values:
    serialize: params => Qs.stringify(params, { arrayFormat: 'repeat' })
  }
})

BaseAxiosInstance.interceptors.request.use(async req => {
  const notPlatformUserToken = getLocalStorage(NOT_PLATFORM_USER_TOKEN)
  const isInternal = isInternalRequest(req.url)
  const headers = {
    ...req.headers,
    // ignore sending token to the 3rd party service
    ...(notPlatformUserToken && isInternal && { 'X-Media-Order-Token': `${notPlatformUserToken}` }),
    'Accept-Language': BE_LANGUAGE_VALUES[getSelectedLanguage()]
  }

  const token = await getToken()
  // check token before request
  checkAuthToken(token)

  // ignore sending Authorization with BE to amazon as it's failing
  // https://stackoverflow.com/questions/31366986/uploading-to-amazon-s3-via-ng-file-upload-error-400
  // warning in case the request is made to the 3rd party service - due to security reason token should not be sent
  if (!headers['Authorization'] && isInternal && token) {
    headers['Authorization'] = `Bearer ${token}`
  }

  return {
    ...req,
    headers
  }
})

const api = params =>
  new Promise((resolve, reject) => {
    BaseAxiosInstance(params)
      .then(res => resolve(res))
      .catch(error => reject(error))
  })
export default api
