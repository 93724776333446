import {
  EMAIL,
  FIRST_NAME,
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from './fields'

export const transformNotificationUsersOrEmailsToBE = values => {
  if (values[USER_OPTION] === SEND_TO_PLATFORM_USER) {
    return {
      [NOTIFICATION_USERS]: values[NOTIFICATION_USERS].map(user => user.value)
    }
  } else if (values[USER_OPTION] === SEND_BY_EMAIL) {
    return {
      [NOTIFICATION_EMAILS]: values[NOTIFICATION_EMAILS].map(email => ({
        [EMAIL]: email[EMAIL],
        [FIRST_NAME]: email[FIRST_NAME]
      }))
    }
  }
  return {}
}
