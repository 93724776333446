import React, { useRef } from 'react'
import { useTheme } from 'react-jss'

import Select from '../Select'
import HighlightedSelectSingleValue from './HighlightedSelectSingleValue'

import { getHighlightedValueSelectStyles } from './styles'

const HighlightedValueSelect = ({
  value,
  isDisabled,
  onChange,
  isClearable = true, // preset default value
  ...props
}) => {
  const theme = useTheme()

  const selectRef = useRef()
  const onChangeHandler = (option, eventType) => {
    if (eventType?.action === 'clear') {
      onChange(option, eventType)
      // remove focus from the select input after clearing the value
      setTimeout(() => selectRef.current?.blur(), 1)
    } else {
      onChange(option, eventType)
    }
  }

  const isEmptyValue = !value || (Array.isArray(value) && !value.length)

  return (
    <Select
      ref={selectRef}
      value={value}
      onChange={onChangeHandler}
      showIndicator={isEmptyValue}
      isClearable={isClearable}
      isDisabled={isDisabled}
      CustomValue={HighlightedSelectSingleValue}
      customStyles={getHighlightedValueSelectStyles({ ...props, isDisabled, theme })}
      {...props}
    />
  )
}

export default HighlightedValueSelect
