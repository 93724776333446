import React, { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import Step from '../../../../../../../../features/components/Forms/StepForm/Step'
import CustomAudiencesFields from '../../../../../../../../forms/ReusableFormFields/CustomAudiencesFields'
import { ReactComponent as AudienceTargetingIcon } from '../../../../../../../../assets/icons/campaigns/audienceTargeting.svg'

import { formatNumber } from '../../../../../../../../helpers/numbers'

import useDrawerFormStyles from '../../../../../../../../styles/common/drawerForms'
import useStyles from './styles'

import {
  CUSTOM_AUDIENCES,
  CUSTOM_AUDIENCES_EXCLUSION
} from '../../../../../../../../forms/ReusableFormFields/CustomAudiencesFields/fields'

const hardcodedOnsiteAudienceList = [
  {
    value: 1,
    label: 'Category Buyers: Chocolate & Sweets',
    tag: '780,454'
  },
  {
    value: 2,
    label: 'Lapsed Customers (60 Days)',
    tag: '598,450'
  },
  {
    value: 3,
    label: 'Price Conscious Shoppers',
    tag: '940,011'
  },
  {
    value: 4,
    label: 'Existing Customers (60 days)',
    tag: '605,400'
  },
  {
    value: 5,
    label: 'Competitor Buyers',
    tag: '209,231'
  },
  {
    value: 6,
    label: 'Complimentary Basket Product Buyers',
    tag: '130,439'
  }
]

const stepFields = [CUSTOM_AUDIENCES, CUSTOM_AUDIENCES_EXCLUSION]

const CustomAudiencesStep = ({ formik, handleStepChange }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()
  const classes = useStyles()

  const { values } = formik

  const selectedCustomAudiences = values[CUSTOM_AUDIENCES]

  const audienceForecast = useMemo(() => {
    const sumOfAudiencesSizes = selectedCustomAudiences.reduce((acc, audience) => {
      return acc + parseInt(audience.tag.replace(',', ''))
    }, 0)

    return sumOfAudiencesSizes * 0.4
  }, [selectedCustomAudiences])

  const handleContinue = useCallback(() => {
    handleStepChange(stepFields)
  }, [handleStepChange])

  return (
    <Step
      stepTitle="Select an audience"
      stepDescription="Target Jetstar audiences on onsite placements"
      handleContinue={handleContinue}
    >
      <CustomAudiencesFields
        formik={formik}
        formattedAudiences={hardcodedOnsiteAudienceList}
        audiencesIsLoading={false}
      />
      {audienceForecast > 0 && (
        <div className={classnames(drawerFormClasses.section, classes.container)}>
          <div className={classes.iconContainer}>
            <AudienceTargetingIcon />
          </div>
          <div className={classes.forecastInfo}>
            <p className={classes.forecastTitle}>{t('Forecast Available Impressions')}</p>
            <p className={classes.forecastAmount}>{formatNumber(audienceForecast, { min: 0, max: 0 })}</p>
            <p className={classes.forecastDescription}>
              {t(
                'The number of available impressions will be determined by the actual site traffic during the campaign period.'
              )}
            </p>
          </div>
        </div>
      )}
    </Step>
  )
}

export default CustomAudiencesStep
