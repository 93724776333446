import { AbilityBuilder, createMongoAbility } from '@casl/ability'
import { ROUTES } from './routes'

export const CONTROLLER_TYPE = 'controller'
export const AGENCY_TYPE = 'agency'
export const REGULAR_TYPE = 'regular'

export const MANAGER = 'manager'
export const ADMIN = 'admin'
export const REPORT = 'report'
export const BUYER = 'buyer'
export const ASSET_MANAGER = 'asset_manager'
export const INSTALLER = 'installer'

// SEPARATE PERMISSIONS ENTITIES
export const AUTOMATIC_OPTIMIZE_PERMISSIONS = 'AutomaticOptimizePermission'
export const MEDIA_ORDER_FILES_PERMISSIONS = 'MediaOrderFilesPermission'
export const INTERNAL_ADS_PERMISSION = 'InternalAdsPermission'
export const CAMPAIGN_PERMISSION = 'CampaignPermission'
export const LINE_ITEM_PERMISSION = 'LineItemPermission'
export const AD_PERMISSION = 'AdPermission'
export const QUOTATION_PERMISSION = 'quotation'
export const BOOKING_CONFIRMATION_EMAILS_CC_PERMISSION = 'booking_confirmation_emails_cc'
export const BOOKING_CONFIRMATION_EMAILS_BCC_PERMISSION = 'booking_confirmation_emails_bcc'
export const MODIFY_QUOTATION_PRICING = 'repricing'
export const FINANCE_ADMIN_PERMISSION = 'is_finance_admin'
export const SELF_ACCOUNT_TAGS_MANAGE = 'self_account_tags_manage'
export const BOOKINGS_SELF_ACCOUNT_FILTER = 'bookings_selfAccount_filter'
export const SEND_QUOTATION_PERMISSION = 'send_quotation'
export const AMENDMENT_PERMISSION = 'amendment'
export const UPLOAD_MEDIA_ORDER_FILES = 'upload_media_order_files'
export const CREATE_DIRECT_MEDIA_ORDER = 'create_direct_media_order' // create media order without contract
export const MEDIA_CATEGORIES_FILTERS_PERMISSION = 'media_categories_filters_permission'
export const MEDIA_ORDER_RECIPIENTS_PERMISSIONS = 'media_order_recipients'

// ADDITIONAL permissions from accounts(selfAccount, controller, agency or membership) to manage specific entities
export const PACKAGE_APPROVE_PERMISSION = 'is_package_approver'
// Users with this permission can sign contracts and book media on behalf of their organisation.
export const SIGNATORY_PERMISSION = 'signatory'

export const getPermissionsForUnauthorizedUser = () => {
  const { build } = new AbilityBuilder(createMongoAbility)
  return build()
}

// if some entity or page is not listed here, it means that it is available for all users(all users have permission
// to access or manage it).
// In case if some entity or page is provided with permissions to some role, it means that only users with this role
// will have access to it and other users will automatically be denied access to it(opposite to when entity or page is not listed here).
export function getPermissionsForAuthorizedUser({
  role,
  accountType,
  controllerPermissions,
  selfAccountPermissions,
  agencyPermissions
}) {
  const { can, build } = new AbilityBuilder(createMongoAbility)

  if (accountType === CONTROLLER_TYPE) {
    handleControllerType(can, role)
  } else if (accountType === AGENCY_TYPE) {
    handleAgencyType(can, role)
  } else if (accountType === REGULAR_TYPE) {
    handleRegularType(can, role)
  }

  // these permissions are linked to the membership of the controller, agency or selfAccount..
  // i.e. the user has specific permissions when interacting on the specific selfAccount/controller/agency
  controllerPermissions && can('manage', controllerPermissions)
  selfAccountPermissions && can('manage', selfAccountPermissions)
  agencyPermissions && can('manage', agencyPermissions)

  return build()
}

const handleControllerType = (can, role) => {
  if (role === REPORT) {
    can('manage', [
      ROUTES.dashboard,
      ROUTES.bookedMediaCalendarReport,
      ROUTES.inventoryCalendarReport,
      ROUTES.bookedRevenueReport,
      ROUTES.breakdownCharts,
      MEDIA_CATEGORIES_FILTERS_PERMISSION,
      MEDIA_ORDER_FILES_PERMISSIONS,
      QUOTATION_PERMISSION,
      SIGNATORY_PERMISSION,
      SELF_ACCOUNT_TAGS_MANAGE
    ])
  } else if (role === MANAGER) {
    can('create', [CREATE_DIRECT_MEDIA_ORDER])
    can('manage', [
      ROUTES.dashboard,
      ROUTES.bookedMediaCalendarReport,
      ROUTES.inventoryCalendarReport,
      ROUTES.bookedRevenueReport,
      ROUTES.breakdownCharts,
      ROUTES.adApprovals,
      ROUTES.audiences,
      ROUTES.campaigns,
      ROUTES.campaignsSummary,
      ROUTES.lineItemsSummary,
      ROUTES.proposals,
      ROUTES.proposalsCreate,
      ROUTES.proposalsEdit,
      MEDIA_CATEGORIES_FILTERS_PERMISSION,
      CAMPAIGN_PERMISSION,
      LINE_ITEM_PERMISSION,
      AD_PERMISSION,
      AUTOMATIC_OPTIMIZE_PERMISSIONS,
      INTERNAL_ADS_PERMISSION,
      MEDIA_ORDER_FILES_PERMISSIONS,
      QUOTATION_PERMISSION,
      SIGNATORY_PERMISSION,
      UPLOAD_MEDIA_ORDER_FILES,
      // SETTINGS PAGES:
      SELF_ACCOUNT_TAGS_MANAGE,
      ROUTES.manageLocationLists,
      ROUTES.brandPages,
      ROUTES.pagesAndIdentities,
      ROUTES.invoicing,
      ROUTES.mediaPackages,
      ROUTES.bookedMediaAndFiles,
      BOOKINGS_SELF_ACCOUNT_FILTER,
      ROUTES.reporting,
      SEND_QUOTATION_PERMISSION
    ])
  } else if (role === ADMIN) {
    can('create', [CREATE_DIRECT_MEDIA_ORDER])
    can('manage', [
      ROUTES.dashboard,
      ROUTES.bookedMediaCalendarReport,
      ROUTES.inventoryCalendarReport,
      ROUTES.bookedRevenueReport,
      ROUTES.breakdownCharts,
      ROUTES.adApprovals,
      ROUTES.audiences,
      ROUTES.campaigns,
      ROUTES.campaignsSummary,
      ROUTES.lineItemsSummary,
      ROUTES.proposals,
      ROUTES.proposalsCreate,
      ROUTES.proposalsEdit,
      MEDIA_CATEGORIES_FILTERS_PERMISSION,
      CAMPAIGN_PERMISSION,
      LINE_ITEM_PERMISSION,
      AD_PERMISSION,
      AUTOMATIC_OPTIMIZE_PERMISSIONS,
      INTERNAL_ADS_PERMISSION,
      MEDIA_ORDER_FILES_PERMISSIONS,
      QUOTATION_PERMISSION,
      SIGNATORY_PERMISSION,
      UPLOAD_MEDIA_ORDER_FILES,
      AMENDMENT_PERMISSION,
      MEDIA_ORDER_RECIPIENTS_PERMISSIONS,
      // SETTINGS PAGES:
      SELF_ACCOUNT_TAGS_MANAGE,
      ROUTES.settings,
      ROUTES.manageLocationLists,
      ROUTES.brandPages,
      ROUTES.pagesAndIdentities,
      ROUTES.manageMargins,
      ROUTES.controllerTeam,
      ROUTES.advertiserAccounts,
      ROUTES.agencyAccounts,
      ROUTES.invoicing,
      ROUTES.mediaProducts,
      ROUTES.mediaPackages,
      ROUTES.bookedMediaAndFiles,
      ROUTES.reporting,
      ROUTES.discounts,
      ROUTES.controller,
      ROUTES.agency,
      BOOKINGS_SELF_ACCOUNT_FILTER,
      SEND_QUOTATION_PERMISSION
    ])
  } else if (role === BUYER) {
    can('manage', [
      ROUTES.dashboard,
      ROUTES.bookedMediaCalendarReport,
      ROUTES.inventoryCalendarReport,
      ROUTES.bookedRevenueReport,
      ROUTES.breakdownCharts,
      MEDIA_ORDER_FILES_PERMISSIONS,
      MEDIA_CATEGORIES_FILTERS_PERMISSION
    ])
  } else if (role === ASSET_MANAGER) {
    can('manage', [
      ROUTES.dashboard,
      ROUTES.bookedMediaCalendarReport,
      ROUTES.inventoryCalendarReport,
      ROUTES.bookedRevenueReport,
      ROUTES.breakdownCharts,
      // SETTINGS PAGES:
      ROUTES.bookedMediaAndFiles,
      MEDIA_ORDER_FILES_PERMISSIONS,
      MEDIA_CATEGORIES_FILTERS_PERMISSION
    ])
  } else if (role === INSTALLER) {
    can('manage', [ROUTES.dashboard, MEDIA_ORDER_FILES_PERMISSIONS])
  }
}

const handleAgencyType = (can, role) => {
  if (role === REPORT) {
    // report permissions
    can('manage', [ROUTES.agency])
  } else if (role === MANAGER) {
    // manager permissions
    can('update', [INTERNAL_ADS_PERMISSION])
    can('manage', [
      ROUTES.audiences,
      ROUTES.campaigns,
      ROUTES.campaignsSummary,
      ROUTES.lineItemsSummary,
      CAMPAIGN_PERMISSION,
      LINE_ITEM_PERMISSION,
      AD_PERMISSION,
      AUTOMATIC_OPTIMIZE_PERMISSIONS,
      BOOKINGS_SELF_ACCOUNT_FILTER,
      // SETTINGS PAGES:
      ROUTES.manageLocationLists,
      ROUTES.agency
    ])
  } else if (role === ADMIN) {
    can('manage', [
      ROUTES.audiences,
      ROUTES.campaigns,
      ROUTES.campaignsSummary,
      ROUTES.lineItemsSummary,
      CAMPAIGN_PERMISSION,
      LINE_ITEM_PERMISSION,
      AD_PERMISSION,
      AUTOMATIC_OPTIMIZE_PERMISSIONS,
      BOOKINGS_SELF_ACCOUNT_FILTER,
      // SETTINGS PAGES:
      ROUTES.manageLocationLists,
      ROUTES.settings,
      ROUTES.agency,
      ROUTES.advertiserAccounts,
      ROUTES.invoicing
    ])
    can('update', [INTERNAL_ADS_PERMISSION])
  }
}

const handleRegularType = (can, role) => {
  if (role === REPORT) {
    // report permissions
  } else if (role === MANAGER) {
    can('update', [INTERNAL_ADS_PERMISSION])
    can('manage', [
      ROUTES.audiences,
      CAMPAIGN_PERMISSION,
      LINE_ITEM_PERMISSION,
      AD_PERMISSION,
      AUTOMATIC_OPTIMIZE_PERMISSIONS,
      ROUTES.manageLocationLists,
      ROUTES.campaigns,
      ROUTES.campaignsSummary,
      ROUTES.lineItemsSummary
    ])
  } else if (role === ADMIN) {
    can('update', [INTERNAL_ADS_PERMISSION])
    can('manage', [
      ROUTES.audiences,
      CAMPAIGN_PERMISSION,
      LINE_ITEM_PERMISSION,
      AD_PERMISSION,
      AUTOMATIC_OPTIMIZE_PERMISSIONS,
      ROUTES.campaigns,
      ROUTES.campaignsSummary,
      ROUTES.lineItemsSummary,
      // SETTINGS PAGES:
      ROUTES.settings,
      ROUTES.manageLocationLists,
      ROUTES.invoicing
    ])
  }
}
