import { createStyles } from '../../styles/helpers'

import { transitionShort, zIndex8 } from '../../styles/const/common'

const useStyles = createStyles(theme => ({
  triggerElement: {
    display: 'flex',
    cursor: ({ isDisabled }) => !isDisabled && 'pointer'
  },
  arrowIndicator: {
    width: 12,
    height: 'auto',
    marginLeft: 15,
    transition: transitionShort,
    color: theme.brandPrimary,
    transform: ({ dropdownOpen }) => (dropdownOpen ? 'rotate(180deg)' : 'rotate(0deg)')
  },
  dropdownBody: {
    border: ({ borderColor, borderWidth }) => `${borderWidth}px solid ${borderColor}`,
    borderRadius: theme.defaultBorderRadius,
    backgroundColor: 'white',
    cursor: 'auto',
    animation: '$showDropdown 200ms ease',
    // zIndex9 is used to cover the dropdown by header on mob
    zIndex: zIndex8
  },
  '@keyframes showDropdown': {
    '0%': {
      display: 'none',
      opacity: 0
    },
    '1%': {
      display: 'block',
      opacity: 0
    },
    '100%': {
      display: 'block',
      opacity: 1
    }
  }
}))

export default useStyles
