import React, { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import ProposalMediaOrderRecipientsForm from './ProposalMediaOrderRecipientsForm'

import useManageFormsDrawer from '../../../../../hooks/formHooks/useManageFormsDrawer'

import { clearMembersList, getMembersList } from '../../../../../modules/actions/selfAccountTeam'
import { selectedSelfAccountSelector } from '../../../../../modules/selectors/app'
import { membersIsLoadingSelector } from '../../../../../modules/selectors/selfAccountTeam'

import { PROPOSAL_MEDIA_ORDER_RECIPIENTS } from '../../../../../constants/forms'

function ProposalMediaOrderRecipients({ contractId, onSuccessSubmit, disabled }) {
  const dispatch = useDispatch()

  const selfAccount = useSelector(selectedSelfAccountSelector)

  const membersIsLoading = useSelector(membersIsLoadingSelector)

  const onAfterFormClose = useCallback(() => {
    dispatch(clearMembersList())
  }, [dispatch])

  const { isFormOpen } = useManageFormsDrawer({
    formName: PROPOSAL_MEDIA_ORDER_RECIPIENTS,
    onAfterFormClose
  })

  useEffect(() => {
    // we fetch members with limit 1 just to find out if there are any members,
    // if there are members we preselect radio button for platform users
    // otherwise we preselect radio button for email
    if (isFormOpen) {
      dispatch(getMembersList({ account: selfAccount, limit: 1 }))
    }
  }, [dispatch, selfAccount, isFormOpen])

  return (
    <FormDrawerWrapper
      formName={PROPOSAL_MEDIA_ORDER_RECIPIENTS}
      title="Confirmation Email Recipients"
      description="Select platform users or enter email addresses to receive a confirmation email for this booking. Details of the required file specifications will also be included if applicable."
      openButtonText="Confirm and book"
      disabled={disabled}
      isFormLoading={membersIsLoading}
    >
      <ProposalMediaOrderRecipientsForm contractId={contractId} onSuccessSubmit={onSuccessSubmit} />
    </FormDrawerWrapper>
  )
}

ProposalMediaOrderRecipients.propTypes = {
  contractId: PropTypes.number.isRequired,
  onSuccessSubmit: PropTypes.func,
  disabled: PropTypes.bool
}

export default ProposalMediaOrderRecipients
