import React from 'react'
import { useParams } from 'react-router'
import classnames from 'classnames'

import UnauthorizedPage from '../UnauthorizedPage'
import RequestedFilesSection from '../../MediaOrderSummary/MediaOrderSummaryContent/RequestedFilesSection'
import MediaOrderInfo from './MediaOrderInfo'

import { ROUTE_PARAMS } from '../../../constants/routes'

import useAuthStyles from '../../../styles/common/authPages'
import useStyles from './styles'

const MediaOrderRequestedFiles = () => {
  const authClasses = useAuthStyles()
  const classes = useStyles()

  const { [ROUTE_PARAMS.mediaOrderId]: mediaOrderId } = useParams()

  return (
    <UnauthorizedPage
      helmetTitle="helmetTitle.MediaOrderSummary"
      className={classnames(authClasses.authPage, classes.requestedFilesPage)}
    >
      <MediaOrderInfo />
      <RequestedFilesSection mediaOrderId={mediaOrderId} />
    </UnauthorizedPage>
  )
}

export default MediaOrderRequestedFiles
