import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import classnames from 'classnames'

import Page from '../../index'
import QuotationCreateForm from './QuotationForm'
import SkeletonQuotationBuilderForm from './QuotationForm/Skeleton'
import ProposalMediaOrderGenerate from './ProposalMediaOrderGenerate'
import ProposalBuilderPageBreadcrumbs from './ProposalBuilderPageBreadcrumbs'
import { MediaOrderFormProvider } from '../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'
import { ContractBuilderProvider } from '../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/ContractBuilderContext'

import { clearActiveContract, clearGetContract, getContract } from '../../../modules/actions/contracts'
import { activeContractSelector, getContractIsLoadingSelector } from '../../../modules/selectors/contracts'

import useStyles from './styles'
import useCommonStyles from '../../../styles/common/listPage'

const QuotationBuilderPage = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const classes = useStyles()
  const commonClasses = useCommonStyles()

  const activeContract = useSelector(activeContractSelector)
  const contractIsLoading = useSelector(getContractIsLoadingSelector)

  const sequentialId = activeContract?.media_order?.sequential_id || ''
  const createdContractId = activeContract?.id
  const isWithoutRequirements = activeContract?.without_requirements
  const { id } = useParams()

  useEffect(() => {
    if (id) {
      dispatch(
        getContract(id, {
          inventory_detail: true,
          competing_quotation: true // allows to get competing quotation data
        })
      )
    }
  }, [dispatch, id])

  useEffect(() => {
    return () => {
      dispatch(clearActiveContract())
      dispatch(clearGetContract())
    }
  }, [dispatch])

  return (
    <Page helmetTitle="helmetTitle.QuotationCreatePage">
      {contractIsLoading ? (
        <SkeletonQuotationBuilderForm />
      ) : (
        <MediaOrderFormProvider initialSelfAccount={activeContract?.account}>
          <div className={classnames(commonClasses.heading, classes.heading)}>
            <div>
              <ProposalBuilderPageBreadcrumbs />
              <h3 className={commonClasses.title}>{t('Proposal') + ' ' + sequentialId}</h3>
            </div>
            {createdContractId && isWithoutRequirements && (
              <ProposalMediaOrderGenerate contractId={createdContractId} />
            )}
          </div>

          <ContractBuilderProvider contractId={createdContractId}>
            <QuotationCreateForm />
          </ContractBuilderProvider>
        </MediaOrderFormProvider>
      )}
    </Page>
  )
}

export default QuotationBuilderPage
