import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { getSelectedPeriodLabel } from './helpers'

import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../fields'

import useStyles from './styles'

const PeriodsCell = ({ productValues }) => {
  const classes = useStyles()

  const productData = productValues.data
  const productPeriod = productData?.period

  const groupedPeriods = productValues[PRODUCT_GROUPED_PUBLICATIONS_DATES]
  const hasGroupedPeriods = groupedPeriods.length > 0

  const selectedPeriodLabel = useMemo(
    () => getSelectedPeriodLabel({ groupedPeriods, productPeriod }),
    [groupedPeriods, productPeriod]
  )

  return <div className={classes.periodCol}>{hasGroupedPeriods ? selectedPeriodLabel : ''}</div>
}

PeriodsCell.propTypes = {
  productValues: PropTypes.object
}

export default PeriodsCell
