import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'

import FormDrawerWrapper from '../../../../../../features/components/FormDrawerWrapper'
import UpdateMediaOrderRecipientsForm from './UpdateMediaOrderRecipientsForm'

import useManageFormsDrawer from '../../../../../../hooks/formHooks/useManageFormsDrawer'

import { selectedSelfAccountSelector } from '../../../../../../modules/selectors/app'
import { membersIsLoadingSelector } from '../../../../../../modules/selectors/selfAccountTeam'
import { clearMembersList, getMembersList } from '../../../../../../modules/actions/selfAccountTeam'

import { UPDATE_MEDIA_ORDER_RECIPIENTS } from '../../../../../../constants/forms'
import { tabletDownSize } from '../../../../../../styles/const/breakpoints'

function UpdateMediaOrderRecipients() {
  const dispatch = useDispatch()

  const selfAccount = useSelector(selectedSelfAccountSelector)

  const membersIsLoading = useSelector(membersIsLoadingSelector)

  const isTablet = useMediaQuery({ maxWidth: tabletDownSize })

  const onAfterFormClose = useCallback(() => {
    dispatch(clearMembersList())
  }, [dispatch])

  const { isFormOpen } = useManageFormsDrawer({
    formName: UPDATE_MEDIA_ORDER_RECIPIENTS,
    onAfterFormClose
  })

  useEffect(() => {
    // we fetch members with limit 1 just to find out if there are any members,
    // if there are members we preselect radio button for platform users
    // otherwise we preselect radio button for email
    if (isFormOpen) {
      dispatch(getMembersList({ account: selfAccount, limit: 1 }))
    }
  }, [dispatch, selfAccount, isFormOpen])

  return (
    <FormDrawerWrapper
      formName={UPDATE_MEDIA_ORDER_RECIPIENTS}
      title="Manage confirmation email recipients"
      description="Users or email addresses that will receive the confirmation email (including required file specifications if applicable)"
      openButtonText="Manage recipients"
      isFormLoading={membersIsLoading}
      buttonProps={{
        isSmall: !isTablet
      }}
      closeOnSubmit={false}
    >
      <UpdateMediaOrderRecipientsForm />
    </FormDrawerWrapper>
  )
}

export default UpdateMediaOrderRecipients
