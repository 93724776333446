import * as Yup from 'yup'

import { MEDIA_PRODUCTS, MODIFIED_PRODUCTS } from './fields'
import { BRAND, CAMPAIGN_NAME } from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/fields'
import { productPublicationDatesValidation } from '../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/validation'
import { PRODUCT_PERIODS_DATES } from '../../fields'
import {
  EMAIL,
  FIRST_NAME,
  NOTIFICATION_EMAILS,
  SEND_BY_EMAIL,
  USER_OPTION
} from '../../../../../features/components/notificationFormFields/fields'

export const getValidationSchema = ({ askBrandName, askCampaignName, isBrandRequired }) => {
  return Yup.object({
    [MEDIA_PRODUCTS]: Yup.array().of(productPublicationDatesValidation),
    [MODIFIED_PRODUCTS]: Yup.array().of(
      Yup.object().shape({
        [PRODUCT_PERIODS_DATES]: Yup.array().of(
          Yup.object().shape({
            price: Yup.number().required('Price is required')
          })
        )
      })
    ),
    ...(askCampaignName && {
      [CAMPAIGN_NAME]: Yup.string().required('Please enter a campaign name')
    }),
    ...(askBrandName && {
      [BRAND]: isBrandRequired ? Yup.string().required('Please select the brand') : Yup.string()
    }),

    [NOTIFICATION_EMAILS]: Yup.array().when(USER_OPTION, {
      is: SEND_BY_EMAIL,
      then: () =>
        Yup.array().of(
          Yup.object().test({
            name: 'notification email validation',
            test: (value, context) => {
              const { [EMAIL]: email, [FIRST_NAME]: firstName } = value
              const { path } = context

              if (firstName && !email) {
                return context.createError({
                  path: `${path}.${EMAIL}`,
                  message: 'Email is required'
                })
              }

              if (email && !firstName) {
                return context.createError({
                  path: `${path}.${FIRST_NAME}`,
                  message: 'First name is required'
                })
              }
            }
          })
        )
    })
  })
}
