import { SEND_TO_PLATFORM_USER, USER_OPTION } from '../../../../features/components/notificationFormFields/fields'

export const PLATFORM_USER_RECEIVER = 'quotation_receiver_user'
export const EMAIL_RECEIVER = 'quotation_receiver_email'

export const initialValues = {
  [USER_OPTION]: SEND_TO_PLATFORM_USER,
  [PLATFORM_USER_RECEIVER]: '',
  [EMAIL_RECEIVER]: ''
}
