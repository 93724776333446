import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../components/Form'
import SaveDataDetails from './SaveDataDetails'
import ContractBuilder from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder'

import { useHandleSubmitForm } from './useHandleSubmitForm'
import { usePurifiedFormik } from '../../../../hooks/formHooks/usePurifiedFormik'

import { activeContractSelector, contractSelector } from '../../../../modules/selectors/contracts'

import { QUOTATION_CREATE } from '../../../../constants/forms'

import { getInitialValues } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/fields'
import { getValidationSchema } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/validation'

import useStyles from './styles'

function QuotationCreateForm() {
  const classes = useStyles()

  // loaded contract is used to get initial values for the form
  const loadedContract = useSelector(contractSelector)
  // active contract is used to check if we are in edit mode(user may crete new contract or edit existing one)
  const activeContract = useSelector(activeContractSelector)
  const createdContractId = activeContract?.id

  // by default force user to see additional info edit
  const showAdditionalInfoEdit = useMemo(() => !createdContractId, [createdContractId])
  const [isAdditionalInfoEdit, setIsAdditionalInfoEdit] = useState(showAdditionalInfoEdit)

  const { onSubmit, successSubmit, errorSelector, isLoadingSelector, clearSubmitHandler, setEditMode } =
    useHandleSubmitForm()

  const initialValues = useMemo(() => {
    return getInitialValues(loadedContract)
  }, [loadedContract])

  const validationSchema = useMemo(() => {
    // we don't check additional media order validation during contract creation/update
    return getValidationSchema({})
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
    enableReinitialize: true
  })
  const purifiedFormik = usePurifiedFormik(formik)

  return (
    <Form
      formik={purifiedFormik}
      formName={QUOTATION_CREATE}
      showSubmit={false}
      className={classes.quotationsProductsTable}
      // processing
      successSubmit={successSubmit}
      errorSelector={errorSelector}
      isLoadingSelector={isLoadingSelector}
      // after form submit
      clearHandler={clearSubmitHandler}
      // set edit more after successful creation to use update handlers and selectors
      onSuccessSubmit={() => setEditMode(true)}
    >
      {createdContractId && <SaveDataDetails />}
      <ContractBuilder
        formik={purifiedFormik}
        isAdditionalInfoEdit={isAdditionalInfoEdit}
        setIsAdditionalInfoEdit={setIsAdditionalInfoEdit}
      />
    </Form>
  )
}

export default QuotationCreateForm
