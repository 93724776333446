import { EMAIL_RECEIVER, PLATFORM_USER_RECEIVER } from './fields'
import {
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../features/components/notificationFormFields/fields'

export const transformValuesToBE = values => {
  if (values[USER_OPTION] === SEND_TO_PLATFORM_USER) {
    return {
      [PLATFORM_USER_RECEIVER]: values[PLATFORM_USER_RECEIVER]
    }
  } else if (values[USER_OPTION] === SEND_BY_EMAIL) {
    return {
      [EMAIL_RECEIVER]: values[EMAIL_RECEIVER]
    }
  }
  return {}
}
