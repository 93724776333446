import { v4 as uuidv4 } from 'uuid'

import { calc } from '../../../../../../helpers/numbers'

import {
  CHANNEL_OPTION,
  CHANNEL_OPTION_OFFSITE,
  CHANNEL_OPTION_ONSITE,
  CREATIVE_DEADLINE_DAYS,
  CUSTOM_DISCOUNT_CHECKBOX,
  DEFAULT_INVENTORY_DATE_START,
  DESCRIPTION,
  DISCOUNT_PAIRS,
  DISCOUNT_PERCENTAGE,
  DISCOUNT_PRESET,
  DISCOUNT_QUANTITY,
  discountPairInitialValue,
  DISCOUNTS,
  DISCOUNTS_TAB_CUSTOM,
  DISCOUNTS_TAB_PRESET,
  DISCOUNTS_TABS,
  FILE_SPECIFICATION_TYPE,
  FILE_SPECIFICATION_VALUE,
  FILE_SPECIFICATIONS_PAIRS,
  FILES_REQUIREMENTS,
  FILES_REQUIREMENTS_OPTION,
  FILES_REQUIREMENTS_SUPPORTED_FORMATS,
  FILES_REQUIREMENTS_TYPE,
  initialFiles,
  initialFileSpecifications,
  INTERNAL_ID,
  NAME,
  PERIOD_OPTION,
  PLATFORMS
} from '../../../MediaProductCreate/MediaProductCreateForm/fields'
import { PRICE_CURRENCY, PRICE_PAIRS, PRICE_VALUE } from '../../../../../ReusableFormFields/PricePairFields/fields'
import { PROVIDER_TO_PLATFORMS } from '../../../../../../constants/selectLists/platformList'
import { OPTION_NO, OPTION_YES } from '../../../../../../constants/forms'
import { getSupportedFormats } from '../../../../../../constants/product'
import { INVENTORY_AMOUNT, INVENTORY_PERIOD, MAX_PERIODS, MIN_PERIODS, platforms } from '../../../fields'
import { getTagsSectionInitialValues } from './TagsSection/fields'
import { getImagesSectionInitialValues } from '../../sections/ImagesSection/fields'
import { getLocationSectionInitialValues } from '../../sections/LocationSection/fields'
import { getVariablesSectionInitialValues } from '../../sections/VariablesSection/fields'
import { getInitialCategories } from '../../fields'

export const OFFSITE = 'offsite'
export const ATTACHED_FILES = 'attached_files'

const getDiscountsSectionInitialValues = (editProductData, discountPresets = []) => {
  const discountPreset = editProductData[DISCOUNT_PRESET]?.id
  const discounts =
    editProductData[DISCOUNTS]?.map(item => ({
      [DISCOUNT_PERCENTAGE]: calc(item[DISCOUNT_PERCENTAGE]).mul(100).toString(),
      [DISCOUNT_QUANTITY]: item.value,
      id: uuidv4()
    })) || []

  if (discountPreset) {
    // If product has discount preset - we show tabs and preselect discountPreset tab
    return {
      [DISCOUNTS_TABS]: DISCOUNTS_TAB_PRESET,
      [DISCOUNT_PRESET]: discountPreset,
      [DISCOUNT_PAIRS]: [{ ...discountPairInitialValue, id: uuidv4() }]
    }
  } else if (discounts.length) {
    // If product has custom discounts - we show tabs and preselect custom discounts tab
    return {
      [DISCOUNTS_TABS]: DISCOUNTS_TAB_CUSTOM,
      [DISCOUNT_PRESET]: '',
      [DISCOUNT_PAIRS]: discounts
    }
  } else if (discountPresets.length > 0) {
    // If there are discount presets - we show tabs and preselect discountPreset tab
    return {
      [DISCOUNTS_TABS]: DISCOUNTS_TAB_PRESET,
      [DISCOUNT_PRESET]: '',
      [DISCOUNT_PAIRS]: [{ ...discountPairInitialValue, id: uuidv4() }]
    }
  } else {
    // In case there are no discount presets - we don't show tabs but just checkbox
    return {
      [CUSTOM_DISCOUNT_CHECKBOX]: false,
      [DISCOUNT_PAIRS]: [{ ...discountPairInitialValue, id: uuidv4() }]
    }
  }
}

export const initialProductPrices = editProductData => {
  return editProductData?.prices?.map(item => ({
    id: item.id,
    text: item.id,
    [PRICE_CURRENCY]: item.currency,
    [PRICE_VALUE]: item.price
  }))
}

export const getInitialValues = ({ editProductData, productTags, discountPresets }) => {
  const requiredFiles =
    editProductData[FILES_REQUIREMENTS] &&
    editProductData[FILES_REQUIREMENTS].map(file => {
      const formats = getSupportedFormats(file[FILES_REQUIREMENTS_TYPE])

      // group file specifications into array (we represent specifications as editable list)
      const selectedSpecifications = Object.keys(initialFileSpecifications)
        .filter(item => !!file[item])
        .map(item => ({
          [FILE_SPECIFICATION_TYPE]: item,
          [FILE_SPECIFICATION_VALUE]: file[item],
          id: uuidv4()
        }))

      return {
        ...file,
        [FILE_SPECIFICATIONS_PAIRS]: selectedSpecifications,
        // populate supported formats to {value, label} object which match MultiSelectBox component
        [FILES_REQUIREMENTS_SUPPORTED_FORMATS]: file[FILES_REQUIREMENTS_SUPPORTED_FORMATS].map(format => {
          return formats.find(item => item.value === format)
        })
      }
    })

  return {
    [NAME]: editProductData[NAME] || '',
    [DESCRIPTION]: editProductData[DESCRIPTION] || '',
    ...getInitialCategories(editProductData),
    [PERIOD_OPTION]: editProductData.period || '',
    [CREATIVE_DEADLINE_DAYS]: editProductData[CREATIVE_DEADLINE_DAYS] || '',
    [CHANNEL_OPTION]: editProductData.offsite ? CHANNEL_OPTION_OFFSITE : CHANNEL_OPTION_ONSITE,
    [PLATFORMS]: editProductData.providers?.length
      ? editProductData.providers.map(item => {
          return platforms.find(platform => platform.value === PROVIDER_TO_PLATFORMS[item])
        })
      : [],
    [INVENTORY_AMOUNT]: editProductData[INVENTORY_AMOUNT] || '',
    [INVENTORY_PERIOD]: editProductData[INVENTORY_PERIOD] || '',
    [DEFAULT_INVENTORY_DATE_START]: editProductData[DEFAULT_INVENTORY_DATE_START]
      ? new Date(editProductData[DEFAULT_INVENTORY_DATE_START])
      : '',
    [MIN_PERIODS]: editProductData[MIN_PERIODS] || '',
    [MAX_PERIODS]: editProductData[MAX_PERIODS] || '',
    [PRICE_PAIRS]: initialProductPrices(editProductData),
    ...getDiscountsSectionInitialValues(editProductData, discountPresets),
    ...getImagesSectionInitialValues(editProductData),
    // if there were file requirements, then preset the option to yes
    [FILES_REQUIREMENTS_OPTION]: !!editProductData[FILES_REQUIREMENTS]?.length ? OPTION_YES : OPTION_NO,
    [FILES_REQUIREMENTS]: !!requiredFiles.length ? requiredFiles : initialFiles,
    ...getTagsSectionInitialValues(editProductData, productTags),
    ...getLocationSectionInitialValues(editProductData),
    [OFFSITE]: editProductData[OFFSITE],
    ...getVariablesSectionInitialValues(editProductData),
    [INTERNAL_ID]: editProductData[INTERNAL_ID] || '',
    [ATTACHED_FILES]:
      editProductData[ATTACHED_FILES]?.map(attachedFile => ({
        value: attachedFile.id,
        label: attachedFile.file_name
      })) || []
  }
}
