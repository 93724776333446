import { useSelector } from 'react-redux'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import SpecificationsDocumentsTableData from './SpecificationsDocumentsTableData'
import TableDataLoader from '../../../../../components/Table/TableDataLoader'

import { useLoadPaginatedList } from '../../../../../features/hooks/useLoadPaginatedList'

import {
  getMediaProductAttachedFilesErrorSelector,
  getMediaProductAttachedFilesIsLoadingSelector,
  getMediaProductAttachedFilesNextSelector,
  mediaProductAttachedFilesSelector,
  mediaProductAttachedFilesWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'
import {
  clearGetMediaProductAttachedFiles,
  getMediaProductAttachedFiles
} from '../../../../../modules/actions/mediaOrdersProducts'

import useCommonStyles from '../../../../../styles/common/listPage'

export const tableColumnsSize = {
  actions: 30
}

const SpecificationsDocumentsTable = () => {
  const { t } = useTranslation()

  const commonClasses = useCommonStyles()
  const mediaProductAttachedFiles = useSelector(mediaProductAttachedFilesSelector)
  const getMediaProductAttachedFilesNext = useSelector(getMediaProductAttachedFilesNextSelector)

  const selectedControllerId = useSelector(selectedControllerIdSelector)

  const params = useMemo(
    () => ({
      controller: selectedControllerId
    }),
    [selectedControllerId]
  )

  const loadMoreHandler = useLoadPaginatedList({
    params,
    action: getMediaProductAttachedFiles,
    clearAction: clearGetMediaProductAttachedFiles,
    next: getMediaProductAttachedFilesNext
  })

  const columnsSchema = useMemo(
    () => [
      {
        fieldKey: 'title'
      },
      {
        style: { maxWidth: tableColumnsSize.actions }
      }
    ],
    []
  )

  const noDataContent = useMemo(
    () => (
      <div className={commonClasses.noDataContent}>
        <div>{t('There are no specifications documents')}</div>
        <br />
      </div>
    ),
    [commonClasses.noDataContent, t]
  )

  return (
    <TableDataLoader
      itemsLength={mediaProductAttachedFiles.length}
      errorSelector={getMediaProductAttachedFilesErrorSelector}
      wasLoadedSelector={mediaProductAttachedFilesWasLoadedSelector}
      isLoadingSelector={getMediaProductAttachedFilesIsLoadingSelector}
      skeletonProps={{ cols: columnsSchema }}
      noDataContent={noDataContent}
      loadMore={loadMoreHandler}
    >
      <SpecificationsDocumentsTableData />
    </TableDataLoader>
  )
}

export default SpecificationsDocumentsTable
