import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import FieldsSection from '../../../../../../../../features/components/Form/FieldsSection'
import SelectOrDeselectAllButton from './SelectOrDeselectAllButton'
import FieldRow from '../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import PublicationDatesFields from './PublicationDatesFields'

import useStyles from './styles'

const ProductSection = ({ formik, product }) => {
  const classes = useStyles()

  const { values, setFieldValue } = formik

  // group booked media by files from the file_requirements array
  const groupedBookedMediaByFile = product.bookedMedia.reduce((acc, media) => {
    media.file_requirements.forEach(file => {
      if (!acc[file.id]) {
        acc[file.id] = {
          id: file.id,
          name: file.file_name,
          bookedMedia: []
        }
      }
      acc[file.id].bookedMedia.push(media)
    })
    return acc
  }, {})

  return (
    <FieldsSection
      title={product.name}
      rightColumn={<SelectOrDeselectAllButton formik={formik} groupedBookedMediaByFile={groupedBookedMediaByFile} />}
    >
      <FieldRow title="Publication dates" description="These are the go-live dates for the booked media">
        {Object.values(groupedBookedMediaByFile).map(file => {
          return (
            <Fragment key={file.id}>
              <h3 className={classes.fileName}>{file.name}</h3>
              <PublicationDatesFields
                values={values}
                setFieldValue={setFieldValue}
                fileId={file.id}
                bookedMedias={file.bookedMedia}
              />
            </Fragment>
          )
        })}
      </FieldRow>
    </FieldsSection>
  )
}

ProductSection.propTypes = {
  formik: PropTypes.object,
  product: PropTypes.object
}

export default ProductSection
