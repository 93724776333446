import { useSelector } from 'react-redux'

import { activeStepSelector } from '../../../../../modules/selectors/forms'

export function useProgressItems() {
  const activeStep = useSelector(activeStepSelector)

  return [
    {
      label: 'Upload file',
      isActive: activeStep === 0,
      isSuccess: activeStep > 0
    },
    {
      label: 'Select publication dates',
      isActive: activeStep === 1,
      isSuccess: activeStep > 1
    }
  ]
}
