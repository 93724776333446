import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'

import DownloadContract from '../DownloadContract'
import Can from '../../../../../features/components/Can'
import ResponsiveContractPdfViewer from '../../../../../features/components/Contract/ResponsiveContractPdfViewer'
import PdfPageSkeleton from '../../../../../features/components/Contract/ResponsiveContractPdfViewer/ContractPdfViewer/Skeleton/PdfPageSkeleton'

import { getAccessibleUrl } from '../../../../../modules/actions/files'
import { fileAccessibleURLSelector } from '../../../../../modules/selectors/files'
import { getMediaOrderFilesIsLoadingSelector } from '../../../../../modules/selectors/mediaOrders'

import { AMENDMENT_PERMISSION, UPLOAD_MEDIA_ORDER_FILES } from '../../../../../constants/permissions'

import useStyles from './styles'

const MediaOrderFileButtons = ({ fileUrl, downloadButtonClassName }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  // the url is private and has token to access it, often the token is already expired when user tries to open it
  // to make sure the url can be accessed, need to get accessible url
  const { [fileUrl]: accessibleUrl } = useSelector(fileAccessibleURLSelector)
  const mediaOrderFilesIsLoading = useSelector(getMediaOrderFilesIsLoadingSelector)
  const isAccessibleUrlLoading = !!accessibleUrl && accessibleUrl.isLoading

  useEffect(() => {
    if (fileUrl) {
      dispatch(getAccessibleUrl(fileUrl))
    }
  }, [dispatch, fileUrl])

  return (
    <div className={classes.pdfContainer}>
      {mediaOrderFilesIsLoading || isAccessibleUrlLoading ? (
        <>
          <div className={classes.actionsContainer}>
            <Can I="create" a={AMENDMENT_PERMISSION}>
              <Skeleton width="140px" height="37px" />
            </Can>
            <Can I="manage" a={UPLOAD_MEDIA_ORDER_FILES}>
              <Skeleton width="80px" height="37px" />
            </Can>
            <Skeleton width="170px" height="37px" />
          </div>
          <PdfPageSkeleton />
        </>
      ) : (
        <>
          <div className={classes.actionsContainer}>
            {/*pass accessibleUrl?.data instead of fileUrl to avoid PDF rendering issues based on loading state change*/}
            <DownloadContract
              pdfUrl={
                accessibleUrl?.data +
                // sometimes the accessible url is the same as the requested file url, to prevent the bug, add random text to the signature
                'new_contract_file'
              }
              className={downloadButtonClassName}
            />
          </div>
          <div>
            <ResponsiveContractPdfViewer pdfFile={accessibleUrl?.data} showPagesCount={false} />
          </div>
        </>
      )}
    </div>
  )
}

MediaOrderFileButtons.propTypes = {
  fileUrl: PropTypes.string,
  downloadButtonClassName: PropTypes.string
}

export default MediaOrderFileButtons
