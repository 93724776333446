import React from 'react'
import { useTranslation } from 'react-i18next'

import AttachedFilesPaginatedMultiSelect from './AttachedFilesPaginatedMultiSelect'

import useDrawerFormStyles from '../../../../../../../styles/common/drawerForms'

const AttachedFilesSection = ({ formik }) => {
  const { t } = useTranslation()
  const drawerFormClasses = useDrawerFormStyles()

  return (
    <div className={drawerFormClasses.section}>
      <h3 className={drawerFormClasses.sectionTitle}>{t('Attached files')}</h3>
      <AttachedFilesPaginatedMultiSelect formik={formik} />
    </div>
  )
}

export default AttachedFilesSection
