import { createStyles } from '../../../../../../../../styles/helpers'
import { darkGrey, orangeShop } from '../../../../../../../../styles/const/colors'

export default createStyles({
  competingWarningIcon: {
    width: '20px',
    height: '20px',
    color: orangeShop
  },
  dropdownBodyWrapper: {
    padding: '22px 22px 40px'
  },
  description: {
    color: darkGrey,
    marginTop: 4
  }
})
