import { useDispatch, useSelector } from 'react-redux'
import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'

import Form from '../../../../../../components/Form'
import SpecificationsDocumentFormContent from '../../SpecificationsDocumentFormContent'

import {
  mediaProductAttachedFileSelector,
  updateMediaProductAttachedFileErrorSelector,
  updateMediaProductAttachedFileIsLoadingSelector,
  updateMediaProductAttachedFileWasUpdatedSelector
} from '../../../../../../modules/selectors/mediaOrdersProducts'
import {
  clearUpdateMediaProductAttachedFile,
  updateMediaProductAttachedFile
} from '../../../../../../modules/actions/mediaOrdersProducts'

import { SPECIFICATIONS_DOCUMENT_EDIT } from '../../../../../../constants/forms'
import { getInitialValues } from './fields'
import { validationSchema } from '../../validation'
import { FILE, FILE_NAME } from '../../fields'

function SpecificationsDocumentEditForm() {
  const dispatch = useDispatch()

  const mediaProductAttachedFile = useSelector(mediaProductAttachedFileSelector)

  const itemWasUpdated = useSelector(updateMediaProductAttachedFileWasUpdatedSelector)

  const onSubmit = useCallback(
    values => {
      const data = {
        [FILE]: values[FILE],
        [FILE_NAME]: values[FILE_NAME]
      }

      dispatch(updateMediaProductAttachedFile(mediaProductAttachedFile?.id, data))
    },
    [dispatch, mediaProductAttachedFile?.id]
  )

  const handleClearHandler = useCallback(() => {
    dispatch(clearUpdateMediaProductAttachedFile())
  }, [dispatch])

  const initialValues = useMemo(() => {
    return getInitialValues(mediaProductAttachedFile)
  }, [mediaProductAttachedFile])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  return (
    <Form
      formik={formik}
      formName={SPECIFICATIONS_DOCUMENT_EDIT}
      successSubmit={itemWasUpdated}
      errorSelector={updateMediaProductAttachedFileErrorSelector}
      isLoadingSelector={updateMediaProductAttachedFileIsLoadingSelector}
      clearHandler={handleClearHandler}
    >
      <SpecificationsDocumentFormContent formik={formik} />
    </Form>
  )
}

export default SpecificationsDocumentEditForm
