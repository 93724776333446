import { race, take } from 'typed-redux-saga'
import { all, call, put, takeEvery, takeLatest, select } from 'redux-saga/effects'

import {
  UPDATE_BATCH_INVENTORY,
  CREATE_INVENTORY,
  CREATE_PRODUCT,
  updateBatchInventoryFailure,
  updateBatchInventorySuccess,
  createInventoryFailure,
  createInventorySuccess,
  createProductFailure,
  createProductSuccess,
  DELETE_INVENTORY,
  DELETE_PRODUCT,
  deleteInventoryFailure,
  deleteInventorySuccess,
  deleteProductFailure,
  deleteProductSuccess,
  GET_INVENTORY,
  GET_PRODUCT,
  GET_PRODUCTS,
  GET_PRODUCTS_GRAPH,
  getInventoryFailure,
  getInventorySuccess,
  getProductFailure,
  getProductsFailure,
  getProductsGraphFailure,
  getProductsGraphSuccess,
  getProductsSuccess,
  getProductSuccess,
  UPDATE_INVENTORY,
  UPDATE_PRODUCT,
  updateInventoryFailure,
  updateInventorySuccess,
  updateProductFailure,
  updateProductSuccess,
  GET_PRODUCTS_TAGS,
  getProductsTagsFailure,
  getProductsTagsSuccess,
  updateProductsOrderSuccess,
  updateProductsOrderFailure,
  UPDATE_PRODUCTS_ORDER,
  updateProductGraphDataInProductsList,
  GET_PRODUCT_GRAPH,
  getProductGraphSuccess,
  getProductGraphFailure,
  getInventoryLastItemSuccess,
  getInventoryLastItemFailure,
  GET_INVENTORY_LAST_ITEM,
  GET_MEDIA_PRODUCTS_AS_CSV,
  getMediaProductsAsCsvSuccess,
  getMediaProductsAsCsvFailure,
  GET_INVENTORY_GROUP,
  getInventoryGroupSuccess,
  getInventoryGroupFailure,
  GET_INVENTORY_REPORT,
  getInventoryReportSuccess,
  getInventoryReportFailure,
  CLEAR_GET_INVENTORY_REPORT,
  CLEAR_GET_MEDIA_PRODUCTS_AS_CSV,
  GET_MEDIA_SUB_CATEGORIES,
  getMediaSubCategoriesSuccess,
  getMediaSubCategoriesFailure,
  GET_UTILISATION_REPORT,
  getUtilisationReportSuccess,
  getUtilisationReportFailure,
  CLEAR_INVENTORY,
  GET_MEDIA_PRODUCT_ATTACHED_FILES,
  getMediaProductAttachedFilesSuccess,
  getMediaProductAttachedFilesFailure,
  CREATE_MEDIA_PRODUCT_ATTACHED_FILE,
  createMediaProductAttachedFileSuccess,
  createMediaProductAttachedFileFailure,
  GET_MEDIA_PRODUCT_ATTACHED_FILE,
  getMediaProductAttachedFileSuccess,
  getMediaProductAttachedFileFailure,
  updateMediaProductAttachedFileSuccess,
  updateMediaProductAttachedFileFailure,
  UPDATE_MEDIA_PRODUCT_ATTACHED_FILE,
  deleteMediaProductAttachedFileSuccess,
  deleteMediaProductAttachedFileFailure,
  DELETE_MEDIA_PRODUCT_ATTACHED_FILE
} from '../actions/mediaOrdersProducts'

import {
  updateBatchInventoryService,
  createInventoryService,
  createProductService,
  deleteInventoryService,
  deleteProductService,
  getInventoryService,
  getProductService,
  getProductsGraphService,
  getProductsService,
  updateInventoryService,
  updateProductService,
  getProductsTagsService,
  updateProductsOrderService,
  getProductGraphService,
  getInventoryLastItemService,
  getMediaProductsAsCsvService,
  getInventoryGroupService,
  getInventoryReportService,
  getMediaSubCategoriesService,
  getUtilisationReportService,
  getMediaProductAttachedFilesService,
  createMediaProductAttachedFileService,
  getMediaProductAttachedFileService,
  updateMediaProductAttachedFileService,
  deleteMediaProductAttachedFileService
} from '../services/mediaOrdersProducts'

import { selectedControllerIdSelector } from '../selectors/app'
import { getProductTags } from '../actions/tags'

import { mediaProductTagsParams } from '../../features/components/mediaProductsComponents/MediaProductsFilters'
import { productTagsSelector } from '../selectors/tags'
import { TAGS } from '../../forms/Multiplatform/MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'
import { handleWebSocketRequest, takeLatestIgnorePrevious } from '../../helpers/modules/saga'

function* mediaOrdersProductsWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_PRODUCTS_TAGS, getProductsTagsWorker),
    takeLatest(GET_PRODUCTS, getProductsWorker),
    takeEvery(GET_PRODUCT, getProductWorker),
    takeEvery(GET_PRODUCT_GRAPH, getProductGraphWorker),
    takeEvery(GET_PRODUCTS_GRAPH, getProductsGraphWorker),
    takeEvery(GET_UTILISATION_REPORT, getUtilisationReportWorker),
    takeEvery(CREATE_PRODUCT, createProductWorker),
    takeEvery(UPDATE_PRODUCT, updateProductWorker),
    takeEvery(DELETE_PRODUCT, deleteProductWorker),
    takeEvery(UPDATE_PRODUCTS_ORDER, updateProductsOrderWorker),
    takeEvery(UPDATE_INVENTORY, updateInventoryWorker),
    takeEvery(CREATE_INVENTORY, createInventoryWorker),
    ...takeLatestIgnorePrevious({ ACTION: GET_INVENTORY, worker: getInventoryWorker }),
    takeEvery(UPDATE_BATCH_INVENTORY, updateBatchInventoryWorker),
    takeEvery(DELETE_INVENTORY, deleteInventoryWorker),
    takeLatest(GET_INVENTORY_GROUP, getInventoryGroupWorker),
    takeEvery(GET_INVENTORY_LAST_ITEM, getInventoryLastItemWorker),
    ...takeLatestIgnorePrevious({ ACTION: GET_INVENTORY_REPORT, worker: getInventoryReportWorker }),
    takeEvery(GET_MEDIA_PRODUCTS_AS_CSV, getMediaProductsAsCsvWorker),
    takeEvery(GET_MEDIA_SUB_CATEGORIES, getMediaSubCategoriesWorker),
    takeEvery(GET_MEDIA_PRODUCT_ATTACHED_FILES, getMediaProductAttachedFilesWorker),
    takeEvery(CREATE_MEDIA_PRODUCT_ATTACHED_FILE, createMediaProductAttachedFileWorker),
    takeEvery(GET_MEDIA_PRODUCT_ATTACHED_FILE, getMediaProductAttachedFileWorker),
    takeEvery(UPDATE_MEDIA_PRODUCT_ATTACHED_FILE, updateMediaProductAttachedFileWorker),
    takeEvery(DELETE_MEDIA_PRODUCT_ATTACHED_FILE, deleteMediaProductAttachedFileWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* getInventoryReportWorker({ params, period }) {
  try {
    const { response } = yield race({
      response: call(getInventoryReportService, params),
      // need to cancel this task, if user change the page, but task still in progress
      cancel: take(CLEAR_GET_INVENTORY_REPORT)
    })
    if (response) {
      yield put(getInventoryReportSuccess(response, period))
    }
  } catch (error) {
    yield put(getInventoryReportFailure(error, period))
  }
}

function* getProductsTagsWorker({ params }) {
  try {
    const response = yield call(getProductsTagsService, params)

    yield put(getProductsTagsSuccess(response))
  } catch (e) {
    yield put(getProductsTagsFailure(e))
  }
}

function* getProductsWorker({ params }) {
  try {
    // the getProductsService is used outside saga flow, so double check all places in case of some changes..
    const response = yield call(getProductsService, params)

    yield put(getProductsSuccess(response))
  } catch (e) {
    yield put(getProductsFailure(e))
  }
}

function* getProductWorker({ params }) {
  try {
    const response = yield call(getProductService, params)

    yield put(getProductSuccess(response))
  } catch (e) {
    yield put(getProductFailure(e))
  }
}

function* getProductGraphWorker({ id, params }) {
  try {
    const response = yield call(getProductGraphService, id, params)
    yield put(getProductGraphSuccess(response))
  } catch (error) {
    yield put(getProductGraphFailure(error))
  }
}

function* getProductsGraphWorker({ params }) {
  try {
    const response = yield call(getProductsGraphService, params)

    yield put(getProductsGraphSuccess(response))
  } catch (e) {
    yield put(getProductsGraphFailure(e))
  }
}

function* getUtilisationReportWorker({ params }) {
  try {
    const response = yield call(getUtilisationReportService, params)
    yield put(getUtilisationReportSuccess(response))
  } catch (error) {
    yield put(getUtilisationReportFailure(error))
  }
}

function* createProductWorker({ productData }) {
  try {
    const response = yield call(createProductService, productData)

    yield put(createProductSuccess(response))

    // reload tags if new tag was created during product create
    const productsTags = yield select(productTagsSelector)
    const createdProductTags = response[TAGS]

    // find new tags in created product tags and reload tags if new tag was created:
    const productTagTitles = productsTags.map(tag => tag.title)
    const isNewTagCreated = createdProductTags.some(tagId => !productTagTitles.includes(tagId))
    if (isNewTagCreated) {
      const controllerId = yield select(selectedControllerIdSelector)
      yield put(getProductTags({ ...mediaProductTagsParams, controller: controllerId }))
    }
  } catch (e) {
    yield put(createProductFailure(e))
  }
}

function* updateProductWorker({ id, data }) {
  try {
    const response = yield call(updateProductService, id, data)
    yield put(updateProductSuccess(response))

    // reload tags if new tag was created during product edit
    const productsTags = yield select(productTagsSelector)
    const updatedProductTags = response[TAGS]

    // find new tags in updated product tags and reload tags if new tag was created:
    const productTagTitles = productsTags.map(tag => tag.title)
    const isNewTagCreated = updatedProductTags.some(tagId => !productTagTitles.includes(tagId))
    if (isNewTagCreated) {
      const controllerId = yield select(selectedControllerIdSelector)
      yield put(getProductTags({ ...mediaProductTagsParams, controller: controllerId }))
    }
  } catch (error) {
    yield put(updateProductFailure(error))
  }
}

function* deleteProductWorker({ id }) {
  try {
    yield call(deleteProductService, id)
    yield put(deleteProductSuccess({ id }))
  } catch (error) {
    yield put(deleteProductFailure(error))
  }
}

function* getInventoryWorker({ params }) {
  const dateRange = {
    date_from: params.date_from,
    date_to: params.date_to
  }
  try {
    const { response } = yield race({
      response: call(getInventoryService, params),
      // need to cancel this task, if user change the page, but task still in progress
      cancel: take(CLEAR_INVENTORY)
    })
    if (response) {
      yield put(getInventorySuccess(response, dateRange))
    }
  } catch (e) {
    yield put(getInventoryFailure(e, dateRange))
  }
}

function* updateInventoryWorker({ id, inventoryData }) {
  try {
    const response = yield call(updateInventoryService, id, inventoryData)
    yield put(updateInventorySuccess(response))
  } catch (e) {
    yield put(updateInventoryFailure(e))
  }
}

function* createInventoryWorker({ inventoryData }) {
  try {
    const response = yield call(createInventoryService, inventoryData)
    yield put(createInventorySuccess(response))
  } catch (e) {
    yield put(createInventoryFailure(e))
  }
}

function* updateBatchInventoryWorker({ data, productId }) {
  try {
    const response = yield call(updateBatchInventoryService, data)
    yield put(updateBatchInventorySuccess(response))
    // need to update product graph data after updating inventory without reloading page
    yield put(updateProductGraphDataInProductsList(productId, response))
  } catch (error) {
    yield put(updateBatchInventoryFailure(error))
  }
}

function* deleteInventoryWorker({ id }) {
  try {
    yield call(deleteInventoryService, id)
    yield put(deleteInventorySuccess(id))
  } catch (error) {
    yield put(deleteInventoryFailure(error))
  }
}

function* getInventoryGroupWorker({ params }) {
  try {
    const response = yield call(getInventoryGroupService, params)
    yield put(getInventoryGroupSuccess(response))
  } catch (error) {
    yield put(getInventoryGroupFailure(error))
  }
}

function* updateProductsOrderWorker({ data }) {
  try {
    const response = yield call(updateProductsOrderService, data)
    yield put(updateProductsOrderSuccess(response))
  } catch (error) {
    yield put(updateProductsOrderFailure(error))
  }
}

function* getInventoryLastItemWorker({ params }) {
  try {
    const response = yield call(getInventoryLastItemService, params)
    yield put(getInventoryLastItemSuccess(response))
  } catch (error) {
    yield put(getInventoryLastItemFailure(error))
  }
}

function* getMediaProductsAsCsvWorker({ params }) {
  try {
    yield call(handleWebSocketRequest, {
      service: getMediaProductsAsCsvService,
      serviceProps: {
        params
      },
      cancelActionType: CLEAR_GET_MEDIA_PRODUCTS_AS_CSV,
      successAction: getMediaProductsAsCsvSuccess,
      failureAction: getMediaProductsAsCsvFailure
    })
  } catch (error) {
    yield put(getMediaProductsAsCsvFailure(error))
  }
}

function* getMediaSubCategoriesWorker({ params }) {
  try {
    const response = yield call(getMediaSubCategoriesService, params)
    yield put(getMediaSubCategoriesSuccess(response))
  } catch (error) {
    yield put(getMediaSubCategoriesFailure(error))
  }
}

function* getMediaProductAttachedFilesWorker({ params }) {
  try {
    const response = yield call(getMediaProductAttachedFilesService, params)
    yield put(getMediaProductAttachedFilesSuccess(response))
  } catch (error) {
    yield put(getMediaProductAttachedFilesFailure(error))
  }
}

function* createMediaProductAttachedFileWorker({ data }) {
  try {
    const response = yield call(createMediaProductAttachedFileService, data)
    yield put(createMediaProductAttachedFileSuccess(response))
  } catch (error) {
    yield put(createMediaProductAttachedFileFailure(error))
  }
}

function* getMediaProductAttachedFileWorker({ params }) {
  try {
    const response = yield call(getMediaProductAttachedFileService, params)
    yield put(getMediaProductAttachedFileSuccess(response))
  } catch (error) {
    yield put(getMediaProductAttachedFileFailure(error))
  }
}

function* updateMediaProductAttachedFileWorker({ id, data }) {
  try {
    const response = yield call(updateMediaProductAttachedFileService, id, data)
    yield put(updateMediaProductAttachedFileSuccess(response))
  } catch (error) {
    yield put(updateMediaProductAttachedFileFailure(error))
  }
}

function* deleteMediaProductAttachedFileWorker({ id }) {
  try {
    yield call(deleteMediaProductAttachedFileService, id)
    yield put(deleteMediaProductAttachedFileSuccess({ id }))
  } catch (error) {
    yield put(deleteMediaProductAttachedFileFailure(error))
  }
}

export default mediaOrdersProductsWatcher
