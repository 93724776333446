import React, { useEffect } from 'react'

import { UnauthorizedRoutes } from '../../../Routes'
import { AbilityContext } from '../Can'

import history from '../../../history'

import { setLocalStorage } from '../../../helpers/storage'
import { createJsonFromQueryString } from '../../../helpers/url'
import { getPermissionsForUnauthorizedUser } from '../../../constants/permissions'

import { NOT_PLATFORM_USER_TOKEN } from '../../../constants/api'

const UnauthorizedApp = () => {
  const permissions = getPermissionsForUnauthorizedUser()

  useEffect(() => {
    // token param is used to provide auth access to not registered users
    const { token } = createJsonFromQueryString(history.location.search, false)

    setLocalStorage(NOT_PLATFORM_USER_TOKEN, token)

    return () => {
      setLocalStorage(NOT_PLATFORM_USER_TOKEN, '')
    }
  }, [])

  return (
    <AbilityContext.Provider value={permissions}>
      <UnauthorizedRoutes />
    </AbilityContext.Provider>
  )
}

export default UnauthorizedApp
