import React from 'react'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'

import { formatCurrency, formatNumber } from '../../../../helpers/numbers'
import { MobileDown, MobileUp } from '../../../../components/hoc/ResponsiveRendering'

import useStyles from './styles'

// component which represent price per each day
function PeriodDateCell({ formattedDate, periodOptions, formattedDateBE, currencySymbol, isPseudoDisabled }) {
  const classes = useStyles()
  const periodData = periodOptions.find(period => period.value === formattedDateBE)
  const { t } = useTranslation()

  if (periodData?.overdue || periodData?.isCompeting) {
    return (
      <div className={classes.root}>
        <span
          className={classnames(classes.day, {
            [classes.callToBookDate]: periodData?.overdue,
            [classes.reservedDate]: periodData?.isCompeting
          })}
        >
          {formattedDate}
        </span>
        {periodData?.overdue ? (
          <span className={classes.callToBook}>{t('Call to book')}</span>
        ) : (
          <span className={classes.reserved}>{t('Reserved')}</span>
        )}
      </div>
    )
  }

  return periodData && !periodData.disabled ? (
    <div
      className={classnames(classes.root, {
        [classes.pseudoDisabled]: isPseudoDisabled
      })}
    >
      <span className={classes.day}>{formattedDate}</span>
      <span className={classnames('price', classes.price)}>
        <MobileUp>{formatCurrency(periodData.price, { max: 0 }, { symbol: currencySymbol })}</MobileUp>
        {/*there is no space in cell for currency symbol on mobile, so it is hidden*/}
        <MobileDown>{formatNumber(periodData.price)}</MobileDown>
      </span>
    </div>
  ) : (
    <span
      className={classnames(classes.root, {
        [classes.pseudoDisabled]: isPseudoDisabled
      })}
    >
      {formattedDate}
    </span>
  )
}

export default React.memo(PeriodDateCell)
