import React from 'react'
import { useTranslation } from 'react-i18next'
import Skeleton from 'react-loading-skeleton'
import PropTypes from 'prop-types'

import Button from '../../../../components/Button'
import TableActionsSkeleton from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/ProductsManage/TableActions/Skeleton'
import AdditionalMediaOrderInfoSkeleton from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/AdditionalMediaOrderInfo/Skeleton'
import CalendarTableSkeleton from '../../ProductsCalendarPreview/Skeleton'
import TableDataLoader from '../../../../components/Table/TableDataLoader'
import ProposalBuilderPageBreadcrumbs from '../ProposalBuilderPageBreadcrumbs'

import {
  contractWasLoadedSelector,
  getContractErrorSelector,
  getContractIsLoadingSelector
} from '../../../../modules/selectors/contracts'

import useCommonStyles from '../../../../styles/common/listPage'

function SkeletonQuotationBuilderForm({ showHeading = true, hasTopMargin = true }) {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()

  return (
    <div>
      {showHeading && (
        <div className={commonClasses.heading}>
          <div>
            <ProposalBuilderPageBreadcrumbs />
            <h3 className={commonClasses.title}>
              {t('Proposal')} <Skeleton width={60} />
            </h3>
          </div>
          <Button isSmall>
            <Skeleton width={120} />
          </Button>
        </div>
      )}
      <AdditionalMediaOrderInfoSkeleton hasTopMargin={hasTopMargin} />
      <CalendarTableSkeleton />
      <TableActionsSkeleton />
      <TableDataLoader
        errorSelector={getContractErrorSelector}
        wasLoadedSelector={contractWasLoadedSelector}
        isLoadingSelector={getContractIsLoadingSelector}
      />
    </div>
  )
}

SkeletonQuotationBuilderForm.propTypes = {
  showHeading: PropTypes.bool,
  hasTopMargin: PropTypes.bool
}

export default SkeletonQuotationBuilderForm
