import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import ProposalMediaOrderRecipients from './ProposalMediaOrderRecipients'
import { MediaOrderFormContext } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'

import { useRedirectToGeneratedMediaOrder } from '../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate/hooks/useRedirectToGeneratedMediaOrder'
import { useRequiredFields } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useRequiredFields'

import { getAdditionalOrderInfoValidationSchema } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/validation'

import { activeContractSelector, updateContractIsLoadingSelector } from '../../../../modules/selectors/contracts'
import { BRAND, BUYER, CAMPAIGN_NAME } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/fields'

const ProposalMediaOrderGenerate = ({ contractId }) => {
  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const activeContract = useSelector(activeContractSelector)
  const updateContractIsLoading = useSelector(updateContractIsLoadingSelector)

  const { askBrandName, askCampaignName, isBrandRequired, askBuyer } = useRequiredFields(contextSelfAccountData)
  const [isMediaOrderAdditionalFieldsValid, setIsMediaOrderAdditionalFieldsValid] = useState(false)

  useEffect(() => {
    const validateFields = async () => {
      const additionalMediaOrderInfoValidation = getAdditionalOrderInfoValidationSchema({
        askBrandName,
        askCampaignName,
        isBrandRequired,
        askBuyer
      })

      try {
        // Validate the data
        const mediaOrderData = activeContract?.media_order
        const mediaOrderFields = {
          [CAMPAIGN_NAME]: mediaOrderData?.[CAMPAIGN_NAME],
          // we manage brand as ID, but on BE it stores as object
          [BRAND]: mediaOrderData?.[BRAND]?.id,
          [BUYER]: {
            ...mediaOrderData?.[BUYER],
            value: mediaOrderData?.[BUYER]?.id
          }
        }
        await additionalMediaOrderInfoValidation.validate(mediaOrderFields, { abortEarly: false })
        setIsMediaOrderAdditionalFieldsValid(true)
      } catch (err) {
        console.warn('err', err)
        setIsMediaOrderAdditionalFieldsValid(false)
      }
    }

    validateFields()
  }, [activeContract?.media_order, askBrandName, askCampaignName, isBrandRequired, askBuyer])

  // when we are in create mode and are creating the contract for different self account to selectedSelfAccount, then:
  // we need to pass the self account id to redirect for correct media order form
  const contractSelfAccountId = activeContract?.account
  const redirectToGeneratedMediaOrder = useRedirectToGeneratedMediaOrder(contractSelfAccountId)

  return (
    <ProposalMediaOrderRecipients
      contractId={contractId}
      onSuccessSubmit={redirectToGeneratedMediaOrder}
      disabled={updateContractIsLoading || !isMediaOrderAdditionalFieldsValid}
    />
  )
}

export default ProposalMediaOrderGenerate
