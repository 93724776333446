import React from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import Checkbox from '../../../../../../../../../components/Form/Checkbox'

import { getFormattedMediaDate } from '../../../../../../../../../pages/MediaOrderSummary/MediaOrderSummaryContent/RequestedFilesSection/helpers'

import { SELECTED_PERIODS } from '../../../../fields'

import useStyles from './styles'

function PublicationDatesFields({ values, setFieldValue, fileId, bookedMedias }) {
  const classes = useStyles()
  const { t } = useTranslation()

  const bookedMediaList = bookedMedias.map(bookedMedia => ({
    uploaded_files: bookedMedia.uploaded_files,
    name: `[${SELECTED_PERIODS}][${fileId}][${bookedMedia.id}]`,
    // there could be used media with different periods like week, 2 weeks, month, etc.
    title: getFormattedMediaDate(bookedMedia.date_start, bookedMedia.period)
  }))

  return bookedMediaList.map(({ name, title, uploaded_files: uploadedFiles }) => {
    const isFileAlreadyUploaded = !!uploadedFiles?.find(item => item.media_product_file === fileId)

    return (
      <Checkbox
        key={name}
        id={`checkbox_${name}`}
        title={t(title)}
        isLarge
        checked={!!getIn(values, name)}
        onCheck={() => {
          setFieldValue(name, !getIn(values, name))
        }}
        className={classes.periodCheckbox}
        disabled={isFileAlreadyUploaded}
      >
        {isFileAlreadyUploaded && <span className={classes.alreadyUploadedDescription}>({t('already uploaded')})</span>}
      </Checkbox>
    )
  })
}

PublicationDatesFields.propTypes = {
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  bookedMedias: PropTypes.array,
  fileId: PropTypes.number
}

export default PublicationDatesFields
