import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import AdaptiveActions from '../../../../../../../../../../features/components/AdaptiveActions'

import { mediaOrderResendConfirmationEmail } from '../../../../../../../../../../modules/actions/mediaOrders'
import { mediaOrderSelector } from '../../../../../../../../../../modules/selectors/mediaOrders'

import {
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS
} from '../../../../../../../../../../features/components/notificationFormFields/fields'

const RecipientActions = ({ data }) => {
  const { t } = useTranslation()

  const mediaOrder = useSelector(mediaOrderSelector)
  const mediaOrderId = mediaOrder.id

  const dispatch = useDispatch()

  const { userId, email, id: recipientRowId } = data

  const resendNotificationHandler = useCallback(() => {
    let requestData = {}
    if (userId) {
      requestData = {
        [NOTIFICATION_USERS]: [userId]
      }
    } else {
      requestData = {
        [NOTIFICATION_EMAILS]: [email]
      }
    }
    dispatch(mediaOrderResendConfirmationEmail(mediaOrderId, requestData, recipientRowId))
  }, [dispatch, email, mediaOrderId, userId, recipientRowId])

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Resend'),
        onClickHandler: resendNotificationHandler
      }
    ],
    [resendNotificationHandler, t]
  )

  return <AdaptiveActions options={dropdownOptions} />
}

RecipientActions.propTypes = {
  data: PropTypes.shape({
    userId: PropTypes.number,
    email: PropTypes.string,
    id: PropTypes.string.isRequired
  }).isRequired
}

export default RecipientActions
