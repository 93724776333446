import React, { useCallback, useMemo } from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Form from '../../../../../../components/Form'
import ProposalMediaOrderRecipientsFormContent from './ProposalMediaOrderRecipientsFormContent'

import { useClearMediaOrderForm } from '../../../../../../forms/Multiplatform/MediaOrderForms/ContractMediaOrderGenerate/hooks/useClearMediaOrderForm'
import { transformNotificationUsersOrEmailsToBE } from '../../../../../../features/components/notificationFormFields/formatters'

import { clearGenerateMediaOrder, generateMediaOrder } from '../../../../../../modules/actions/mediaOrders'
import {
  generateMediaOrderErrorSelector,
  generateMediaOrderIsLoadingSelector,
  mediaOrderWasGeneratedSelector
} from '../../../../../../modules/selectors/mediaOrders'
import { membersSelector } from '../../../../../../modules/selectors/selfAccountTeam'

import { PROPOSAL_MEDIA_ORDER_RECIPIENTS } from '../../../../../../constants/forms'
import { validationSchema } from './validation'
import { getInitialValues } from './fields'

function ProposalMediaOrderRecipientsForm({ contractId, onSuccessSubmit }) {
  const dispatch = useDispatch()

  const mediaOrderWasCreated = useSelector(mediaOrderWasGeneratedSelector)
  const members = useSelector(membersSelector)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformNotificationUsersOrEmailsToBE(values)

      dispatch(generateMediaOrder(contractId, {}, transformedData))
    },
    [dispatch, contractId]
  )

  const initialValues = useMemo(() => {
    return getInitialValues(members)
  }, [members])

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit
  })

  const clearItemSubmit = useCallback(() => {
    dispatch(clearGenerateMediaOrder())
  }, [dispatch])

  const handleSubmitFormClear = useClearMediaOrderForm()

  const handleSuccessCreateMediaOrder = useCallback(() => {
    handleSubmitFormClear()
    onSuccessSubmit && onSuccessSubmit()
  }, [handleSubmitFormClear, onSuccessSubmit])

  return (
    <Form
      formik={formik}
      formName={PROPOSAL_MEDIA_ORDER_RECIPIENTS}
      // processing
      successSubmit={mediaOrderWasCreated}
      errorSelector={generateMediaOrderErrorSelector}
      isLoadingSelector={generateMediaOrderIsLoadingSelector}
      // after form submit
      clearHandler={clearItemSubmit}
      submitText="Confirm and book"
      onSuccessSubmit={handleSuccessCreateMediaOrder}
    >
      <ProposalMediaOrderRecipientsFormContent formik={formik} />
    </Form>
  )
}

ProposalMediaOrderRecipientsForm.propTypes = {
  contractId: PropTypes.number.isRequired,
  onSuccessSubmit: PropTypes.func
}

export default ProposalMediaOrderRecipientsForm
