import { useCallback, useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MediaOrderFormContext } from '../../../../forms/Multiplatform/MediaOrderForms/MediaOrderFormContext'

import { transformValuesToBE } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/formatters'
import {
  clearCreateContract,
  createContract,
  updateContract,
  clearUpdateContract
} from '../../../../modules/actions/contracts'

import {
  activeContractSelector,
  contractWasCreatedSelector,
  createContractsErrorSelector,
  createContractsIsLoadingSelector,
  contractWasUpdatedSelector,
  updateContractIsLoadingSelector,
  updateContractErrorSelector
} from '../../../../modules/selectors/contracts'
import { selectedControllerIdSelector } from '../../../../modules/selectors/app'

export function useHandleSubmitForm() {
  const dispatch = useDispatch()

  const activeContract = useSelector(activeContractSelector)
  const createdContractId = activeContract?.id

  // if contract was created during creation or loaded for editing the quotation, we are in edit mode
  const [isEditMode, setEditMode] = useState(Boolean(createdContractId))
  const controllerId = useSelector(selectedControllerIdSelector)
  const contractWasCreated = useSelector(contractWasCreatedSelector)
  const contractWasUpdated = useSelector(contractWasUpdatedSelector)
  const { contextSelfAccountData, currency } = useContext(MediaOrderFormContext)

  const onSubmit = useCallback(
    values => {
      const transformedData = transformValuesToBE({
        selfAccountData: contextSelfAccountData,
        controllerId,
        selectedCurrency: currency,
        values
      })
      const contractData = {
        detail: transformedData,
        // in this case the PDF file generation will occur in the background and will not affect the response time
        // as we are not using the PDF file for contract builder, we can set this to false
        generate_pdf: false,
        quotation: true, // BE need to get it passed for correct work
        without_requirements: true, // allows to create contract without terms
        set_amendment_cost: true, // allows to set modified pricing
        account: contextSelfAccountData.id,
        controller: controllerId
      }

      if (createdContractId) {
        dispatch(updateContract(createdContractId, contractData))
      } else {
        dispatch(createContract(contractData))
      }
    },
    [createdContractId, contextSelfAccountData, controllerId, currency, dispatch]
  )

  const clearCreateHandler = useCallback(() => dispatch(clearCreateContract()), [dispatch])
  const clearUpdateHandler = useCallback(() => dispatch(clearUpdateContract()), [dispatch])

  useEffect(() => {
    return () => {
      // stop handling success if page is left during update/create contract
      // if not cancel, then it will save data in redux which will not be cleared
      // and in places with handling success on update/create contract form it will trigger success submit action
      clearCreateHandler()
      clearUpdateHandler()
    }
  }, [dispatch, clearCreateHandler, clearUpdateHandler])

  return {
    onSubmit,
    setEditMode,
    clearSubmitHandler: isEditMode ? clearUpdateHandler : clearCreateHandler,
    successSubmit: isEditMode ? contractWasUpdated : contractWasCreated,
    errorSelector: isEditMode ? updateContractErrorSelector : createContractsErrorSelector,
    isLoadingSelector: isEditMode ? updateContractIsLoadingSelector : createContractsIsLoadingSelector
  }
}
