import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import SpecificationsDocumentEditForm from './SpecificationsDocumentEditForm'

import useManageEditFormData from '../../../../../hooks/formHooks/useManageEditFormData'

import {
  mediaProductAttachedFileSelector,
  mediaProductAttachedFileWasLoadedSelector
} from '../../../../../modules/selectors/mediaOrdersProducts'
import {
  clearGetMediaProductAttachedFile,
  getMediaProductAttachedFile
} from '../../../../../modules/actions/mediaOrdersProducts'

import { SPECIFICATIONS_DOCUMENT_EDIT } from '../../../../../constants/forms'

const SpecificationsDocumentEdit = () => {
  const dispatch = useDispatch()

  const mediaProductAttachedFile = useSelector(mediaProductAttachedFileSelector)
  const mediaProductAttachedFileWasLoaded = useSelector(mediaProductAttachedFileWasLoadedSelector)

  const getSpecificationsDocumentHandler = useCallback(
    selectedEditItemId => {
      dispatch(getMediaProductAttachedFile({ id: selectedEditItemId }))
    },
    [dispatch]
  )

  const clearSpecificationsDocumentHandler = useCallback(() => {
    dispatch(clearGetMediaProductAttachedFile())
  }, [dispatch])

  useManageEditFormData({
    formName: SPECIFICATIONS_DOCUMENT_EDIT,
    getDataHandler: getSpecificationsDocumentHandler,
    clearDataHandler: clearSpecificationsDocumentHandler,
    loadedDataId: mediaProductAttachedFile.id
  })

  return (
    <FormDrawerWrapper
      formName={SPECIFICATIONS_DOCUMENT_EDIT}
      isFormLoading={!mediaProductAttachedFileWasLoaded}
      title="Edit specifications document"
      showOpenButton={false}
    >
      <SpecificationsDocumentEditForm />
    </FormDrawerWrapper>
  )
}

export default SpecificationsDocumentEdit
