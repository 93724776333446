import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { CAMPAIGN_NAME } from '../../../../forms/Multiplatform/MediaOrderForms/ContractBuilder/fields'

import useStyles from './styles'

const AccountBrandCell = ({ account, brand, campaignName, sequentialList, sequentialIds, isSmall }) => {
  const classes = useStyles()

  const campaignNameToShow = useMemo(() => {
    return sequentialList ? sequentialList[0][CAMPAIGN_NAME] : campaignName
  }, [campaignName, sequentialList])

  // sub rows don't have sequentialIds
  const isSubRow = !sequentialIds

  return (
    <div className={classes.accountBrandContainer}>
      {isSmall ? <h5>{account}</h5> : <h4>{account}</h4>}
      {brand && <p className={classes.brand}>{brand}</p>}
      {campaignNameToShow &&
        // don't show campaign name for sub rows
        !isSubRow && <p className={classes.campaignName}>{campaignNameToShow}</p>}
    </div>
  )
}

AccountBrandCell.propTypes = {
  account: PropTypes.string.isRequired,
  brand: PropTypes.string,
  campaignName: PropTypes.string,
  sequentialList: PropTypes.array,
  sequentialIds: PropTypes.array,
  isSmall: PropTypes.bool
}

export default AccountBrandCell
