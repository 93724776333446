import { createStyles } from '../../../../../../styles/helpers'

import { darkBlue } from '../../../../../../styles/const/colors'
import { expandRowLeftPadding, rowLeftPadding } from '../../../../../../components/Table/styles'

const useStyles = createStyles({
  installationReportTable: {
    fontSize: 12
  },
  row: {
    fontSize: 12
  },
  subTable: {
    border: 'none !important',
    paddingLeft: expandRowLeftPadding - rowLeftPadding // align the sub table rows with the parent table
  },
  subTableRow: {
    color: darkBlue,
    border: 'none !important',
    fontSize: 12
  }
})

export default useStyles
