import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import Drawer from '../../../components/Drawer'
import DrawerOpenTrigger from './DrawerOpenTrigger'
import SkeletonForm from '../../../components/Form/Skeleton'

import useManageFormsDrawer from '../../../hooks/formHooks/useManageFormsDrawer'
import useComponentWillUnmount from '../../../hooks/useComponentWillUnmount'

import { clearFormSuccessSubmit } from '../../../modules/actions/forms'
import { formIsSubmittingSelector, formSuccessSubmittedSelector } from '../../../modules/selectors/forms'

function FormDrawerWrapper({
  title,
  description,
  children,
  // button props
  buttonType = 'button',
  OpenDrawerTrigger,
  showOpenButton = true,
  openButtonText,
  openBtnClassName,
  // form props
  formName,
  closeOnSubmit = true,
  onAfterFormClose,
  // loading props
  SkeletonFormComponent,
  isFormLoading,
  isWideDrawer,
  hasDefaultDrawerContentSpacing,
  drawerContentClassName,
  disabled,
  buttonProps
}) {
  const dispatch = useDispatch()
  // selector is a function to avoid triggering re-renders for other FormDrawers when submit is done for this.
  const formIsSubmitting = useSelector(formIsSubmittingSelector(formName))
  const formWasSubmitted = useSelector(formSuccessSubmittedSelector(formName))

  // managing FormDrawerWrapper open state is doing internally from children components
  const { isFormOpen, openDrawerForm, handleFormClose } = useManageFormsDrawer({
    formName: formName,
    onAfterFormClose
  })

  // when form is unmounted with open state (e.g. redirection inside drawer happens), explicitly close it
  // we also need to use custom unmount hook to track real unmount action (usual useEffect doesn't work)
  useComponentWillUnmount(() => {
    if (isFormOpen) {
      handleFormClose()
    }
  })

  useEffect(() => {
    if (closeOnSubmit && formWasSubmitted) {
      handleFormClose()
    }
    if (formWasSubmitted) {
      dispatch(clearFormSuccessSubmit())
    }
  }, [dispatch, handleFormClose, formWasSubmitted, closeOnSubmit])

  return (
    <>
      {showOpenButton && (
        <DrawerOpenTrigger
          OpenDrawerTrigger={OpenDrawerTrigger}
          buttonType={buttonType}
          openBtnClassName={openBtnClassName}
          openButtonText={openButtonText}
          openDrawerForm={openDrawerForm}
          disabled={disabled}
          buttonProps={buttonProps}
        />
      )}
      <Drawer
        isOpen={isFormOpen}
        onClose={handleFormClose}
        title={title}
        description={description}
        disabledClose={formIsSubmitting}
        isWideDrawer={isWideDrawer}
        // add spacing for the SkeletonForm
        hasDefaultDrawerContentSpacing={isFormLoading ? true : hasDefaultDrawerContentSpacing}
        contentClassName={drawerContentClassName}
      >
        {isFormLoading ? SkeletonFormComponent || <SkeletonForm /> : children}
      </Drawer>
    </>
  )
}

FormDrawerWrapper.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  // button props
  buttonType: PropTypes.oneOf(['button', 'actionText']),
  OpenDrawerTrigger: PropTypes.func,
  showOpenButton: PropTypes.bool,
  openButtonText: PropTypes.string,
  openBtnClassName: PropTypes.string,
  // form props
  formName: PropTypes.string.isRequired,
  closeOnSubmit: PropTypes.bool,
  onAfterFormClose: PropTypes.func,
  // loading props
  SkeletonFormComponent: PropTypes.node,
  isFormLoading: PropTypes.bool,
  hasDefaultDrawerContentSpacing: PropTypes.bool,
  drawerContentClassName: PropTypes.string,
  disabled: PropTypes.bool,
  buttonProps: PropTypes.object
}

export default FormDrawerWrapper
