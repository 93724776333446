import { NOT_PLATFORM_USER_TOKEN } from '../constants/api'

export const clearStorageItem = (key, isSessionStorage) => {
  return isSessionStorage ? sessionStorage.removeItem(key) : localStorage.removeItem(key)
}

export const setStorage = (key, item, isSessionStorage) => {
  try {
    if (typeof item === 'string') {
      isSessionStorage ? sessionStorage.setItem(key, item) : localStorage.setItem(key, item)
    } else {
      const serializedItem = JSON.stringify(item)
      isSessionStorage ? sessionStorage.setItem(key, serializedItem) : localStorage.setItem(key, serializedItem)
    }
  } catch (err) {
    console.error(err)
  }
}

export const setLocalStorage = (key, item) => {
  setStorage(key, item)
}
export const setSessionStorage = (key, item) => {
  setStorage(key, item, true)
}

export const getStorage = (key, parse, isSessionStorage) => {
  try {
    const serializedItem = isSessionStorage ? sessionStorage.getItem(key) : localStorage.getItem(key)

    if (parse && serializedItem) {
      return JSON.parse(serializedItem)
    } else {
      return serializedItem && serializedItem !== 'undefined' ? serializedItem : undefined
    }
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const getLocalStorage = (key, parse) => {
  return getStorage(key, parse)
}

export const getSessionStorage = (key, parse) => {
  return getStorage(key, parse, true)
}

export const clearLocalStorage = () => {
  // don't clear cognito keys as these needed to the flexible auth flow
  const keysToKeep = new Set(['Cognito', NOT_PLATFORM_USER_TOKEN])

  for (let i = localStorage.length - 1; i >= 0; i--) {
    const key = localStorage.key(i)
    const allowDelete = key && ![...keysToKeep].some(keepKey => key.includes(keepKey))

    if (allowDelete) {
      localStorage.removeItem(key)
    }
  }
}
