import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { isValid } from 'date-fns'

import { useLoadPaginatedMediaOrders } from '../../../../MediaOrders/hooks'

import { selectedControllerIdSelector } from '../../../../../modules/selectors/app'

import { formatSetupFilters } from '../../../../LandingPage/ControllerLandingPageContent/ManagementReporting/Dashboard/helpers'

import { validMediaOrdersStatuses } from '../../../../../constants/mediaOrders'
import { formatDateToBE, initialDatePeriod } from '../../../../../constants/dates'

export const BookingsFiltersContext = React.createContext()

export function BookingsDataProvider({ children }) {
  const controllerId = useSelector(selectedControllerIdSelector)

  const [createdDateRangeFilter, setCreatedDateRangeFilter] = useState(initialDatePeriod)
  const [dateRangeFilter, setDateRangeFilter] = useState(initialDatePeriod)
  // account
  const [multipleAccountFilterOption, setMultipleAccountFilterOption] = useState([])
  const [multipleControllerMembersFilter, setMultipleControllerMembersFilter] = useState([])

  const filterRequestParams = useMemo(() => {
    const createdStartDate = createdDateRangeFilter.startDate
    const createdEndDate = createdDateRangeFilter.endDate
    const startDate = dateRangeFilter.startDate
    const endDate = dateRangeFilter.endDate

    return {
      controller: controllerId,
      status: validMediaOrdersStatuses,
      // account
      ...(multipleAccountFilterOption?.length && { account: multipleAccountFilterOption }),
      // created
      ...(createdStartDate &&
        isValid(createdStartDate) && {
          created_after: formatDateToBE(new Date(createdStartDate))
        }),
      // createdEndDate could be Invalid Date, for this reason we need to check if it's valid
      ...(createdEndDate &&
        isValid(createdEndDate) && {
          created_before: formatDateToBE(new Date(createdEndDate))
        }),
      // start_date
      ...(startDate &&
        isValid(startDate) && {
          start_date_after: formatDateToBE(new Date(startDate))
        }),
      // endDate could be Invalid Date, for this reason we need to check if it's valid
      ...(endDate &&
        isValid(endDate) && {
          start_date_before: formatDateToBE(new Date(endDate))
        }),
      // created_by
      ...(multipleControllerMembersFilter?.length && { created_by: multipleControllerMembersFilter })
    }
  }, [
    multipleAccountFilterOption,
    controllerId,
    createdDateRangeFilter,
    dateRangeFilter,
    multipleControllerMembersFilter
  ])

  const paginatedMediaOrdersParams = useMemo(() => formatSetupFilters(filterRequestParams), [filterRequestParams])

  const loadMoreMediaOrdersHandler = useLoadPaginatedMediaOrders(paginatedMediaOrdersParams)

  const bookingsFilterProps = useMemo(() => {
    return {
      filterRequestParams,
      loadMoreMediaOrdersHandler,
      multipleAccountFilterOption,
      setMultipleAccountFilterOption,
      createdDateRangeFilter,
      setCreatedDateRangeFilter,
      dateRangeFilter,
      setDateRangeFilter,
      multipleControllerMembersFilter,
      setMultipleControllerMembersFilter
    }
  }, [
    filterRequestParams,
    loadMoreMediaOrdersHandler,
    multipleAccountFilterOption,
    setMultipleAccountFilterOption,
    createdDateRangeFilter,
    setCreatedDateRangeFilter,
    dateRangeFilter,
    setDateRangeFilter,
    multipleControllerMembersFilter,
    setMultipleControllerMembersFilter
  ])

  return <BookingsFiltersContext.Provider value={bookingsFilterProps}>{children}</BookingsFiltersContext.Provider>
}
