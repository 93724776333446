import { concat } from '../../helpers/common'

const MODULE_NAME = 'MEDIA_ORDERS/'

// PLOP_APPEND_PATTERN_ANCHOR_ACTION

export const GENERATE_MEDIA_ORDER = concat(MODULE_NAME, 'GENERATE_MEDIA_ORDER')
export const GENERATE_MEDIA_ORDER_SUCCESS = concat(MODULE_NAME, 'GENERATE_MEDIA_ORDER_SUCCESS')
export const GENERATE_MEDIA_ORDER_FAILURE = concat(MODULE_NAME, 'GENERATE_MEDIA_ORDER_FAILURE')
export const CLEAR_GENERATE_MEDIA_ORDER = concat(MODULE_NAME, 'CLEAR_GENERATE_MEDIA_ORDER')

export const ADD_SELECTED_MEDIA_PACKAGE = concat(MODULE_NAME, 'ADD_SELECTED_MEDIA_PACKAGE')
export const REMOVE_SELECTED_MEDIA_PACKAGE = concat(MODULE_NAME, 'REMOVE_SELECTED_MEDIA_PACKAGE')
export const CLEAR_SELECTED_MEDIA_PACKAGES = concat(MODULE_NAME, 'CLEAR_SELECTED_MEDIA_PACKAGES')

export const GET_MEDIA_ORDERS = concat(MODULE_NAME, 'GET_MEDIA_ORDERS')
export const GET_MEDIA_ORDERS_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_SUCCESS')
export const GET_MEDIA_ORDERS_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_ORDERS_FAILURE')
export const CLEAR_MEDIA_ORDERS = concat(MODULE_NAME, 'CLEAR_MEDIA_ORDERS')

export const GET_MEDIA_ORDER = concat(MODULE_NAME, 'GET_MEDIA_ORDER')
export const GET_MEDIA_ORDER_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_ORDER_SUCCESS')
export const GET_MEDIA_ORDER_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_ORDER_FAILURE')
export const CLEAR_MEDIA_ORDER = concat(MODULE_NAME, 'CLEAR_MEDIA_ORDER')

export const GET_MEDIA_ORDER_FILES = concat(MODULE_NAME, 'GET_MEDIA_ORDER_FILES')
export const GET_MEDIA_ORDER_FILES_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_ORDER_FILES_SUCCESS')
export const GET_MEDIA_ORDER_FILES_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_ORDER_FILES_FAILURE')
export const CLEAR_GET_MEDIA_ORDER_FILES = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_ORDER_FILES')

export const SET_PRODUCT_PERIODS_UPDATE = concat(MODULE_NAME, 'SET_PRODUCT_PERIODS_UPDATE')

export const CREATE_MEDIA_ORDER = concat(MODULE_NAME, 'CREATE_MEDIA_ORDER')
export const CREATE_MEDIA_ORDER_SUCCESS = concat(MODULE_NAME, 'CREATE_MEDIA_ORDER_SUCCESS')
export const CREATE_MEDIA_ORDER_FAILURE = concat(MODULE_NAME, 'CREATE_MEDIA_ORDER_FAILURE')
export const CLEAR_CREATE_MEDIA_ORDER = concat(MODULE_NAME, 'CLEAR_CREATE_MEDIA_ORDER')

export const UPDATE_MEDIA_ORDER = concat(MODULE_NAME, 'UPDATE_MEDIA_ORDER')
export const UPDATE_MEDIA_ORDER_SUCCESS = concat(MODULE_NAME, 'UPDATE_MEDIA_ORDER_SUCCESS')
export const UPDATE_MEDIA_ORDER_FAILURE = concat(MODULE_NAME, 'UPDATE_MEDIA_ORDER_FAILURE')
export const CLEAR_UPDATE_MEDIA_ORDER = concat(MODULE_NAME, 'CLEAR_UPDATE_MEDIA_ORDER')
export const UPDATE_MEDIA_ORDER_DATA = concat(MODULE_NAME, 'UPDATE_MEDIA_ORDER_DATA')

export const CANCEL_MEDIA_ORDER = concat(MODULE_NAME, 'CANCEL_MEDIA_ORDER')
export const CANCEL_MEDIA_ORDER_SUCCESS = concat(MODULE_NAME, 'CANCEL_MEDIA_ORDER_SUCCESS')
export const CANCEL_MEDIA_ORDER_FAILURE = concat(MODULE_NAME, 'CANCEL_MEDIA_ORDER_FAILURE')
export const CLEAR_CANCEL_MEDIA_ORDER = concat(MODULE_NAME, 'CLEAR_CANCEL_MEDIA_ORDER')

export const ADD_MEDIA_ORDER_FILE = concat(MODULE_NAME, 'ADD_MEDIA_ORDER_FILE')
export const ADD_MEDIA_ORDER_FILE_SUCCESS = concat(MODULE_NAME, 'ADD_MEDIA_ORDER_FILE_SUCCESS')
export const ADD_MEDIA_ORDER_FILE_FAILURE = concat(MODULE_NAME, 'ADD_MEDIA_ORDER_FILE_FAILURE')
export const CLEAR_ADD_MEDIA_ORDER_FILE = concat(MODULE_NAME, 'CLEAR_ADD_MEDIA_ORDER_FILE')

export const DELETE_MEDIA_ORDER_FILE = concat(MODULE_NAME, 'DELETE_MEDIA_ORDER_FILE')
export const DELETE_MEDIA_ORDER_FILE_SUCCESS = concat(MODULE_NAME, 'DELETE_MEDIA_ORDER_FILE_SUCCESS')
export const DELETE_MEDIA_ORDER_FILE_FAILURE = concat(MODULE_NAME, 'DELETE_MEDIA_ORDER_FILE_FAILURE')
export const CLEAR_DELETE_MEDIA_ORDER_FILE = concat(MODULE_NAME, 'CLEAR_DELETE_MEDIA_ORDER_FILE')

export const SET_MEDIA_ORDER_UPLOAD_CREATIVE = concat(MODULE_NAME, 'SET_MEDIA_ORDER_UPLOAD_CREATIVE')
export const CLEAR_MEDIA_ORDER_UPLOAD_CREATIVE = concat(MODULE_NAME, 'CLEAR_MEDIA_ORDER_UPLOAD_CREATIVE')

export const GET_CATALOGUE_PRODUCTS = concat(MODULE_NAME, 'GET_CATALOGUE_PRODUCTS')
export const GET_CATALOGUE_PRODUCTS_SUCCESS = concat(MODULE_NAME, 'GET_CATALOGUE_PRODUCTS_SUCCESS')
export const GET_CATALOGUE_PRODUCTS_FAILURE = concat(MODULE_NAME, 'GET_CATALOGUE_PRODUCTS_FAILURE')
export const CLEAR_CATALOGUE_PRODUCTS = concat(MODULE_NAME, 'CLEAR_CATALOGUE_PRODUCTS')

export const GET_CATEGORY_PAGES = concat(MODULE_NAME, 'GET_CATEGORY_PAGES')
export const GET_CATEGORY_PAGES_SUCCESS = concat(MODULE_NAME, 'GET_CATEGORY_PAGES_SUCCESS')
export const GET_CATEGORY_PAGES_FAILURE = concat(MODULE_NAME, 'GET_CATEGORY_PAGES_FAILURE')
export const CLEAR_CATEGORY_PAGES = concat(MODULE_NAME, 'CLEAR_CATEGORY_PAGES')

export const CREATE_CATEGORY_PAGE = concat(MODULE_NAME, 'CREATE_CATEGORY_PAGE')
export const CREATE_CATEGORY_PAGE_SUCCESS = concat(MODULE_NAME, 'CREATE_CATEGORY_PAGE_SUCCESS')
export const CREATE_CATEGORY_PAGE_FAILURE = concat(MODULE_NAME, 'CREATE_CATEGORY_PAGE_FAILURE')
export const CLEAR_CREATE_CATEGORY_PAGE = concat(MODULE_NAME, 'CLEAR_CREATE_CATEGORY_PAGE')

export const UPDATE_CATEGORY_PAGE = concat(MODULE_NAME, 'UPDATE_CATEGORY_PAGE')
export const UPDATE_CATEGORY_PAGE_SUCCESS = concat(MODULE_NAME, 'UPDATE_CATEGORY_PAGE_SUCCESS')
export const UPDATE_CATEGORY_PAGE_FAILURE = concat(MODULE_NAME, 'UPDATE_CATEGORY_PAGE_FAILURE')
export const CLEAR_UPDATE_CATEGORY_PAGE = concat(MODULE_NAME, 'CLEAR_UPDATE_CATEGORY_PAGE')

export const SET_MEDIA_ORDER_CAMPAIGNS_DATE_RANGE = concat(MODULE_NAME, 'SET_MEDIA_ORDER_CAMPAIGNS_DATA_RANGE')

export const SET_MEDIA_ORDER_DATA = concat(MODULE_NAME, 'SET_MEDIA_ORDER_DATA')
export const CLEAR_MEDIA_ORDER_DATA = concat(MODULE_NAME, 'CLEAR_MEDIA_ORDER_DATA')

export const GET_CONTRACT_ENTITIES = concat(MODULE_NAME, 'GET_CONTRACT_ENTITIES')
export const GET_CONTRACT_ENTITIES_SUCCESS = concat(MODULE_NAME, 'GET_CONTRACT_ENTITIES_SUCCESS')
export const GET_CONTRACT_ENTITIES_FAILURE = concat(MODULE_NAME, 'GET_CONTRACT_ENTITIES_FAILURE')
export const CLEAR_GET_CONTRACT_ENTITIES = concat(MODULE_NAME, 'CLEAR_GET_CONTRACT_ENTITIES')

export const GET_MEDIA_ORDER_HISTORY = concat(MODULE_NAME, 'GET_MEDIA_ORDER_HISTORY')
export const GET_MEDIA_ORDER_HISTORY_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_ORDER_HISTORY_SUCCESS')
export const GET_MEDIA_ORDER_HISTORY_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_ORDER_HISTORY_FAILURE')
export const CLEAR_GET_MEDIA_ORDER_HISTORY = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_ORDER_HISTORY')

export const GET_MEDIA_ORDER_AS_CSV = concat(MODULE_NAME, 'GET_MEDIA_ORDER_AS_CSV')
export const GET_MEDIA_ORDER_AS_CSV_SUCCESS = concat(MODULE_NAME, 'GET_MEDIA_ORDER_AS_CSV_SUCCESS')
export const GET_MEDIA_ORDER_AS_CSV_FAILURE = concat(MODULE_NAME, 'GET_MEDIA_ORDER_AS_CSV_FAILURE')
export const CLEAR_GET_MEDIA_ORDER_AS_CSV = concat(MODULE_NAME, 'CLEAR_GET_MEDIA_ORDER_AS_CSV')

export const MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL = concat(MODULE_NAME, 'MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL')
export const MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL_SUCCESS = concat(
  MODULE_NAME,
  'MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL_SUCCESS'
)
export const MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL_FAILURE = concat(
  MODULE_NAME,
  'MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL_FAILURE'
)
export const CLEAR_MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL = concat(
  MODULE_NAME,
  'CLEAR_MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL'
)

// PLOP_APPEND_PATTERN_ANCHOR_ACTION_CREATOR

// Generate media order
export function generateMediaOrder(id, params, data) {
  return { type: GENERATE_MEDIA_ORDER, id, params, data }
}

export function generateMediaOrderSuccess(data) {
  return { type: GENERATE_MEDIA_ORDER_SUCCESS, data }
}

export function generateMediaOrderFailure(error) {
  return { type: GENERATE_MEDIA_ORDER_FAILURE, error }
}

export function clearGenerateMediaOrder() {
  return { type: CLEAR_GENERATE_MEDIA_ORDER }
}

export function setMediaOrderData(data) {
  return { type: SET_MEDIA_ORDER_DATA, data }
}

export function clearMediaOrderData() {
  return { type: CLEAR_MEDIA_ORDER_DATA }
}

// set selected media package
export function addSelectedMediaPackage(data) {
  return { type: ADD_SELECTED_MEDIA_PACKAGE, data }
}
export function removeSelectedMediaPackage(id) {
  return { type: REMOVE_SELECTED_MEDIA_PACKAGE, id }
}
export function clearSelectedMediaPackages() {
  return { type: CLEAR_SELECTED_MEDIA_PACKAGES }
}

// get media orders
export function getMediaOrders(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_MEDIA_ORDERS, params, loadOptions }
}

export function getMediaOrdersSuccess(mediaOrdersData) {
  return { type: GET_MEDIA_ORDERS_SUCCESS, mediaOrdersData }
}

export function getMediaOrdersFailure(error) {
  return { type: GET_MEDIA_ORDERS_FAILURE, error }
}

export function clearMediaOrders() {
  return { type: CLEAR_MEDIA_ORDERS }
}

// get media order
export function getMediaOrder(id) {
  return { type: GET_MEDIA_ORDER, id }
}

export function getMediaOrderSuccess(mediaOrderData) {
  return { type: GET_MEDIA_ORDER_SUCCESS, mediaOrderData }
}

export function getMediaOrderFailure(error) {
  return { type: GET_MEDIA_ORDER_FAILURE, error }
}

export function clearMediaOrder() {
  return { type: CLEAR_MEDIA_ORDER }
}

// Add media order file
export function addMediaOrderFile(data) {
  return { type: ADD_MEDIA_ORDER_FILE, data }
}

export function addMediaOrderFileSuccess(data) {
  return { type: ADD_MEDIA_ORDER_FILE_SUCCESS, data }
}

export function addMediaOrderFileFailure(error) {
  return { type: ADD_MEDIA_ORDER_FILE_FAILURE, error }
}

export function clearAddMediaOrderFile() {
  return { type: CLEAR_ADD_MEDIA_ORDER_FILE }
}

// Delete media order file
export function deleteMediaOrderFile(params) {
  return { type: DELETE_MEDIA_ORDER_FILE, params }
}

export function deleteMediaOrderFileSuccess(data) {
  return { type: DELETE_MEDIA_ORDER_FILE_SUCCESS, data }
}

export function deleteMediaOrderFileFailure(error) {
  return { type: DELETE_MEDIA_ORDER_FILE_FAILURE, error }
}

export function clearDeleteMediaOrderFile() {
  return { type: CLEAR_DELETE_MEDIA_ORDER_FILE }
}

// Get media order files
export function getMediaOrderFiles(params) {
  return { type: GET_MEDIA_ORDER_FILES, params }
}

export function getMediaOrderFilesSuccess(data) {
  return { type: GET_MEDIA_ORDER_FILES_SUCCESS, data }
}

export function getMediaOrderFilesFailure(error) {
  return { type: GET_MEDIA_ORDER_FILES_FAILURE, error }
}

export function clearGetMediaOrderFiles() {
  return { type: CLEAR_GET_MEDIA_ORDER_FILES }
}

// create media order helper
export function setProductPeriodsUpdate(isPeriodsUpdate) {
  return { type: SET_PRODUCT_PERIODS_UPDATE, isPeriodsUpdate }
}

// create media order
export function createMediaOrder(mediaOrderData) {
  return { type: CREATE_MEDIA_ORDER, mediaOrderData }
}

export function createMediaOrderSuccess(mediaOrderData) {
  return { type: CREATE_MEDIA_ORDER_SUCCESS, mediaOrderData }
}

export function createMediaOrderFailure(error) {
  return { type: CREATE_MEDIA_ORDER_FAILURE, error }
}

export function clearCreateMediaOrder() {
  return { type: CLEAR_CREATE_MEDIA_ORDER }
}

// Update media order
export function updateMediaOrder(id, data) {
  return { type: UPDATE_MEDIA_ORDER, id, data }
}

export function updateMediaOrderSuccess(data) {
  return { type: UPDATE_MEDIA_ORDER_SUCCESS, data }
}

export function updateMediaOrderFailure(error) {
  return { type: UPDATE_MEDIA_ORDER_FAILURE, error }
}

export function clearUpdateMediaOrder() {
  return { type: CLEAR_UPDATE_MEDIA_ORDER }
}

export function updateMediaOrderData(data) {
  // action for media order updates
  return { type: UPDATE_MEDIA_ORDER_DATA, data }
}

// Cancel media order
export function cancelMediaOrder(id, data) {
  return { type: CANCEL_MEDIA_ORDER, id, data }
}

export function cancelMediaOrderSuccess(data) {
  return { type: CANCEL_MEDIA_ORDER_SUCCESS, data }
}

export function cancelMediaOrderFailure(error) {
  return { type: CANCEL_MEDIA_ORDER_FAILURE, error }
}

export function clearCancelMediaOrder() {
  return { type: CLEAR_CANCEL_MEDIA_ORDER }
}

// media order uploads
export function setMediaOrderUploadCreative(data) {
  return { type: SET_MEDIA_ORDER_UPLOAD_CREATIVE, data }
}

export function clearMediaOrderUploadCreative() {
  return { type: CLEAR_MEDIA_ORDER_UPLOAD_CREATIVE }
}

// Get catalogue products
export function getCatalogueProducts(
  params,
  loadOptions = {
    shouldClearExistingProducts: false
  }
) {
  return { type: GET_CATALOGUE_PRODUCTS, params, loadOptions }
}

export function getCatalogueProductsSuccess(data) {
  return { type: GET_CATALOGUE_PRODUCTS_SUCCESS, data }
}

export function getCatalogueProductsFailure(error) {
  return { type: GET_CATALOGUE_PRODUCTS_FAILURE, error }
}

export function clearCatalogueProducts() {
  return { type: CLEAR_CATALOGUE_PRODUCTS }
}

// Get category pages
export function getCategoryPages(params) {
  return { type: GET_CATEGORY_PAGES, params }
}

export function getCategoryPagesSuccess(data) {
  return { type: GET_CATEGORY_PAGES_SUCCESS, data }
}

export function getCategoryPagesFailure(error) {
  return { type: GET_CATEGORY_PAGES_FAILURE, error }
}

export function clearCategoryPages() {
  return { type: CLEAR_CATEGORY_PAGES }
}

// set media order campaigns date range
export function setMediaOrderCampaignsDateRange(dateRange) {
  return { type: SET_MEDIA_ORDER_CAMPAIGNS_DATE_RANGE, dateRange }
}

// Create category page
export function createCategoryPage(data) {
  return { type: CREATE_CATEGORY_PAGE, data }
}

export function createCategoryPageSuccess(data) {
  return { type: CREATE_CATEGORY_PAGE_SUCCESS, data }
}

export function createCategoryPageFailure(error) {
  return { type: CREATE_CATEGORY_PAGE_FAILURE, error }
}

export function clearCreateCategoryPage() {
  return { type: CLEAR_CREATE_CATEGORY_PAGE }
}

// Update category page
export function updateCategoryPage(id, data) {
  return { type: UPDATE_CATEGORY_PAGE, id, data }
}

export function updateCategoryPageSuccess(data) {
  return { type: UPDATE_CATEGORY_PAGE_SUCCESS, data }
}

export function updateCategoryPageFailure(error) {
  return { type: UPDATE_CATEGORY_PAGE_FAILURE, error }
}

export function clearUpdateCategoryPage() {
  return { type: CLEAR_UPDATE_CATEGORY_PAGE }
}

// Get contract entities
export function getContractEntities(params) {
  return { type: GET_CONTRACT_ENTITIES, params }
}

export function getContractEntitiesSuccess(data) {
  return { type: GET_CONTRACT_ENTITIES_SUCCESS, data }
}

export function getContractEntitiesFailure(error) {
  return { type: GET_CONTRACT_ENTITIES_FAILURE, error }
}

export function clearGetContractEntities() {
  return { type: CLEAR_GET_CONTRACT_ENTITIES }
}

// Get media order history
export function getMediaOrderHistory(
  params,
  loadOptions = {
    shouldClearExistingState: false
  }
) {
  return { type: GET_MEDIA_ORDER_HISTORY, params, loadOptions }
}

export function getMediaOrderHistorySuccess(data) {
  return { type: GET_MEDIA_ORDER_HISTORY_SUCCESS, data }
}

export function getMediaOrderHistoryFailure(error) {
  return { type: GET_MEDIA_ORDER_HISTORY_FAILURE, error }
}

export function clearGetMediaOrderHistory() {
  return { type: CLEAR_GET_MEDIA_ORDER_HISTORY }
}

// Get media order as csv
export function getMediaOrderAsCsv(params) {
  return { type: GET_MEDIA_ORDER_AS_CSV, params }
}

export function getMediaOrderAsCsvSuccess(data) {
  return { type: GET_MEDIA_ORDER_AS_CSV_SUCCESS, data }
}

export function getMediaOrderAsCsvFailure(error) {
  return { type: GET_MEDIA_ORDER_AS_CSV_FAILURE, error }
}

export function clearGetMediaOrderAsCsv() {
  return { type: CLEAR_GET_MEDIA_ORDER_AS_CSV }
}

// Media order resend confirmation email
export function mediaOrderResendConfirmationEmail(id, data, recipientRowId) {
  return { type: MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL, id, data, recipientRowId }
}

export function mediaOrderResendConfirmationEmailSuccess(data) {
  return { type: MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL_SUCCESS, data }
}

export function mediaOrderResendConfirmationEmailFailure(error) {
  return { type: MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL_FAILURE, error }
}

export function clearMediaOrderResendConfirmationEmail() {
  return { type: CLEAR_MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL }
}
