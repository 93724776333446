import {
  FACEBOOK_PLATFORM,
  FACEBOOK_PROVIDER_PLATFORM,
  GOOGLE_PLATFORM,
  GOOGLE_PROVIDER_PLATFORM,
  DV_360_PLATFORM,
  DV_360_PROVIDER_PLATFORM,
  TIKTOK_PLATFORM,
  TIKTOK_PROVIDER_PLATFORM
} from './selectLists/platformList'

export const INTERCOM_APP_ID = 'lo59znbl'

export const NOT_PLATFORM_USER_TOKEN = 'notPlatformUserToken'

// some of endpoint has different platform parameter to ROUTES like facebook and google
export const ENDPOINT_PLATFORM = {
  [FACEBOOK_PLATFORM]: FACEBOOK_PROVIDER_PLATFORM,
  [GOOGLE_PLATFORM]: GOOGLE_PROVIDER_PLATFORM,
  [DV_360_PLATFORM]: DV_360_PROVIDER_PLATFORM,
  [TIKTOK_PLATFORM]: TIKTOK_PROVIDER_PLATFORM
}

export const WEB_SOCKET_ENDPOINTS = {
  streamAiSuggestions: '/api/ws/stream_suggestions/', // this is a websocket endpoint and it is not in Swagger library
  bookedMediaCSV: '/api/ws/booked_media/csv_export/', // this is a websocket endpoint and it is not in Swagger library
  mediaProductExportCSV: '/api/ws/media_products/csv_export/', // this is a websocket endpoint and it is not in Swagger
  mediaOrderExportCSV: '/api/ws/media_order/csv_export/' // this is a websocket endpoint and it is not in Swagger
  // library
}

export const ENDPOINTS = {
  currentUserProfile: '/api/users/me/', // users_me_read
  users: '/api/users/', // users_list
  userSpecific: '/api/users/:id/', // users_read
  userInvites: '/api/users/:id/invites/', // invites_list
  setMFA: '/api/mfa_management/set_mfa/',
  controllerLandingPageSetup: '/api/controller_landing_pages/',

  // auth
  login: '/api/auth/login/', //auth_login_create
  logout: '/api/auth/logout/', //auth_logout_create
  oauth: '/api/auth/oauth/', //auth_oauth_list
  inviteAccept: '/api/invites/accept/', //invites_accept
  register: '/api/auth/register/', //auth_register_create
  resetPassword: '/api/auth/reset_password/', //auth_reset_password_create
  changePassword: '/api/auth/change-password/', //auth_change-password_create
  sendResetPasswordLink: '/api/auth/send_reset_password_link/', //auth_send_reset_password_link_create
  verify_registration: '/api/auth/verify_registration/', //auth_verify_registration_create'
  resend_register: '/api/resend-register/', // resend-register_create
  token: '/api/token/',

  // social auth
  facebookAuth: '/api/allauth/facebook/login/callback/',
  googleAuth: '/api/allauth/google/login/callback/',
  dv360Auth: '/api/allauth/dv360/login/callback/',
  tikTokAuth: '/api/allauth/tiktok/login/callback/',

  // agencies,
  agencies: '/api/agencies/',
  agencyMembers: '/api/agency_members/',
  agencyInvites: '/api/agency_invites/',

  // controllers
  controllers: '/api/controllers/',
  controllerMembers: '/api/controller_members/',
  controllerInvites: '/api/controller_invites/',

  // ads / visuals
  ads: '/api/:platform/visuals/', // ads list
  ad: '/api/:platform/visuals/:id/', // single ad
  copyAd: '/api/:platform/visuals/:id/copy_fb/', // facebook_visuals_copy_fb
  internalAds: '/api/internal_visuals/',
  internalAdsCount: '/api/internal_visuals/items_count/',

  // campaigns
  campaigns: '/api/:platform/campaigns/', //campaigns_list
  campaignGraphs: '/api/campaigns/graphs/', //campaigns_graphs
  campaignReports: '/api/reports/campaigns/', //reports_campaigns_list
  shortInfoCampaigns: '/api/:platform/campaigns/short_info/',
  duplicateCampaign: '/api/:platform/campaigns/:id/copy/',

  // line items
  lineItems: '/api/:platform/line_items/', //line_items_list
  lineItemsReportsBreakdown: '/api/reports/line_item_breakdown/',
  duplicateLineItem: '/api/:platform/line_items/:id/copy/',

  // accounts
  accounts: '/api/accounts/', //accounts_list
  adAccounts: '/api/:platform/adaccounts/', //adaccounts_list

  // audiences
  audiences: '/api/:platform/audiences/', //audiences_list

  // combined data
  combinedCampaigns: '/api/combined/campaigns/data/',
  combinedLineItems: '/api/combined/campaigns/line_items/',
  combinedGraphs: '/api/combined/campaigns/graphs/',
  combinedSegmentedData: '/api/combined/campaigns/segmented_data/',
  combinedAds: '/api/combined/campaigns/visuals/',

  // facebook choices
  choicesGeolocations: '/api/facebook/choices/geolocations/',
  choicesCountries: '/api/countries/',
  choicesInterests: '/api/facebook/choices/interests/',
  choicesCategories: '/api/facebook/choices/family_statuses/',
  choicesLanguages: '/api/facebook/choices/languages/',
  choicesPixels: '/api/facebook/choices/pixels/',
  choicesCTA: '/api/facebook/call_to_actions/',

  // social accounts
  accessibleFacebookPages: '/api/pages/',
  facebookPages: '/api/facebook/pages/',
  facebookPagesRequestAccess: '/api/facebook/pages/request_page_access/',

  // locations
  locations: '/api/locations/',
  locationLists: '/api/location_lists/',
  locationAddresses: '/api/location_addresses/',

  // team
  membersList: '/api/members/',
  invitesList: '/api/invites/',

  // goals
  goals: '/api/facebook/goals/',

  // pixels
  pixels: '/api/facebook/pixels/',
  // files
  signUrl: '/api/sign-url/', // sign-url_create
  privateUploadUrl: '/api/private_upload_url/', // sign-url_create
  accessibleUrl: '/api/generate_sign_url/', // get accessible url
  facebookMediaImage: '/api/facebook/media_files/fb_image_upload/', // media_files_fb_image_upload
  facebookMediaVideo: '/api/facebook/media_files/fb_video_upload/', // media_files_fb_video_upload
  facebookMediaVideoDetail: '/api/facebook/media_files/:id/fb_video_detail/', // media_files_fb_video_detail
  facebookMediaImages: '/api/facebook/media_files/fb_images/',
  facebookMediaVideos: '/api/facebook/media_files/fb_videos/',
  facebookVideoUrls: '/api/facebook/media_files/fb_videos_info/',
  facebookImageUrls: '/api/facebook/media_files/fb_images_info/',
  tikTokImages: '/api/tiktok/images/',
  tikTokVideos: '/api/tiktok/videos/',

  // calendarPlanner
  calendarsList: '/api/calendars/',
  calendarEvents: '/api/events/', // events_list
  calendarEventGroups: '/api/event-groups/',
  updateEventGroupsOrder: '/api/event-groups/order_update/',

  // tools
  changeHistory: '/api/utility_tools/tracking_history/', // utility_tools_tracking_history_list
  // AI suggestions for ad's content
  aiSuggestions: '/api/tools/suggestions/', // tools_suggestions_list

  // batch_info
  batchInfo: '/api/batch_info/',

  // google
  googleConstants: '/api/google/google_constants/', // google_constants_list
  googleUsersList: '/api/google/user_lists/', // google_user_lists_list
  campaignCriterions: '/api/campaign_criterions/', // campaign_criterions_list
  campaignAdScheduleCriterions: '/api/campaign_criterions/read_ad_schedule/', // campaign_criterions_read_ad_schedule_read
  campaignCriterionsBatch: '/api/campaign_criterions/batch/', // campaign_criterions_batch
  campaignCriterionsCreateProximity: '/api/campaign_criterions/create_proximity/', // campaign_criterions_create_proximity
  campaignCriterionsCreateSchedule: '/api/campaign_criterions/create_ad_schedule/', // campaign_criterions_create_schedule
  lineItemCriterions: '/api/google/ad_group_criterions/', // google_ad_group_criterions_list
  lineItemCriterionsBatch: '/api/google/ad_group_criterions/batch/', // google_ad_group_criterions_batch

  // tiktok
  tikTokInterests: '/api/tiktok/interest_categories/',
  tikTokActionCategories: '/api/tiktok/action_categories/',
  tikTokHashtags: '/api/tiktok/recommended_hashtags/',
  tikTokCustomAudiences: '/api/tiktok/custom_audiences/',
  tikTokIdentities: '/api/tiktok/identities/',
  tikTokPixels: '/api/tiktok/pixel_events/',
  tikTokRegions: '/api/tiktok/regions/',
  tikTokVideoThumbnails: '/api/tiktok/video_thumbnails/',

  // banners
  banners: '/api/banners/',

  // triggers,
  triggers: '/api/triggers/',

  // automation rules
  automationRules: '/api/rules/',

  // assets
  assets: '/api/:platform/assets/', // google_assets_list
  assetsBatch: '/api/:platform/assets/batch/', // google_assets_batch
  assetGroups: '/api/:platform/asset_groups/', // asset_groups_list
  assetGroup: '/api/:platform/asset_groups/:id/', // asset_groups
  assetGroupSignals: '/api/:platform/asset_groups_signals/',
  internalAssetGroups: '/api/google/internal_asset_groups/',

  // async session
  asyncSession: '/api/:platform/async_sessions/:id/',

  // margins
  margins: '/api/margin_settings/',
  marginOverride: '/api/margin_setting_overrides/',
  groupedMargins: '/api/margin_settings/grouped/',

  // media orders
  mediaPackageCategories: '/api/media_package_category/',
  mediaPackageCategoriesOrderUpdate: '/api/media_package_category/order_update/',
  mediaPackages: '/api/media_packages/',
  mediaPackagesOrderUpdate: '/api/media_packages/order_update/',
  groupedMediaPackages: '/api/media_packages/grouped/',
  mediaOrders: '/api/media_orders/',
  mediaOrderFiles: '/api/media_order_files/',
  mediaOrderSuggestedChanges: '/api/media_orders/:id/suggested_changes/',
  mediaOrderApplySuggestedChanges: '/api/media_orders/:id/apply_suggestion/',
  contractLogs: '/api/contract_logs/',
  optimizationHistory: '/api/optimization_history/',
  products: '/api/media_products/',
  mediaProductAttachedFiles: '/api/media_product_attached_files/',
  utilisationReport: '/api/utilization_report/run/',
  discountPresets: '/api/media_product_discount_presets/',
  priceChangePeriods: '/api/price_change_periods/',
  mediaProductLocations: '/api/media_product_locations/',
  mediaProductStores: '/api/media_product_stores/',
  mediaProductVariables: '/api/media_product_variables/',
  mediaProductVariableOptions: '/api/media_product_variable_options/',
  mediaProductVariableOptionsBulkList: '/api/media_product_variable_options/bulk-list/',
  mediaProductAttachedLocations: '/api/media_products/attached_locations/',
  bookedMediaAttachedLocations: '/api/booked_media/attached_locations/',
  productsOrderUpdate: '/api/media_products/order_update/',
  productsTags: '/api/media_products/tags/',
  mediaPackagesExportToCsv: '/api/media_packages/export_to_csv/',
  inventory: '/api/inventory/',
  inventoryLastItem: '/api/inventory/last_item/',
  inventoryBatchUpdate: '/api/inventory/batch_update/',
  catalogueProducts: '/api/products/',
  inventoryReport: '/api/report_inventory/',
  categoryPages: '/api/category_pages/',
  bookedMedia: '/api/booked_media/',
  bookingMediaUploads: '/api/uploaded_files/', // uploaded_files_list
  bookingMediaBulkUpdate: '/api/booked_media/bulk-update/',
  bookingMediaBulkUploads: '/api/uploaded_files/bulk-update/', // uploaded_files/bulk-update/
  bookingRecentlyMediaUploads: '/api/recently_uploaded_files/', // recently_uploaded_files_list
  bookedMediaReport: '/api/report_booked_media/', // report_booked_media_list
  bookedRevenueReport: '/api/booked_revenue_breakdown/run/', // booked_revenue_breakdown_run
  periodBookedMediaReport: '/api/period_booked_media_report/', // period_booked_media_report_list
  brandRevenueReport: '/api/brand_revenue_report/', // brand_revenue_report_list
  installationBookedMediaReport: '/api/installation_report_booked_media/', // installation_report_booked_media_list
  installationBookedMediaReportExportCsv: '/api/installation_report_booked_media/export-csv/', // installation_report_booked_media_list export csv
  mediaOrderResendConfirmationEmail: '/api/media_orders/:id/resend_confirmation_email/',

  // contracts
  contracts: '/api/contracts/',
  signContract: '/api/contracts/:id/sign/',
  contractClearSign: '/api/contracts/:id/clear_sign/',
  generateMediaOrder: '/api/contracts/:id/generate_media_order/',
  contractsStatusBulk: '/api/contracts/bulk_update_status/',

  // quotations
  sendQuotation: '/api/contracts/:id/send_quotation/',
  resendQuotation: '/api/contracts/:id/resend_quotation/',

  // amendments
  amendments: '/api/amendments/',
  signAmendmentController: '/api/amendments/:id/controller_sign/',
  clearSignAmendmentController: '/api/amendments/:id/clear_controller_sign/',
  signAmendmentUser: '/api/amendments/:id/sign/',
  clearSignAmendmentUser: '/api/amendments/:id/clear_user_sign/',
  amendmentSend: '/api/amendments/:id/send_amendment/',
  amendmentSubmit: '/api/amendments/:id/submit_amendment/',

  // targeting options
  targetingBulkCreate: '/api/dv360/targeting/bulk_create/',

  // tags
  tags: '/api/tags/',

  // media product tags
  mediaProductTags: '/api/media_product_tags/',

  // statements
  statements: '/api/statements/',

  // doohly
  doohlyApiKey: '/api/access_settings/',
  doohlyCampaigns: '/api/doohly/campaigns/',
  doohlyLineItems: '/api/doohly/line_items/',

  // contract entities
  contractingEntities: '/api/contracting_entities/',

  // categories
  categories: '/api/categories/',
  subCategories: '/api/sub_categories/',

  // mediaSubCategories
  mediaSubCategories: '/api/media_sub_categories/'
}
