import { all, call, put, takeEvery, select } from 'redux-saga/effects'

import {
  GENERATE_MEDIA_ORDER,
  generateMediaOrderSuccess,
  generateMediaOrderFailure,
  CREATE_CATEGORY_PAGE,
  CREATE_MEDIA_ORDER,
  createCategoryPageFailure,
  createCategoryPageSuccess,
  createMediaOrderFailure,
  createMediaOrderSuccess,
  GET_CATALOGUE_PRODUCTS,
  GET_CATEGORY_PAGES,
  GET_MEDIA_ORDER,
  GET_MEDIA_ORDERS,
  getCatalogueProductsFailure,
  getCatalogueProductsSuccess,
  getCategoryPagesFailure,
  getCategoryPagesSuccess,
  getMediaOrderFailure,
  getMediaOrdersFailure,
  getMediaOrdersSuccess,
  getMediaOrderSuccess,
  UPDATE_CATEGORY_PAGE,
  UPDATE_MEDIA_ORDER,
  updateCategoryPageFailure,
  updateCategoryPageSuccess,
  updateMediaOrderFailure,
  updateMediaOrderSuccess,
  GET_MEDIA_ORDER_FILES,
  getMediaOrderFilesSuccess,
  getMediaOrderFilesFailure,
  ADD_MEDIA_ORDER_FILE,
  addMediaOrderFileSuccess,
  addMediaOrderFileFailure,
  DELETE_MEDIA_ORDER_FILE,
  deleteMediaOrderFileSuccess,
  deleteMediaOrderFileFailure,
  GET_CONTRACT_ENTITIES,
  getContractEntitiesSuccess,
  getContractEntitiesFailure,
  CANCEL_MEDIA_ORDER,
  cancelMediaOrderSuccess,
  cancelMediaOrderFailure,
  GET_MEDIA_ORDER_HISTORY,
  getMediaOrderHistorySuccess,
  getMediaOrderHistoryFailure,
  GET_MEDIA_ORDER_AS_CSV,
  getMediaOrderAsCsvSuccess,
  getMediaOrderAsCsvFailure,
  CLEAR_GET_MEDIA_ORDER_AS_CSV,
  mediaOrderResendConfirmationEmailSuccess,
  mediaOrderResendConfirmationEmailFailure,
  MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL
} from '../actions/mediaOrders'

import {
  addMediaOrderFileService,
  cancelMediaOrderService,
  createCategoryPageService,
  createMediaOrderService,
  deleteMediaOrderFileService,
  generateMediaOrderService,
  getCatalogueProductsService,
  getCategoryPagesService,
  getContractEntitiesService,
  getMediaOrderAsCsvService,
  getMediaOrderFilesService,
  getMediaOrderHistoryService,
  getMediaOrderService,
  getMediaOrdersService,
  mediaOrderResendConfirmationEmailService,
  updateCategoryPageService,
  updateMediaOrderService
} from '../services/mediaOrders'
import {
  CREATE_MEDIA_PACKAGE,
  CREATE_MEDIA_PACKAGE_CATEGORY,
  createMediaPackageCategoryFailure,
  createMediaPackageCategorySuccess,
  createMediaPackageFailure,
  createMediaPackageSuccess,
  DELETE_MEDIA_PACKAGE,
  deleteMediaPackageFailure,
  deleteMediaPackageSuccess,
  GET_GROUPED_MEDIA_PACKAGES,
  GET_MEDIA_PACKAGE_CATEGORIES,
  GET_MEDIA_PACKAGES,
  getGroupedMediaPackagesFailure,
  getGroupedMediaPackagesSuccess,
  getMediaPackageCategoriesFailure,
  getMediaPackageCategoriesSuccess,
  getMediaPackagesFailure,
  getMediaPackagesSuccess,
  UPDATE_MEDIA_PACKAGE,
  UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER,
  UPDATE_MEDIA_PACKAGE_CATEGORY,
  UPDATE_MEDIA_PACKAGES_ORDER,
  updateMediaPackageCategoriesOrderFailure,
  updateMediaPackageCategoriesOrderSuccess,
  updateMediaPackageCategoryFailure,
  updateMediaPackageCategorySuccess,
  updateMediaPackageFailure,
  updateMediaPackagesBulkSuccess,
  updateMediaPackagesOrderFailure,
  updateMediaPackagesOrderSuccess,
  updateMediaPackageSuccess
} from '../actions/mediaPackages'
import {
  createMediaPackageCategoryService,
  createMediaPackageService,
  deleteMediaPackageService,
  getGroupedMediaPackagesService,
  getMediaPackageCategoriesService,
  getMediaPackagesService,
  updateMediaPackageCategoriesOrderService,
  updateMediaPackageCategoryService,
  updateMediaPackageService,
  updateMediaPackagesOrderService
} from '../services/mediaPackages'
import { mediaPackagesSelector } from '../selectors/mediaPackages'
import { handleWebSocketRequest } from '../../helpers/modules/saga'

function* mediaOrdersWatcher() {
  yield all([
    // PLOP_APPEND_PATTERN_ANCHOR_WATCHER
    takeEvery(GET_MEDIA_ORDER_HISTORY, getMediaOrderHistoryWorker),
    takeEvery(GENERATE_MEDIA_ORDER, generateMediaOrderWorker),
    takeEvery(GET_MEDIA_PACKAGE_CATEGORIES, getMediaPackageCategoriesWorker),
    takeEvery(CREATE_MEDIA_PACKAGE_CATEGORY, createMediaPackageCategoryWorker),
    takeEvery(UPDATE_MEDIA_PACKAGE_CATEGORY, updateMediaPackageCategoryWorker),
    takeEvery(UPDATE_MEDIA_PACKAGE_CATEGORIES_ORDER, updateMediaPackageCategoriesOrderWorker),
    takeEvery(GET_GROUPED_MEDIA_PACKAGES, getGroupedMediaPackagesWorker),
    takeEvery(GET_MEDIA_PACKAGES, getMediaPackagesWorker),
    takeEvery(CREATE_MEDIA_PACKAGE, createMediaPackageWorker),
    takeEvery(UPDATE_MEDIA_PACKAGE, updateMediaPackageWorker),
    takeEvery(DELETE_MEDIA_PACKAGE, deleteMediaPackageWorker),
    takeEvery(UPDATE_MEDIA_PACKAGES_ORDER, updateMediaPackagesOrderWorker),
    takeEvery(GET_MEDIA_ORDERS, getMediaOrdersWorker),
    takeEvery(GET_MEDIA_ORDER, getMediaOrderWorker),
    takeEvery(ADD_MEDIA_ORDER_FILE, addMediaOrderFileWorker),
    takeEvery(DELETE_MEDIA_ORDER_FILE, deleteMediaOrderFileWorker),
    takeEvery(GET_MEDIA_ORDER_FILES, getMediaOrderFilesWorker),
    takeEvery(CREATE_MEDIA_ORDER, createMediaOrderWorker),
    takeEvery(UPDATE_MEDIA_ORDER, updateMediaOrderWorker),
    takeEvery(CANCEL_MEDIA_ORDER, cancelMediaOrderWorker),
    takeEvery(GET_CATALOGUE_PRODUCTS, getCatalogueProductsWorker),
    takeEvery(GET_CATEGORY_PAGES, getCategoryPagesWorker),
    takeEvery(CREATE_CATEGORY_PAGE, createCategoryPageWorker),
    takeEvery(UPDATE_CATEGORY_PAGE, updateCategoryPageWorker),
    takeEvery(GET_CONTRACT_ENTITIES, getContractEntitiesWorker),
    takeEvery(GET_MEDIA_ORDER_AS_CSV, getMediaOrderAsCsvWorker),
    takeEvery(MEDIA_ORDER_RESEND_CONFIRMATION_EMAIL, mediaOrderResendConfirmationEmailWorker)
  ])
}

// PLOP_APPEND_PATTERN_ANCHOR_WORKER

function* generateMediaOrderWorker({ id, params, data }) {
  try {
    const response = yield call(generateMediaOrderService, id, params, data)
    yield put(generateMediaOrderSuccess(response))
  } catch (error) {
    yield put(generateMediaOrderFailure(error))
  }
}

function* getMediaPackageCategoriesWorker({ params }) {
  try {
    const response = yield call(getMediaPackageCategoriesService, params)
    yield put(getMediaPackageCategoriesSuccess(response))
  } catch (error) {
    yield put(getMediaPackageCategoriesFailure(error))
  }
}

function* createMediaPackageCategoryWorker({ data }) {
  try {
    const response = yield call(createMediaPackageCategoryService, data)
    yield put(createMediaPackageCategorySuccess(response))
  } catch (error) {
    yield put(createMediaPackageCategoryFailure(error))
  }
}

function* updateMediaPackageCategoryWorker({ id, data }) {
  try {
    const response = yield call(updateMediaPackageCategoryService, id, data)
    yield put(updateMediaPackageCategorySuccess(response))
  } catch (error) {
    yield put(updateMediaPackageCategoryFailure(error))
  }
}

function* updateMediaPackageCategoriesOrderWorker({ data }) {
  try {
    const response = yield call(updateMediaPackageCategoriesOrderService, data)
    yield put(updateMediaPackageCategoriesOrderSuccess(response))
  } catch (error) {
    yield put(updateMediaPackageCategoriesOrderFailure(error))
  }
}

function* getGroupedMediaPackagesWorker({ params }) {
  try {
    const response = yield call(getGroupedMediaPackagesService, params)
    yield put(getGroupedMediaPackagesSuccess(response))
  } catch (error) {
    yield put(getGroupedMediaPackagesFailure(error))
  }
}

function* getMediaPackagesWorker({ params }) {
  try {
    const response = yield call(getMediaPackagesService, params)
    yield put(getMediaPackagesSuccess(response))
  } catch (error) {
    yield put(getMediaPackagesFailure(error))
  }
}

function* createMediaPackageWorker({ data }) {
  try {
    const response = yield call(createMediaPackageService, data)
    yield put(createMediaPackageSuccess(response))
  } catch (error) {
    yield put(createMediaPackageFailure(error))
  }
}

function* updateMediaPackageWorker({ id, data }) {
  try {
    const response = yield call(updateMediaPackageService, id, data)

    const { featured: isFeaturedUpdate } = data

    // user can have only one featured package, so when we assign featured to another package
    // we have to manually find old featured one and toggle this property (the same is done on BE side)
    if (isFeaturedUpdate) {
      const packages = yield select(mediaPackagesSelector)

      const updatedPackages = packages.map(item => {
        if (item.featured) {
          // toggle featured off on previous package
          return { ...item, featured: false }
        } else if (item.id === id) {
          // enable featured on new package (updated one)
          return { ...item, ...response }
        } else {
          return item
        }
      })

      // use the same update action to update existing media package
      yield put(updateMediaPackagesBulkSuccess(updatedPackages))
    } else {
      yield put(updateMediaPackageSuccess(response))
    }
  } catch (error) {
    yield put(updateMediaPackageFailure(error))
  }
}

function* deleteMediaPackageWorker({ id }) {
  try {
    yield call(deleteMediaPackageService, id)
    yield put(deleteMediaPackageSuccess(id))
  } catch (error) {
    yield put(deleteMediaPackageFailure(error))
  }
}

function* updateMediaPackagesOrderWorker({ data }) {
  try {
    const response = yield call(updateMediaPackagesOrderService, data)
    yield put(updateMediaPackagesOrderSuccess(response))
  } catch (error) {
    yield put(updateMediaPackagesOrderFailure(error))
  }
}

function* getMediaOrdersWorker({ params }) {
  try {
    const response = yield call(getMediaOrdersService, params)

    yield put(getMediaOrdersSuccess(response))
  } catch (e) {
    yield put(getMediaOrdersFailure(e))
  }
}

function* getMediaOrderWorker({ id }) {
  try {
    const response = yield call(getMediaOrderService, id)

    yield put(getMediaOrderSuccess(response))
  } catch (e) {
    yield put(getMediaOrderFailure(e))
  }
}

function* addMediaOrderFileWorker({ data }) {
  try {
    const response = yield call(addMediaOrderFileService, data)
    yield put(addMediaOrderFileSuccess(response))
  } catch (error) {
    yield put(addMediaOrderFileFailure(error))
  }
}

function* deleteMediaOrderFileWorker({ params }) {
  try {
    yield call(deleteMediaOrderFileService, params)
    yield put(deleteMediaOrderFileSuccess(params))
  } catch (error) {
    yield put(deleteMediaOrderFileFailure(error))
  }
}

function* getMediaOrderFilesWorker({ params }) {
  try {
    const response = yield call(getMediaOrderFilesService, params)
    yield put(getMediaOrderFilesSuccess(response))
  } catch (error) {
    yield put(getMediaOrderFilesFailure(error))
  }
}

function* createMediaOrderWorker({ mediaOrderData }) {
  try {
    const response = yield call(createMediaOrderService, mediaOrderData)

    yield put(createMediaOrderSuccess(response))
  } catch (e) {
    yield put(createMediaOrderFailure(e))
  }
}

function* updateMediaOrderWorker({ id, data }) {
  try {
    const response = yield call(updateMediaOrderService, id, data)
    yield put(updateMediaOrderSuccess(response))
  } catch (error) {
    yield put(updateMediaOrderFailure(error))
  }
}

function* cancelMediaOrderWorker({ id, data }) {
  try {
    const response = yield call(cancelMediaOrderService, id, data)
    yield put(cancelMediaOrderSuccess(response))
  } catch (error) {
    yield put(cancelMediaOrderFailure(error))
  }
}

function* getCatalogueProductsWorker({ params }) {
  try {
    const response = yield call(getCatalogueProductsService, params)
    yield put(getCatalogueProductsSuccess(response))
  } catch (error) {
    yield put(getCatalogueProductsFailure(error))
  }
}

function* getCategoryPagesWorker({ params }) {
  try {
    const response = yield call(getCategoryPagesService, params)
    yield put(getCategoryPagesSuccess(response))
  } catch (error) {
    yield put(getCategoryPagesFailure(error))
  }
}

function* createCategoryPageWorker({ data }) {
  try {
    const response = yield call(createCategoryPageService, data)
    yield put(createCategoryPageSuccess(response))
  } catch (error) {
    yield put(createCategoryPageFailure(error))
  }
}

function* updateCategoryPageWorker({ id, data }) {
  try {
    const response = yield call(updateCategoryPageService, id, data)
    yield put(updateCategoryPageSuccess(response))
  } catch (error) {
    yield put(updateCategoryPageFailure(error))
  }
}

function* getContractEntitiesWorker({ params }) {
  try {
    const response = yield call(getContractEntitiesService, params)
    yield put(getContractEntitiesSuccess(response))
  } catch (error) {
    yield put(getContractEntitiesFailure(error))
  }
}

function* getMediaOrderHistoryWorker({ params }) {
  try {
    const response = yield call(getMediaOrderHistoryService, params)
    yield put(getMediaOrderHistorySuccess(response))
  } catch (error) {
    yield put(getMediaOrderHistoryFailure(error))
  }
}

function* getMediaOrderAsCsvWorker({ params }) {
  try {
    yield call(handleWebSocketRequest, {
      service: getMediaOrderAsCsvService,
      serviceProps: {
        params
      },
      cancelActionType: CLEAR_GET_MEDIA_ORDER_AS_CSV,
      successAction: getMediaOrderAsCsvSuccess,
      failureAction: getMediaOrderAsCsvFailure
    })
  } catch (error) {
    yield put(getMediaOrderAsCsvFailure(error))
  }
}

function* mediaOrderResendConfirmationEmailWorker({ id, data }) {
  try {
    const response = yield call(mediaOrderResendConfirmationEmailService, id, data)
    yield put(mediaOrderResendConfirmationEmailSuccess(response))
  } catch (error) {
    yield put(mediaOrderResendConfirmationEmailFailure(error))
  }
}

export default mediaOrdersWatcher
