import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import AdaptiveActions from '../../../../../../features/components/AdaptiveActions'

import { openForm } from '../../../../../../modules/actions/forms'
import { deleteMediaProductAttachedFile } from '../../../../../../modules/actions/mediaOrdersProducts'

import { SPECIFICATIONS_DOCUMENT_EDIT } from '../../../../../../constants/forms'
import PropTypes from 'prop-types'

const SpecificationsDocumentsActions = ({ data }) => {
  const { t } = useTranslation()

  const dispatch = useDispatch()

  const { id } = data

  const openEditFormHandler = useCallback(() => {
    dispatch(openForm({ id, formName: SPECIFICATIONS_DOCUMENT_EDIT }))
  }, [dispatch, id])

  const deleteHandler = useCallback(() => {
    dispatch(deleteMediaProductAttachedFile(id))
  }, [dispatch, id])

  const dropdownOptions = useMemo(
    () => [
      {
        text: t('Edit'),
        onClickHandler: openEditFormHandler
      },
      {
        text: t('Delete'),
        onClickHandler: deleteHandler
      }
    ],
    [t, openEditFormHandler, deleteHandler]
  )

  return <AdaptiveActions options={dropdownOptions} />
}

SpecificationsDocumentsActions.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
}

export default SpecificationsDocumentsActions
