import { v4 as uuidv4 } from 'uuid'

import {
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS,
  notificationEmailSection,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../../../features/components/notificationFormFields/fields'

export const DO_NOT_SEND = 'do_not_send'

export const getInitialValues = (members = []) => {
  return {
    [USER_OPTION]: members.length ? SEND_TO_PLATFORM_USER : SEND_BY_EMAIL,
    [NOTIFICATION_USERS]: [],
    [NOTIFICATION_EMAILS]: [{ ...notificationEmailSection, id: uuidv4() }]
  }
}
