import { createStyles } from '../../../../../../../styles/helpers'
import { red } from '../../../../../../../styles/const/colors'

export default createStyles({
  datesCol: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  selectedDatesList: {
    listStyle: 'none',
    fontSize: '12px',
    padding: 0
  },
  isNotAvailable: {
    color: red
  }
})
