import React, { useContext, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import Field from '../../../../../../../../../components/Form/Field'
import FieldRow from '../../../../../../../../../features/components/Form/FieldsSection/FieldRow'
import FieldsSection from '../../../../../../../../../features/components/Form/FieldsSection'
import RecipientsTabs from '../../../../../../../../../features/components/notificationFormFields/RecipientsTabs'

import { useRequiredFields } from '../../../hooks/useRequiredFields'

import { formatOptionsList } from '../../../../../../../../../features/formatters'

import { BRAND, CAMPAIGN_NAME } from '../../../fields'
import { MediaOrderFormContext } from '../../../../../../MediaOrderFormContext'

function AdditionalMediaOrderInfo({ formik, askRecipients }) {
  const { contextSelfAccountData } = useContext(MediaOrderFormContext)

  const brands = contextSelfAccountData?.brands

  const brandsOptions = useMemo(() => {
    return formatOptionsList({
      list: brands,
      valueName: 'id',
      labelName: 'brand_name'
    })
  }, [brands])

  const { setFieldValue } = formik

  const { askBrandName, askCampaignName, preselectFirstBrand, isBrandRequired } =
    useRequiredFields(contextSelfAccountData)
  const showBrandName = askBrandName && isBrandRequired
  const showAdditionalFields = showBrandName || askCampaignName || askRecipients

  useEffect(() => {
    if (askBrandName && preselectFirstBrand) {
      // if there is only one brand, dont ask the question and just add it automatically
      setFieldValue(BRAND, brandsOptions[0].value)
    }
  }, [setFieldValue, askBrandName, preselectFirstBrand, brandsOptions])

  return (
    showAdditionalFields && (
      <FieldsSection title="Additional info">
        {askBrandName && isBrandRequired && (
          <FieldRow title="Brand" description="Please select the brand that will be promoted.">
            <Field formik={formik} name={BRAND} options={brandsOptions} placeholder="Brand" />
          </FieldRow>
        )}
        {askCampaignName && (
          <FieldRow title="Campaign name" description="Please enter a campaign name for this booking.">
            <Field formik={formik} name={CAMPAIGN_NAME} placeholder="Campaign name" />
          </FieldRow>
        )}
        {askRecipients && (
          <FieldRow
            title="Confirmation Email Recipient(s)"
            description="Users or email addresses that will receive the confirmation email (including required file specifications if applicable)"
          >
            <RecipientsTabs formik={formik} />
          </FieldRow>
        )}
      </FieldsSection>
    )
  )
}

AdditionalMediaOrderInfo.propTypes = {
  formik: PropTypes.object.isRequired,
  askRecipients: PropTypes.bool
}

export default AdditionalMediaOrderInfo
