import React, { useCallback, useContext } from 'react'
import PropTypes from 'prop-types'

import DateRange from '../../DateRange'

const DateRangeFilter = ({
  Context,
  formatDateRangeLabel,
  dateRangeFilterName = 'dateRangeFilter',
  setDateRangeFilterHandlerName: setDateRangeFilterName = 'setDateRangeFilter',
  placeholder = 'Dates',
  isClearable,
  isHighlighted
}) => {
  const { [dateRangeFilterName]: dateRangeFilter, [setDateRangeFilterName]: setDateRangeFilter } = useContext(Context)

  const onDateRangeChange = useCallback(
    rangeValues => {
      setDateRangeFilter({
        startDate: rangeValues.startDate,
        endDate: rangeValues.endDate
      })
    },
    [setDateRangeFilter]
  )

  return (
    <DateRange
      placeholder={placeholder}
      dateRangeData={dateRangeFilter}
      onDateRangeChange={onDateRangeChange}
      formatDateRangeLabel={formatDateRangeLabel}
      isSelectedValueBrandPrimary={!isHighlighted}
      isClearable={isClearable}
      isHighlighted={isHighlighted}
    />
  )
}

DateRangeFilter.propTypes = {
  Context: PropTypes.object.isRequired,
  formatDateRangeLabel: PropTypes.func,
  dateRangeFilterName: PropTypes.string,
  setDateRangeFilterHandlerName: PropTypes.string,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  isHighlighted: PropTypes.bool
}

export default DateRangeFilter
