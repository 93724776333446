import React from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../../../components/Form/RadioBlock'
import SelfAccountMembersPaginatedMultiSelect from '../../../../../../../features/components/notificationFormFields/SelfAccountMembersPaginatedMultiSelect'
import SectionsList from '../../../../../../../components/SectionsList'
import RecipientEmailSection from '../../../../../../../features/components/notificationFormFields/RecipientEmailSection'

import { DO_NOT_SEND } from '../fields'
import {
  NOTIFICATION_EMAILS,
  notificationEmailSection,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../../../../features/components/notificationFormFields/fields'

const ProposalMediaOrderRecipientsFormContent = ({ formik }) => {
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const allowDelete = values[NOTIFICATION_EMAILS].length > 1

  return (
    <>
      <RadioBlock
        id="platform_user_radio_button"
        name={USER_OPTION}
        value={SEND_TO_PLATFORM_USER}
        selectedValue={values[USER_OPTION]}
        label={t('Select platform user(s)')}
        setFieldValue={setFieldValue}
      >
        <SelfAccountMembersPaginatedMultiSelect formik={formik} />
      </RadioBlock>
      <RadioBlock
        id="send_by_email_radio_button"
        name={USER_OPTION}
        value={SEND_BY_EMAIL}
        selectedValue={values[USER_OPTION]}
        label={t('Send by email')}
        setFieldValue={setFieldValue}
      >
        <SectionsList
          formik={formik}
          fieldPath={NOTIFICATION_EMAILS}
          allowAddAnotherSection={true}
          allowDeleteSection={allowDelete}
          addFirstSectionText="+ Add recipient"
          addAnotherSectionText="+ Add another recipient"
          Section={RecipientEmailSection}
          initialSection={notificationEmailSection}
        />
      </RadioBlock>
      <RadioBlock
        id="do_not_send_radio_button"
        name={USER_OPTION}
        value={DO_NOT_SEND}
        selectedValue={values[USER_OPTION]}
        label={t('Do not send / send later')}
        setFieldValue={setFieldValue}
      />
    </>
  )
}

export default ProposalMediaOrderRecipientsFormContent
