import React, { useCallback } from 'react'
import { getIn } from 'formik'

import SingleFileUpload from '../../../../../ReusableFormFields/SingleFileUpload'

import { FILE, initialDocumentFileValues } from '../../fields'

const fileUploadOptions = { isPrivate: true }
const SpecificationsDocumentUpload = ({ formik }) => {
  const { values, setFieldValue, setValues, errors, touched } = formik

  const fileError = getIn(errors, FILE)
  const fileTouched = getIn(touched, FILE)
  const fileMissingError = fileTouched && fileError

  const handleMediaUploaded = useCallback(
    uploadedFile => {
      setFieldValue(FILE, uploadedFile.url)
    },
    [setFieldValue]
  )

  const handleMediaFileRemove = useCallback(() => {
    setValues({ ...values, ...initialDocumentFileValues })
  }, [setValues, values])

  return (
    <SingleFileUpload
      fileName="PDF File"
      fileURL={values[FILE]}
      onFileUploaded={handleMediaUploaded}
      onFileRemove={handleMediaFileRemove}
      error={fileMissingError}
      accept="application/pdf"
      buttonText="Upload document"
      fileUploadOptions={fileUploadOptions}
    />
  )
}

export default SpecificationsDocumentUpload
