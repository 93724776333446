import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'

import Form from '../../../../components/Form'
import Button from '../../../../components/Button'
import Field from '../../../../components/Form/Field'
import InfoModal from '../InfoModal'

import { ReactComponent as WarningIcon } from '../../../../assets/icons/warning-circle.svg'

import { DISAPPROVED_REASON, initialValues, validationSchema } from './fields'

import useStyles from './styles'

const AskReasonModal = ({
  onSubmit,
  isOpen,
  onClose,
  successSelector,
  errorSelector,
  isLoadingSelector,
  clearFormHandler,
  title,
  placeholder = 'Disapproval reason',
  ModalMessage,
  showCancelButton = true,
  confirmButtonText,
  cancelButtonText,
  onSuccessSubmit
}) => {
  const classes = useStyles()

  const itemWasUpdated = useSelector(successSelector)

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit
  })

  return (
    <InfoModal
      iconClassName={classes.warningIcon}
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={title}
      Icon={WarningIcon}
      ModalMessage={ModalMessage}
    >
      <div className={classes.wrapper}>
        <Form
          formik={formik}
          formName="disapprovalReasonForm"
          errorSelector={errorSelector}
          isLoadingSelector={isLoadingSelector}
          submitText={confirmButtonText}
          className={classes.form}
          successSubmit={itemWasUpdated}
          onSuccessSubmit={onSuccessSubmit}
          clearHandler={clearFormHandler}
          buttonProps={{
            className: classes.formSubmitButton
          }}
        >
          <Field isTextarea name={DISAPPROVED_REASON} formik={formik} placeholder={placeholder} />
        </Form>
        {showCancelButton && (
          <Button type="button" className={classes.cancelButton} onClick={onClose}>
            {cancelButtonText}
          </Button>
        )}
      </div>
    </InfoModal>
  )
}

AskReasonModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  successSelector: PropTypes.func.isRequired,
  errorSelector: PropTypes.func.isRequired,
  isLoadingSelector: PropTypes.func.isRequired,
  clearFormHandler: PropTypes.func.isRequired,
  onSuccessSubmit: PropTypes.func,
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  cancelButtonText: PropTypes.string
}

export default AskReasonModal
