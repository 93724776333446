import { parseISO } from 'date-fns'

import { getInventoryPeriod } from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/periodHelpers'
import { AVAILABLE_QUANTITY } from '../CustomDatePeriodSelector/hooks/useInventoryPeriods'

// find the earliest period start date
export const findEarliestPeriod = periods =>
  periods.reduce(
    (earliest, period) => {
      return new Date(period.date_start) < new Date(earliest.date_start) ? period : earliest
    },
    { date_start: new Date() }
  )

export const formatPeriodOptions = ({ productStartPeriodDate, productPeriod, inventory }) => {
  if (productStartPeriodDate) {
    return inventory.map(
      ({
        id,
        date_start,
        date_end,
        available_inventory,
        product_booked_quantity = 0,
        period_price,
        competing_quotation
      }) => {
        // for case when Product was already purchased in Media order and we amend media order - the inventory
        // should be fulfilled with quantity of purchased periods.
        const availableQuantity = available_inventory + product_booked_quantity
        const hasQuantity = availableQuantity > 0
        const isDisabled = !hasQuantity
        // when there is available quantity and the competing quotation is available
        const competingQuantity = !isDisabled && competing_quotation?.competing_quotation_quantity
        // currently the code is comment out, uncomment if we need to support overdue periods
        // check if the period is under the creative deadline
        // const isCreativeDeadlineOverdue = new Date(date_start) < productStartDate
        // // set newDate time to 00:00:00 to compare only dates
        // const isDateAvailable = new Date(date_start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)
        console.log('date_start', date_start, 'competingQuantity', competingQuantity)

        return {
          inventory: id,
          ...getInventoryPeriod(parseISO(date_start), productPeriod, isDisabled),
          date_start: date_start,
          date_end: date_end,
          disabled: isDisabled,
          // Competing quotations are a way for the user to see if there is another proposal (quotation) targeting the
          // same inventory period for the same product.. and we highlight it if available_inventory - all competing_quotation quantity <=0
          isCompeting: competingQuantity && available_inventory - competingQuantity <= 0,
          competing_quotation,
          [AVAILABLE_QUANTITY]: availableQuantity,
          // If inventory is > 0 and date is >=today but < creative deadline days, show this:
          // currently the code is comment out, uncomment if we need to support overdue periods
          // overdue: hasQuantity && isDateAvailable && isCreativeDeadlineOverdue,
          price: period_price
        }
      }
    )
  } else {
    return []
  }
}
