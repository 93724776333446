import React, { useCallback, useContext, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Calendar } from 'react-date-range'
import { useSelector } from 'react-redux'
import { isBefore, isSameDay } from 'date-fns'
import { useTranslation } from 'react-i18next'

import Dropdown from '../../../../../../../../../../components/Dropdown'
import ActionText from '../../../../../../../../../../components/ActionText'

import useDateRange from './hooks/hooks'
import { getIsMatchDateRange } from '../../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/PeriodsDatePicker/helpers'

import { fetchedInventoryDateRangesSelector } from '../../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { SelectPeriodsContext } from '../../../../SelectPeriodsContext'

import useCommonStyles from '../../DatePickerPeriodSelector/styles'
import useStyles from '../../../../../../../../../../components/DatePicker/styles'

function PeriodsDatePicker({
  periodSelectorClassName,
  allowToFetchPreviousPeriods,
  onDateRangeChange,
  earliestPeriod,
  minDate,
  maxDate,
  onStartDateSelect,
  disabledDates,
  onClose,
  CustomDayComponent
}) {
  const { t } = useTranslation()
  const commonClasses = useCommonStyles()
  const datePickerClasses = useStyles()
  const { isOpen, setIsOpen, checkAndFetchInventoryByDateRange, initialDataFetch } = useContext(SelectPeriodsContext)

  const [startDate, setStartDate] = useState(null)
  // const [endDate, setEndDate] = useState(null)
  const [selectingEndDate, setSelectingEndDate] = useState(false)
  const [shownMonth, setShownMonth] = useState(new Date(initialDataFetch))

  const fetchedInventoryDateRanges = useSelector(fetchedInventoryDateRangesSelector)

  const { weekStartsOn, rangeSelectedTextColor } = useDateRange()

  const handleDropdownVisibility = useCallback(
    show => {
      setIsOpen(show)
      if (isOpen && !show) {
        onClose && onClose()
        setStartDate(null)
        // setEndDate(null)
      }
    },
    [setIsOpen, isOpen, onClose]
  )

  const onShownDateChangeHandler = useCallback(
    dateRange => {
      checkAndFetchInventoryByDateRange(dateRange, allowToFetchPreviousPeriods)
      setShownMonth(dateRange)
    },
    [checkAndFetchInventoryByDateRange, allowToFetchPreviousPeriods]
  )

  const handleSelect = date => {
    if (!startDate) {
      // If no start date or both dates are set, start a new selection
      onStartDateSelect && onStartDateSelect(date)
      setStartDate(date)
      // setEndDate(null)
      setSelectingEndDate(true)
    } else if (selectingEndDate) {
      // If selecting the end date
      if (isBefore(date, startDate)) {
        // If end date is before start date, swap them
        // setEndDate(startDate)
        setStartDate(date)
        onDateRangeChange({ startDate: date, endDate: startDate })
        setIsOpen(false)
        onClose()
      } else {
        onDateRangeChange({ startDate, endDate: date })
        // setEndDate(date)
        setIsOpen(false)
        onClose()
      }
      setStartDate(null)
      // setEndDate(null)
      setSelectingEndDate(false)
    }
  }

  const handleDetectDisabledDates = useCallback(
    date => {
      // don't allow select dates before first fetched period
      return isBefore(date, earliestPeriod) && !isSameDay(date, earliestPeriod)
    },
    [earliestPeriod]
  )

  const renderDayCell = useMemo(() => {
    const isDataFetched = getIsMatchDateRange(fetchedInventoryDateRanges, shownMonth)
    const DaySkeleton = () => <Skeleton width={55} height={36} />

    return isDataFetched ? CustomDayComponent : DaySkeleton
  }, [CustomDayComponent, fetchedInventoryDateRanges, shownMonth])

  return (
    <Dropdown
      // selects should always show the body at the bottom
      allowPlacementFlip={false}
      placement="bottom-end"
      isOpen={isOpen}
      offsetSize={5}
      portaled={true}
      className={datePickerClasses.dropdownBody}
      strategy={'absolute'} // avoid cut when there is no enough space at the bottom of the page
      onOpenChange={handleDropdownVisibility}
      triggerElement={
        <ActionText className={commonClasses.actionText} isDark>
          {t('+ Add booking period')}
        </ActionText>
      }
    >
      <div className={datePickerClasses.dateRangeDropdown}>
        <div className={datePickerClasses.calendar}>
          <Calendar
            // start week day
            weekStartsOn={weekStartsOn}
            onChange={handleSelect}
            className={classnames(datePickerClasses.calendar, periodSelectorClassName)}
            color={rangeSelectedTextColor}
            showDateDisplay={false}
            minDate={minDate && new Date(minDate)}
            maxDate={maxDate && new Date(maxDate)}
            // represent current date in calendar
            direction="horizontal"
            // disable drag selection if there are disabled dates, because drag selection still selects disabled dates
            dragSelectionEnabled={!disabledDates}
            disabledDates={disabledDates}
            disabledDay={handleDetectDisabledDates}
            dayContentRenderer={renderDayCell}
            onShownDateChange={onShownDateChangeHandler}
          />
        </div>
      </div>
    </Dropdown>
  )
}

PeriodsDatePicker.propTypes = {
  periodSelectorClassName: PropTypes.string,
  onDateRangeChange: PropTypes.func.isRequired,
  allowToFetchPreviousPeriods: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  disabledDates: PropTypes.array,
  CustomDayComponent: PropTypes.func,
  onStartDateSelect: PropTypes.func,
  onClose: PropTypes.func
}

export default PeriodsDatePicker
