import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { getIn } from 'formik'
import PropTypes from 'prop-types'

import MinimisedSectionNew from '../../../../components/MinimisedSectionNew'
import MinimisedSectionHeader from '../../../../components/MinimisedSectionNew/MinimisedSectionHeader'
import FieldLabelWrapper from '../../Form/FieldLabelWrapper'
import Field from '../../../../components/Form/Field'

import { EMAIL, FIRST_NAME, NOTIFICATION_EMAILS } from '../fields'

import useStyles from './styles'

const RecipientEmailSection = ({ formik, index, isSelected, onSelect, onClose, onDelete, allowDelete }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { values } = formik

  const sectionTitle = useMemo(() => {
    const email = getIn(values, `${NOTIFICATION_EMAILS}[${index}][${EMAIL}]`)
    return email || `Recipient ${index + 1}`
  }, [values, index])

  const onClickHandler = useCallback(() => {
    if (isSelected) {
      onClose()
    } else {
      onSelect()
    }
  }, [isSelected, onSelect, onClose])

  return (
    <MinimisedSectionNew
      headerContent={<MinimisedSectionHeader title={t(sectionTitle)} onDelete={allowDelete ? onDelete : null} />}
      isOpened={isSelected}
      onHeaderClick={onClickHandler}
    >
      <FieldLabelWrapper
        label={t('First name')}
        labelFor={`${NOTIFICATION_EMAILS}[${index}][${FIRST_NAME}]`}
        className={classes.firstNameFieldWrapper}
      >
        <Field
          placeholder={t('First name')}
          formik={formik}
          name={`${NOTIFICATION_EMAILS}[${index}][${FIRST_NAME}]`}
          type="text"
          autoComplete="given-name"
        />
      </FieldLabelWrapper>
      <FieldLabelWrapper label={t('Email address')} labelFor={`${NOTIFICATION_EMAILS}[${index}][${EMAIL}]`}>
        <Field placeholder={t('Email address')} formik={formik} name={`${NOTIFICATION_EMAILS}[${index}][${EMAIL}]`} />
      </FieldLabelWrapper>
    </MinimisedSectionNew>
  )
}

RecipientEmailSection.propTypes = {
  formik: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  allowDelete: PropTypes.bool.isRequired
}

export default RecipientEmailSection
