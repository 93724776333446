import React, { useCallback, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import ProductsSummaryList from '../../ProductsSummaryList'
import ModifyProductsList from './ModifyProductsList'
import ProgressButton from '../../../../../../../components/Form/ProgressButton'
import FieldsSection from '../../../../../../../features/components/Form/FieldsSection'
import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import AdditionalMediaOrderInfo from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/OrderCartStep/AdditionalMediaOrderInfo'

import usePermissions from '../../../../../../../hooks/usePermissions'
import { useGetSelectedCurrency } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/hooks/useGetSelectedCurrency'

import { MODIFY_QUOTATION_PRICING } from '../../../../../../../constants/permissions'

import { openForm } from '../../../../../../../modules/actions/forms'
import { clearCreateContract } from '../../../../../../../modules/actions/contracts'
import {
  contractWasCreatedSelector,
  createContractsErrorSelector,
  createContractsIsLoadingSelector,
  createdContractSelector
} from '../../../../../../../modules/selectors/contracts'
import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'
import { ROUTE_PARAMS, ROUTES } from '../../../../../../../constants/routes'
import { PROPOSAL_REVIEW } from '../../../../../../../constants/forms'

import useRedirectFormDrawer from '../../../../../../../features/components/FormDrawerWrapper/useRedirectFormDrawer'

import useStyles from './styles'

function OrderCartStep({ formik, isQuotationSubmission, setIsQuotationSubmission }) {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const permissions = usePermissions()
  const classes = useStyles()

  const { contextSelfAccountData } = useContext(MediaOrderFormContext)
  const selfAccountId = contextSelfAccountData?.id

  const createdContractData = useSelector(createdContractSelector)
  const contractWasCreated = useSelector(contractWasCreatedSelector)
  const createContractsError = useSelector(createContractsErrorSelector)
  const createContractsIsLoading = useSelector(createContractsIsLoadingSelector)
  const selectedCurrency = useGetSelectedCurrency()
  const hasEditPricingPermissions = permissions.can('manage', MODIFY_QUOTATION_PRICING)

  const createdContractMediaOrder = createdContractData?.media_order

  const handleFormikSubmit = formik.handleSubmit
  const handleSubmit = useCallback(
    isQuotation => {
      setIsQuotationSubmission(isQuotation)
      // handle submit to run validations and etc..
      handleFormikSubmit()
    },
    [handleFormikSubmit, setIsQuotationSubmission]
  )

  const handleRedirect = useRedirectFormDrawer()

  const handleSuccessSubmit = useCallback(() => {
    handleRedirect(
      {
        [ROUTE_PARAMS.selfAccount]: selfAccountId,
        [ROUTE_PARAMS.mediaOrderId]: createdContractMediaOrder?.id
      },
      ROUTES.mediaOrderSummary
    )
  }, [createdContractMediaOrder?.id, handleRedirect, selfAccountId])

  const handleSuccessQuotationSubmit = useCallback(() => {
    const quotationId = createdContractData?.id
    handleRedirect(
      {
        [ROUTE_PARAMS.selfAccount]: selfAccountId
      },
      `${ROUTES.proposals}?form=${PROPOSAL_REVIEW}&itemId=${quotationId}`
    )
    // pre-open form
    dispatch(openForm({ id: quotationId, formName: PROPOSAL_REVIEW }))
  }, [createdContractData?.id, handleRedirect, selfAccountId, dispatch])

  const clearCreateItemHandler = useCallback(() => {
    setIsQuotationSubmission(false)
    dispatch(clearCreateContract())
  }, [dispatch, setIsQuotationSubmission])

  return (
    <Step stepTitle="Your media booking" stepDescription="Review your chosen media products and book">
      <FieldsSection title="Booking Summary" hasMargin>
        {hasEditPricingPermissions ? (
          <ModifyProductsList formik={formik} currency={selectedCurrency} />
        ) : (
          <ProductsSummaryList formik={formik} selectedCurrency={selectedCurrency} />
        )}
      </FieldsSection>

      <AdditionalMediaOrderInfo formik={formik} askRecipients={true} />

      <div className={classes.buttonsWrapper}>
        <ProgressButton
          onClick={() => handleSubmit(true)}
          successSubmit={isQuotationSubmission ? contractWasCreated : null}
          isFormLoading={isQuotationSubmission ? createContractsIsLoading : null}
          formError={isQuotationSubmission ? createContractsError : null}
          clearHandler={isQuotationSubmission ? clearCreateItemHandler : null}
          onSuccessSubmit={isQuotationSubmission ? handleSuccessQuotationSubmit : null}
          solid={false}
          className={classes.quotation}
          wrapperClassName={classes.buttonWrapper}
          disabled={createContractsIsLoading}
        >
          {t('Save as Proposal')}
        </ProgressButton>
        <ProgressButton
          onClick={() => handleSubmit(false)}
          successSubmit={!isQuotationSubmission ? contractWasCreated : null}
          isFormLoading={!isQuotationSubmission ? createContractsIsLoading : null}
          formError={!isQuotationSubmission ? createContractsError : null}
          clearHandler={!isQuotationSubmission ? clearCreateItemHandler : null}
          onSuccessSubmit={!isQuotationSubmission ? handleSuccessSubmit : null}
          className={classes.quotation}
          wrapperClassName={classes.buttonWrapper}
          disabled={createContractsIsLoading}
        >
          {t('Save')}
        </ProgressButton>
      </div>
    </Step>
  )
}

export default OrderCartStep
