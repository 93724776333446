import { useTranslation } from 'react-i18next'
import React, { useCallback, useMemo } from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

import ActionText from '../../../../../../../../components/ActionText'

import { SELECTED_PERIODS } from '../../../fields'

import useStyles from './styles'

const SelectOrDeselectAllButton = ({ formik }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { values, setFieldValue } = formik

  const theme = useTheme()

  const isAllSelected = useMemo(() => {
    return Object.keys(values[SELECTED_PERIODS]).every(fileId => {
      return Object.keys(values[SELECTED_PERIODS][fileId]).every(mediaId => {
        return values[SELECTED_PERIODS][fileId][mediaId]
      })
    })
  }, [values])

  const customColorSchema = useMemo(() => {
    return {
      color: theme.brandPrimary,
      hoverColor: theme.brandPrimaryHover,
      touchedColor: theme.brandPrimaryHover
    }
  }, [theme])

  const selectAllPeriodsHandler = useCallback(() => {
    const updatedSelectedPeriods = Object.keys(values[SELECTED_PERIODS]).reduce((result, fileId) => {
      result[fileId] = Object.keys(values[SELECTED_PERIODS][fileId]).reduce((mediaResult, mediaId) => {
        mediaResult[mediaId] = true
        return mediaResult
      }, {})
      return result
    }, {})

    setFieldValue(SELECTED_PERIODS, updatedSelectedPeriods)
  }, [setFieldValue, values])

  const deselectAllPeriodsHandler = useCallback(() => {
    const updatedSelectedPeriods = Object.keys(values[SELECTED_PERIODS]).reduce((result, fileId) => {
      result[fileId] = Object.keys(values[SELECTED_PERIODS][fileId]).reduce((mediaResult, mediaId) => {
        mediaResult[mediaId] = false
        return mediaResult
      }, {})
      return result
    }, {})

    setFieldValue(SELECTED_PERIODS, updatedSelectedPeriods)
  }, [setFieldValue, values])

  return (
    <>
      {!isAllSelected && (
        <ActionText
          customColorSchema={customColorSchema}
          onClick={selectAllPeriodsHandler}
          className={classes.mainSelectOrDeselectAllButton}
        >
          {t('Select All')}
        </ActionText>
      )}

      {isAllSelected && (
        <ActionText
          customColorSchema={customColorSchema}
          onClick={deselectAllPeriodsHandler}
          className={classes.mainSelectOrDeselectAllButton}
        >
          {t('Deselect All')}
        </ActionText>
      )}
    </>
  )
}

SelectOrDeselectAllButton.propTypes = {
  formik: PropTypes.object
}

export default SelectOrDeselectAllButton
