import React from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import { ReactComponent as CloudSaveIcon } from '../../../../../assets/icons/cloud-saved.svg'

import { getDaysDiffLabel } from '../../../QuotationsTable/QuotationsTableData/helpers'

import { activeContractSelector, updateContractIsLoadingSelector } from '../../../../../modules/selectors/contracts'

import useStyles from './styles'

function SaveDataDetails() {
  const classes = useStyles()

  const activeContract = useSelector(activeContractSelector)
  const updateContractIsLoading = useSelector(updateContractIsLoadingSelector)

  return (
    <ul className={classes.saveDetailsContainer}>
      <li className={classes.detail}>Created: {getDaysDiffLabel(activeContract?.created)}</li>
      <li className={classes.detail}>Last updated: {getDaysDiffLabel(activeContract?.modified)}</li>
      <li className={classes.detail}>
        <CloudSaveIcon
          className={classnames(classes.saveIcon, {
            [classes.saveIconLoading]: updateContractIsLoading
          })}
        />{' '}
        {updateContractIsLoading ? 'Saving...' : 'Saved'}
      </li>
    </ul>
  )
}

export default SaveDataDetails
