import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  customBookingDateFilter: {
    display: 'flex',
    alignItems: 'center',
    gap: 10
  }
})

export default useStyles
