import React from 'react'
import PropTypes from 'prop-types'
import Skeleton from 'react-loading-skeleton'

import FormStepProgress from '../../features/components/Form/FormStepProgress'

import useStyles from '../../styles/common/stepForms'
import useStepDescriptionStyles from '../../features/components/Forms/StepForm/StepDescription/styles'

function SkeletonForm({ stepsLength, className }) {
  const classes = useStyles()
  const stepDescriptionClasses = useStepDescriptionStyles()

  return (
    <div className={className}>
      {stepsLength && <FormStepProgress stepsLength={stepsLength} activeStepNumber={0} />}
      <div className={classes.stepTitle}>
        <Skeleton width={390} />
      </div>
      <p className={stepDescriptionClasses.stepDescription}>
        <Skeleton />
      </p>
    </div>
  )
}

SkeletonForm.propTypes = {
  className: PropTypes.string,
  stepsLength: PropTypes.number
}
export default SkeletonForm
