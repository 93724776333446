import React from 'react'

import useStyles from './styles'

const LocationCell = ({ location, sub_location }) => {
  const classes = useStyles()

  return (
    <>
      {location && <h4 className={classes.location}>{location}</h4>}
      {sub_location && <p className={classes.subLocation}>{sub_location}</p>}
    </>
  )
}

export default LocationCell
