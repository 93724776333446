import { createStyles } from '../../../../styles/helpers'

const useStyles = createStyles({
  additionalFieldsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 32,
    gap: 16
  },
  fieldWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  }
})

export default useStyles
