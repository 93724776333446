import {
  EMAIL,
  FIRST_NAME,
  NOTIFICATION_EMAILS,
  NOTIFICATION_USERS,
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../../../../features/components/notificationFormFields/fields'

export const transformValuesToBE = (mediaOrder, values) => {
  if (values[USER_OPTION] === SEND_TO_PLATFORM_USER) {
    const { [NOTIFICATION_USERS]: initialNotificationUsers } = mediaOrder
    const initialNotificationUsersIds = initialNotificationUsers?.map(user => user.id)
    const newNotificationUsersIds = values[NOTIFICATION_USERS].map(user => user.value)

    return {
      [NOTIFICATION_USERS]: initialNotificationUsers?.length
        ? [...initialNotificationUsersIds, ...newNotificationUsersIds]
        : newNotificationUsersIds
    }
  } else if (values[USER_OPTION] === SEND_BY_EMAIL) {
    const { [NOTIFICATION_EMAILS]: initialNotificationEmails } = mediaOrder
    const newNotificationEmails = values[NOTIFICATION_EMAILS].map(email => ({
      [EMAIL]: email[EMAIL],
      [FIRST_NAME]: email[FIRST_NAME]
    }))

    return {
      [NOTIFICATION_EMAILS]: initialNotificationEmails?.length
        ? [...initialNotificationEmails, ...newNotificationEmails]
        : newNotificationEmails
    }
  }
  return {}
}
