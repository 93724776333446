import rtl from 'rtl-css-js'
import { createStyles } from '../../styles/helpers'

import { CHIP_COLORS } from '../../constants/other'

import {
  lightGrey,
  lightGrey2,
  darkGrey,
  red,
  transparent,
  lightYellow,
  greyOutline,
  yellow,
  mediumGrey
} from '../../styles/const/colors'
import { transitionShort, zIndex10 } from '../../styles/const/common'
import { tabletDown } from '../../styles/const/breakpoints'

const menuHeight = 300

export const selectValueSize = 14
export const leftSpaceSize = 14

export const useStyles = createStyles(theme => ({
  select: {
    position: 'relative',
    cursor: props => (props && props.isDisabled ? 'not-allowed' : 'pointer'),
    '-webkit-tap-highlight-color': 'transparent'
  },
  title: {
    display: 'block',
    color: darkGrey,
    padding: `0px 6px 1px ${leftSpaceSize}px`,
    fontSize: 12,
    pointerEvents: 'none'
  },
  valueContainer: {
    flex: 1,
    cursor: 'pointer',
    width: props => props && props.isIconSelect && 0,
    opacity: props => props && props.isIconSelect && 0,
    maxWidth: props => (props && (props.showIndicator || props.showClearInputIcon) ? '83%' : '100%')
  },
  menuContainer: {
    maxHeight: 315,
    minWidth: props => props?.menuWidth || 'calc(100% + 4px)',
    overflow: 'hidden',
    position: 'absolute',
    top: '100%',
    right: -2,
    paddingLeft: 2,
    paddingRight: 2,
    zIndex: zIndex10,
    cursor: 'pointer'
  },
  indicatorsWrapper: {
    display: 'flex',
    alignItems: 'center'
  },

  clearInputButton: {
    marginRight: 3
  },
  clearInputIcon: {
    fontSize: [10, '!important'],
    color: mediumGrey,
    marginLeft: 8,
    marginRight: 8,

    '& path': {
      fill: props => (props?.selectProps?.isHighlighted ? '#fff' : 'unset')
    },

    '&:hover': {
      cursor: 'pointer'
    }
  },
  menu: {
    animation: '$fadeIn 0.2s ease-out',
    top: '0 !important',
    position: 'static  !important',
    boxShadow: 'none !important',
    overflow: 'auto',
    border: {
      width: 1,
      style: 'solid',
      color: greyOutline,
      radius: theme.defaultBorderRadius
    }
  },
  '@keyframes fadeIn': {
    '0%': {
      transform: 'translateY(-100px)'
    },
    '100%': {
      transform: 'translateY(0)'
    }
  }
}))

// pseudoDisable/pseudoOpened is used for representing only UI layer of Select
export function getSelectStyles(props) {
  const selectActiveBorderColor = props.theme.selectActiveBorderColor

  const sharedStyles = {
    container: (provided, { isDisabled }) => ({
      ...provided,
      pointerEvents: isDisabled ? 'none' : 'auto'
    }),
    control: (provided, { menuIsOpen, isDisabled }) => ({
      ...provided,
      fontSize: 14,
      minHeight: 38,
      maxHeight: 38,
      borderRadius: props.theme.defaultBorderRadius,
      borderColor: getBorderColor(props, menuIsOpen),
      boxShadow: 'none',
      backgroundColor: !isDisabled ? 'white' : lightGrey2,
      flexWrap: 'no-wrap',
      maxWidth: '100%',

      ':hover': {
        ...provided[':hover'],
        borderColor: props.noBorder ? transparent : selectActiveBorderColor
      },
      ':active': {
        ...provided[':active'],
        borderColor: props.noBorder ? transparent : selectActiveBorderColor
      },
      ':focused': {
        ...provided[':active'],
        borderColor: props.noBorder ? transparent : selectActiveBorderColor
      },
      [`@media screen and (${tabletDown})`]: {
        minHeight: 40,
        maxHeight: 40
      }
    }),
    valueContainer: provided => {
      const styles = {
        ...provided,
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: 0,
        paddingLeft: leftSpaceSize,
        paddingRight: 0,
        cursor: 'pointer'
      }

      return props.theme.textDirection === 'ltr' ? styles : rtl(styles)
    },
    menu: provided => ({
      ...provided,
      marginTop: '2px',
      color: darkGrey,
      borderRadius: props.theme.defaultBorderRadius,
      right: props.menuPosition === 'right' ? 0 : 'unset',
      minWidth: '100%',
      // auto width makes menu container wider then select container if option content doesn't fit
      // the auto width still doesn't cover all of the cases, so for individual cases we use 'max-content' prop
      // which works well
      width: props.expandMenu ? 'max-content' : props.iconStyleSelect ? '150px' : 'auto',
      zIndex: zIndex10
    }),
    input: provided => {
      const styles = {
        ...provided,
        marginTop: 0,
        paddingTop: 0,
        fontSize: 14,
        marginLeft: 0,
        marginBottom: 0,
        paddingBottom: 0,
        ...(props.singleValueStyles || {})
      }

      return props.theme.textDirection === 'ltr' ? styles : rtl(styles)
    },
    singleValue: provided => ({
      ...provided,
      color: darkGrey,
      marginLeft: 0,
      lineHeight: 1,
      fontSize: '12px',
      ...(props.singleValueStyles || {})
    }),
    dropdownIndicator: provided => ({
      ...provided,
      color: props.theme.brandPrimary,
      cursor: 'pointer',
      padding: props.isIconSelect ? '8px 9px' : '8px',
      ':hover': {
        ...provided[':hover'],
        color: selectActiveBorderColor
      },
      [`@media screen and (${tabletDown})`]: {
        padding: props.isIconSelect ? '8px 13px' : '8px'
      }
    }),
    placeholder: provided => {
      const styles = {
        ...provided,
        color: props.isPlaceholderBrandPrimary ? props.theme.brandPrimary : greyOutline,
        fontWeight: props.isPlaceholderBrandPrimary ? 600 : 400,
        marginLeft: '1px',
        fontSize: selectValueSize
      }

      return props.theme.textDirection === 'ltr' ? styles : rtl(styles)
    },
    menuPortal: provided => ({
      ...provided,
      zIndex: zIndex10
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: props.menuHeightLimit || menuHeight,

      '::-webkit-scrollbar': {
        width: '12px'
      },
      '::-webkit-scrollbar-thumb': {
        background: lightGrey2,
        borderRadius: '12px',
        border: 'solid 3px white'
      },
      '::-webkit-scrollbar-track': {
        border: 'solid 3px white'
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: lightGrey
      }
    }),
    option: (provided, { isDisabled, isFocused, isSelected }) => ({
      ...provided,
      color: isDisabled ? lightGrey : darkGrey,
      fontWeight: isSelected ? 'bold' : 'regular',
      fontSize: '12px',
      lineHeight: '16px',
      backgroundColor: isSelected ? yellow : isFocused ? lightYellow : null,
      cursor: 'pointer',
      ':active': {
        ...provided[':active'],
        backgroundColor: !isDisabled && (isSelected ? 'white' : lightYellow),
        fontWeight: !isDisabled && 'bold'
      }
    }),
    indicatorsContainer: (provided, { selectProps: { menuIsOpen } }) => ({
      ...provided,
      transition: transitionShort,
      padding: '0 4px 0 2px',
      transform: menuIsOpen || props.pseudoOpened ? 'rotate(180deg)' : 'rotate(0deg)'
    }),
    indicatorSeparator: provided => ({
      ...provided,
      display: 'none'
    }),
    multiValue: provided => ({
      ...provided,
      padding: '3px 2px 3px 5px'
    }),
    multiValueRemove: (provided, { data }) => ({
      ...provided,
      background: 'rgba(0,0,0,0) !important',
      cursor: 'pointer',
      color: CHIP_COLORS[(data && data.tagColor) || props.tagsColor],

      ':hover': {
        color: selectActiveBorderColor
      }
    })
  }

  return sharedStyles
}

const getBorderColor = (props, menuIsOpen) => {
  const selectActiveBorderColor = props.theme.selectActiveBorderColor

  if (props.isDisabled) {
    return lightGrey2
  } else if (props.noBorder) {
    return transparent
  } else if (props.hasError) {
    return red
  } else if (menuIsOpen || props.pseudoOpened || props.focused || props.isHovered) {
    return selectActiveBorderColor
  } else {
    return greyOutline
  }
}
