import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { bookedMediaSelector } from '../../../../modules/selectors/mediaOrdersBookings'

import useStyles from './styles'

const MediaOrderInfo = () => {
  const classes = useStyles()

  const { t } = useTranslation()

  const bookedMedia = useSelector(bookedMediaSelector)

  const firstMedia = useMemo(() => {
    return bookedMedia[0]
  }, [bookedMedia])

  if (!firstMedia) {
    return null
  }

  return (
    <div className={classes.additionalFieldsContainer}>
      {firstMedia.account_name && (
        <div className={classes.fieldWrapper}>
          <h4>{t('Account')}:</h4>
          {firstMedia.account_name}
        </div>
      )}
      {firstMedia.brand_name && (
        <div className={classes.fieldWrapper}>
          <h4>{t('Brand')}:</h4>
          {firstMedia.brand_name}
        </div>
      )}
      {firstMedia.campaign_name && (
        <div className={classes.fieldWrapper}>
          <h4>{t('Campaign name')}:</h4>
          {firstMedia.campaign_name}
        </div>
      )}
    </div>
  )
}

export default MediaOrderInfo
