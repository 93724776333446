import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import FiltersSearch from '../../../../features/components/Filters/FiltersSearch'
import DateRangeFilter from '../../../../features/components/Filters/DateRangeFilter'
import BookedMediaStatusFilter from '../../../../features/components/Filters/BookedMediaStatusFilter'
import MultipleControllerSelfAccountsFilter from '../../../../features/components/Filters/MultipleControllerSelfAccountsFilter'
import MultipleCategoriesFilters from '../../../../features/components/Filters/MultipleCategoriesFilters'
import MediaProductFilter from '../../../../features/components/Filters/MediaProductFilter'
import BookedMediaDownloadCSV from './BookedMediaDownloadCSV'
import ProductTagsFilter from '../../../../features/components/Filters/ProductTagsFilter'
import BookedMediaLocationsFilter from '../../../../features/components/Filters/BookedMediaLocationsFilter'
import { BookedMediaDataProvider, BookedMediaFiltersContext } from './BookedMediaFiltersContext'
import MultipleSelfAccountCategoriesFilters from '../../../../features/components/Filters/MultipleSelfAccountCategoriesFilters'
import CustomBookingDateFilter from '../../../../features/components/Filters/CustomBookingDateFilter'
import { DesktopUp, DesktopDown } from '../../../../components/hoc/ResponsiveRendering'

import { userSelfAccountTypeSelector } from '../../../../modules/selectors/app'

import { CONTROLLER_TYPE } from '../../../../constants/permissions'

import useStyles from './styles'

export const BOOKED_MEDIA_AND_FILES_SEARCH = 'booked_media_and_files_search'

const BookedMediaAndFilesFilters = ({ onFiltersChange }) => {
  const classes = useStyles()

  const userAccountType = useSelector(userSelfAccountTypeSelector)

  return (
    <BookedMediaDataProvider onFiltersChange={onFiltersChange}>
      <DesktopUp>
        <div className={classes.filtersRow}>
          <FiltersSearch searchId={BOOKED_MEDIA_AND_FILES_SEARCH} className={classes.searchInput} />
          <div className={classes.rightFiltersRow}>
            <BookedMediaDownloadCSV />
            <BookedMediaStatusFilter Context={BookedMediaFiltersContext} />
            {userAccountType === CONTROLLER_TYPE && (
              <MultipleControllerSelfAccountsFilter Context={BookedMediaFiltersContext} />
            )}
            <MultipleCategoriesFilters Context={BookedMediaFiltersContext} />
            <MultipleSelfAccountCategoriesFilters Context={BookedMediaFiltersContext} />
            <MediaProductFilter Context={BookedMediaFiltersContext} />
            <BookedMediaLocationsFilter Context={BookedMediaFiltersContext} />
            <ProductTagsFilter />
            <DateRangeFilter Context={BookedMediaFiltersContext} />
            <CustomBookingDateFilter Context={BookedMediaFiltersContext} />
            {/*this is used for portalled filters - don't remove until check id usage */}
            <div id="booked_media_filters" />
          </div>
        </div>
      </DesktopUp>
      <DesktopDown>
        <div className={classnames(classes.filtersRow, classes.topFiltersRow)}>
          <FiltersSearch searchId={BOOKED_MEDIA_AND_FILES_SEARCH} className={classes.searchInput} />
          <DateRangeFilter Context={BookedMediaFiltersContext} />
        </div>
        <div className={classes.filtersRow}>
          <BookedMediaStatusFilter Context={BookedMediaFiltersContext} />
          {userAccountType === CONTROLLER_TYPE && (
            <MultipleControllerSelfAccountsFilter Context={BookedMediaFiltersContext} />
          )}
          <MultipleCategoriesFilters Context={BookedMediaFiltersContext} />
          <MultipleSelfAccountCategoriesFilters Context={BookedMediaFiltersContext} />
          <MediaProductFilter Context={BookedMediaFiltersContext} />
          <BookedMediaLocationsFilter Context={BookedMediaFiltersContext} />
          <ProductTagsFilter />
          <CustomBookingDateFilter Context={BookedMediaFiltersContext} />

          {/*this is used for portalled filters - don't remove until check id usage */}
          <div id="booked_media_filters" />
        </div>
      </DesktopDown>
    </BookedMediaDataProvider>
  )
}

BookedMediaAndFilesFilters.propTypes = {
  onFiltersChange: PropTypes.func
}

export default BookedMediaAndFilesFilters
