import React from 'react'
import PropTypes from 'prop-types'

import Select from '../Select'
import HighlightedValueSelect from '../HighlightedValueSelect'

const PseudoSelect = ({ isOpen, isHighlighted, ...props }) => {
  // component recreates UI of Select component but not functional it self

  const SelectComponent = isHighlighted ? HighlightedValueSelect : Select

  return (
    <SelectComponent
      pseudoOpened={isOpen}
      formatValue={false}
      isSearchable={false}
      options={[]}
      usePortal
      // PseudoSelect is used as triggerElement for SelectDropdown - so it should not be opened,
      // instead dropdown body will be opened
      menuIsOpen={false}
      hidePlaceholderOnFocus={false}
      isHighlighted={isHighlighted}
      {...props}
    />
  )
}

PseudoSelect.propTypes = {
  // isOpen used for representing UI layer of Select(like dropdownIndicator position)
  isOpen: PropTypes.bool,
  isHighlighted: PropTypes.bool
}

export default PseudoSelect
