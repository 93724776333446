import React, { useContext, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addMonths, endOfMonth, startOfMonth } from 'date-fns'

import { MediaOrderFormContext } from '../../../../MediaOrderFormContext'
import { useCheckAndFetchInventoryByDateRange } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/PeriodsDatePicker/hooks'

import {
  clearGetInventoryGroup,
  clearGetInventoryLastItem,
  clearInventory,
  getInventory,
  getInventoryGroup,
  getInventoryLastItem
} from '../../../../../../../modules/actions/mediaOrdersProducts'
import {
  fetchedInventoryDateRangesSelector,
  inventoryIsLoadingSelector,
  inventoryLastItemWasLoadedSelector,
  inventorySelector
} from '../../../../../../../modules/selectors/mediaOrdersProducts'

import {
  getProductStartDate,
  getProductStartPeriod
} from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/helpers/periodHelpers'
import { formatDateToBE } from '../../../../../../../constants/dates'
import { getIsMatchDateRange } from '../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/PeriodsDatePicker/helpers'
import { createJsonFromQueryString } from '../../../../../../../helpers/url'

export const SelectPeriodsContext = React.createContext()

export const SelectPeriodsContextProvider = ({
  children,
  productData,
  handleCompetingQuotations,
  allowToFetchPreviousPeriods,
  mediaOrderProductId,
  contractId
}) => {
  const dispatch = useDispatch()

  const [isOpen, setIsOpen] = useState(false)
  // const [inventory, setInventory] = useState([])
  // const [inventoryIsLoading, setInventoryIsLoading] = useState(false)
  const inventory = useSelector(inventorySelector)
  const inventoryIsLoading = useSelector(inventoryIsLoadingSelector)
  const fetchedInventoryDateRanges = useSelector(fetchedInventoryDateRangesSelector)
  const inventoryLastItemWasLoaded = useSelector(inventoryLastItemWasLoadedSelector)

  const { currency } = useContext(MediaOrderFormContext)

  const productId = productData.id
  const productPeriod = productData.period
  const isProductGroup = productData.group

  const initialDataFetch = useMemo(() => {
    // ability to automate the period selection based on the inventory dates
    // mode=periodAutoSelect&inventoryFrom=2024-12-01&inventoryTo=2025-01-31&periodStart=2024-12-04&periodEnd=2025-01-29
    const { mode, inventoryFrom } = createJsonFromQueryString(window.location.search)
    if (mode === 'periodAutoSelect') {
      return inventoryFrom
    } else {
      return allowToFetchPreviousPeriods
        ? formatDateToBE(addMonths(startOfMonth(new Date()), -1))
        : formatDateToBE(new Date())
    }
  }, [allowToFetchPreviousPeriods])
  const productStartDate = useMemo(() => getProductStartDate(productData), [productData])
  const productStartPeriodDate = useMemo(
    () => getProductStartPeriod(productPeriod, productStartDate),
    [productPeriod, productStartDate]
  )

  const inventoryRequestParams = useMemo(() => {
    return {
      media_product: productId,
      currency: currency.code,
      ...(handleCompetingQuotations && { competing_quotation: true }), // allows to get competing quotation data
      // allows to avoid competing inventory to itself
      ...(handleCompetingQuotations && { exclude_competing_contract: contractId }),
      // passing product is product ID from media order, this will ask BE to respond with additional
      // product_booked_quantity field which is equivalent to the quantity of booked periods within the media order
      ...(mediaOrderProductId && { product: mediaOrderProductId })
    }
  }, [contractId, currency.code, handleCompetingQuotations, mediaOrderProductId, productId])

  const checkAndFetchInventoryByDateRange = useCheckAndFetchInventoryByDateRange({
    requestParams: inventoryRequestParams
  })

  useEffect(() => {
    const isFetched = getIsMatchDateRange(fetchedInventoryDateRanges, initialDataFetch)

    if (isOpen && !isFetched) {
      // when allowToFetchPreviousPeriods add 2 months to the initialDataFetch as it loads from past to future
      const dateTo = endOfMonth(addMonths(new Date(initialDataFetch), allowToFetchPreviousPeriods ? 2 : 1))

      // ability to automate the period selection based on the inventory dates
      const { mode, inventoryTo } = createJsonFromQueryString(window.location.search)
      const inventoryParams = {
        ...inventoryRequestParams,
        date_from: initialDataFetch,
        date_to: mode === 'periodAutoSelect' ? inventoryTo : formatDateToBE(dateTo)
      }
      if (isProductGroup) {
        dispatch(getInventoryGroup(inventoryParams))
      } else {
        dispatch(getInventory(inventoryParams))
      }
    }
  }, [
    allowToFetchPreviousPeriods,
    currency.code,
    dispatch,
    fetchedInventoryDateRanges,
    initialDataFetch,
    inventoryRequestParams,
    isOpen,
    isProductGroup,
    productId
  ])

  useEffect(() => {
    if (isOpen && !inventoryLastItemWasLoaded) {
      dispatch(
        getInventoryLastItem({
          media_product: productId
        })
      )
    }
  }, [dispatch, inventoryLastItemWasLoaded, isOpen, productId])

  useEffect(() => {
    return () => {
      if (isProductGroup) {
        dispatch(clearGetInventoryGroup())
      } else {
        dispatch(clearInventory())
      }
      dispatch(clearGetInventoryLastItem())
    }
  }, [dispatch, isProductGroup])

  const { code: currencyCode, symbol: currencySymbol } = currency

  return (
    <SelectPeriodsContext.Provider
      value={{
        productId,
        productPeriod,
        currencyCode,
        currencySymbol,
        initialDataFetch,
        productStartPeriodDate,
        allowToFetchPreviousPeriods,
        checkAndFetchInventoryByDateRange,
        isProductGroup,
        inventory,
        inventoryIsLoading,
        isOpen,
        setIsOpen
      }}
    >
      {children}
    </SelectPeriodsContext.Provider>
  )
}
