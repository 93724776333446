import React, { useCallback, useMemo, useState } from 'react'
import { getIn } from 'formik'
import { useTranslation } from 'react-i18next'
import { v4 as uuidv4 } from 'uuid'
import PropTypes from 'prop-types'

import ActionText from '../ActionText'

const SectionsList = ({
  formik,
  fieldPath,
  allowAddAnotherSection = true,
  allowDeleteSection = true,
  addFirstSectionText = 'Add section',
  addAnotherSectionText = 'Add another section',
  Section = <></>,
  initialSection = {},
  sectionProps = {}
}) => {
  const { t } = useTranslation()

  const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)

  const { values, setFieldValue } = formik

  const sections = getIn(values, fieldPath)

  const addAnotherButtonText = useMemo(() => {
    return sections.length > 0 ? addAnotherSectionText : addFirstSectionText
  }, [sections, addAnotherSectionText, addFirstSectionText])

  const handleAddAnotherSection = useCallback(() => {
    // add new initial section to  array
    const currentSections = getIn(values, fieldPath)

    // open new section
    setSelectedSectionIndex(currentSections.length)
    setFieldValue(fieldPath, [...currentSections, { ...initialSection, id: uuidv4() }])
  }, [setFieldValue, values, fieldPath, initialSection])

  const handleDeleteSection = useCallback(
    index => {
      const newSections = [...sections]
      newSections.splice(index, 1)

      // open previous section if it exists
      const nextSelectedIndex = index > 0 ? index - 1 : null
      setSelectedSectionIndex(nextSelectedIndex)
      setFieldValue(fieldPath, newSections)
    },
    [sections, setFieldValue, fieldPath]
  )

  return (
    <>
      {sections.map((section, index) => (
        <Section
          formik={formik}
          index={index}
          key={section.id}
          isSelected={selectedSectionIndex === index}
          onSelect={() => setSelectedSectionIndex(index)}
          onClose={() => setSelectedSectionIndex(null)}
          onDelete={() => handleDeleteSection(index)}
          allowDelete={allowDeleteSection}
          {...sectionProps}
        />
      ))}
      {!!allowAddAnotherSection && (
        <ActionText onClick={handleAddAnotherSection} isDark>
          {t(addAnotherButtonText)}
        </ActionText>
      )}
    </>
  )
}

SectionsList.propTypes = {
  formik: PropTypes.object.isRequired,
  fieldPath: PropTypes.string.isRequired,
  allowAddAnotherSection: PropTypes.bool,
  allowDeleteSection: PropTypes.bool,
  title: PropTypes.string,
  addFirstSectionText: PropTypes.string,
  addAnotherSectionText: PropTypes.string,
  Section: PropTypes.element,
  initialSection: PropTypes.object,
  sectionProps: PropTypes.object
}

export default SectionsList
