import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './styles'

const StepDescription = ({ stepDescription, StepDescriptionRightContent }) => {
  const classes = useStyles()

  if (stepDescription && StepDescriptionRightContent) {
    return (
      <div className={classes.stepDescriptionWrapper}>
        <p className={classes.stepDescription}>{stepDescription}</p>
        {StepDescriptionRightContent}
      </div>
    )
  }

  return <p className={classes.stepDescription}>{stepDescription}</p>
}

StepDescription.propTypes = {
  stepDescription: PropTypes.string,
  StepDescriptionRightContent: PropTypes.node
}

export default StepDescription
