import React from 'react'
import PropTypes from 'prop-types'

import Tabs from '../../Tabs'
import SelfAccountMembersPaginatedMultiSelect from '../SelfAccountMembersPaginatedMultiSelect'
import SectionsList from '../../../../components/SectionsList'
import RecipientEmailSection from '../RecipientEmailSection'

import {
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../forms/Multiplatform/MediaOrderForms/AmendmentContractSendForm/fields'

import { NOTIFICATION_EMAILS, notificationEmailSection } from '../fields'
import { recipientsOptions } from './fields'

import useStyles from './styles'

const RecipientsTabs = ({ formik, selfAccountMembersIdsToFilter = [] }) => {
  const classes = useStyles()
  const { values, setFieldValue } = formik

  const allowDeleteRecipients = values[NOTIFICATION_EMAILS].length > 1

  return (
    <>
      <Tabs
        options={recipientsOptions}
        selectedValue={values[USER_OPTION]}
        onSelectValue={value => setFieldValue(USER_OPTION, value)}
        className={classes.recipientsTabs}
      />
      {values[USER_OPTION] === SEND_TO_PLATFORM_USER ? (
        <SelfAccountMembersPaginatedMultiSelect formik={formik} optionsIdsToFilter={selfAccountMembersIdsToFilter} />
      ) : (
        <SectionsList
          formik={formik}
          fieldPath={NOTIFICATION_EMAILS}
          allowAddAnotherSection={true}
          allowDeleteSection={allowDeleteRecipients}
          addFirstSectionText="+ Add recipient"
          addAnotherSectionText="+ Add another recipient"
          Section={RecipientEmailSection}
          initialSection={notificationEmailSection}
        />
      )}
    </>
  )
}

RecipientsTabs.propTypes = {
  formik: PropTypes.object,
  selfAccountMembersIdsToFilter: PropTypes.arrayOf(PropTypes.number)
}

export default RecipientsTabs
