import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

import ContentSection from '../../../features/components/ContentSection'
import ContentRow from '../../../features/components/ContentSection/ContentRow'
import ProductsTable from './ProductsTable'
import MediaProductCreate from '../../../forms/Multiplatform/MediaProductForms/MediaProductCreate'
import MediaProductEdit from '../../../forms/Multiplatform/MediaProductForms/editForms/MediaProductEdit'
import CreateProductBtn from './ProductsTable/CreateProductBtn'
import ProductInventory from './ProductInventory'
import ProductTags from './ProductTags'
import ProductLocations from './ProductLocations'
import ProductVariables from './ProductVariables'
import MediaProductsFilters from '../../../features/components/mediaProductsComponents/MediaProductsFilters'
import MediaProductGroupCreate from '../../../forms/Multiplatform/MediaProductForms/MediaProductGroupCreate'
import MediaProductGroupEdit from '../../../forms/Multiplatform/MediaProductForms/editForms/MediaProductGroupEdit'
import MediaProductSpecificationsDocuments from './SpecificationsDocuments'

import { useLoadFilteredAndPaginatedProducts } from '../../../features/hooks/useLoadFilteredAndPaginatedProducts'

import { clearDeleteProduct } from '../../../modules/actions/mediaOrdersProducts'
import { selectedControllerIdSelector } from '../../../modules/selectors/app'
import { deleteProductSelector, productsSelector } from '../../../modules/selectors/mediaOrdersProducts'

import useSettingsClasses from '../styles'

const MediaProducts = () => {
  const settingsClasses = useSettingsClasses()

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const products = useSelector(productsSelector)
  const { wasDeleted: productWasDeleted } = useSelector(deleteProductSelector)
  const controllerId = useSelector(selectedControllerIdSelector)

  const { loadMoreProducts, filters } = useLoadFilteredAndPaginatedProducts()

  useEffect(() => {
    if (productWasDeleted) {
      dispatch(clearDeleteProduct())
    }
  }, [dispatch, productWasDeleted])

  return (
    <>
      <h1 className={settingsClasses.title}>{t('Media Products')}</h1>
      <ContentSection title="Media Products">
        <ContentRow
          title="Product list"
          description="Manage media products and inventory."
          leftColumnChildren={<CreateProductBtn />}
        >
          <div className={settingsClasses.dataContent}>
            <MediaProductsFilters filters={filters} showExportProductsAsCsv={true} showSearchField={true} />
            <ProductsTable products={products} controllerId={controllerId} loadMoreHandler={loadMoreProducts} />
          </div>
        </ContentRow>
        <ProductLocations />
        <ProductVariables />
        <ProductTags />
        <MediaProductSpecificationsDocuments />
      </ContentSection>

      <MediaProductCreate controllerId={controllerId} />
      <MediaProductGroupCreate controllerId={controllerId} />
      <MediaProductEdit />
      <MediaProductGroupEdit />
      <ProductInventory />
    </>
  )
}

export default MediaProducts
