import { createStyles } from '../helpers'
import { drawerFormTitle } from './drawerForms'
import { formFooter } from '../../components/Form/styles'
import { phonesDown } from '../const/breakpoints'

const useStyles = createStyles({
  stepTitle: {
    extend: drawerFormTitle
  },
  stepBody: {
    marginTop: 24,
    '& .checkbox:not(:first-child)': {
      marginTop: 24
    }
  },
  stepFooter: {
    extend: formFooter
  },
  separateCheckbox: {
    marginLeft: 14,
    marginTop: '28px !important'
  },
  [`@media screen and (${phonesDown})`]: {
    stepBody: {
      marginTop: 32
    },
    stepTitle: {
      maxWidth: '100%'
    },
    description: {
      maxWidth: '100%'
    }
  }
})

export default useStyles
