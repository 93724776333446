import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectedControllerDataSelector } from '../../../../../../../modules/selectors/app'

export function useRequiredFields(selfAccountData) {
  const selectedController = useSelector(selectedControllerDataSelector)

  const askCampaignName = selectedController?.ask_campaign_name
  // If there are non but ask_brand_name is TRUE, ignore it and dont ask or add a brand name.
  const askBrandName = selectedController?.ask_brand_name
  // If there is at least one brand name, show a dropdown and require the user to select one
  const isBrandRequired = selfAccountData?.brands?.length >= 1
  // if there is only one brand name, automatically preselect it
  const preselectFirstBrand = selfAccountData?.brands?.length === 1
  const askBuyer = selectedController?.ask_buyer
  return useMemo(() => {
    return { askBrandName, askCampaignName, isBrandRequired, preselectFirstBrand, askBuyer }
  }, [askBrandName, askCampaignName, isBrandRequired, preselectFirstBrand, askBuyer])
}
