import { createStyles } from '../../../../../../styles/helpers'
import { phonesDown, tabletDown } from '../../../../../../styles/const/breakpoints'

export const MEDIA_ORDER_ACTIONS_MAX_WIDTH = 500

const useStyles = createStyles({
  mediaOrderActions: {
    position: 'absolute',
    maxWidth: MEDIA_ORDER_ACTIONS_MAX_WIDTH,
    right: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16
  },
  [`@media screen and (${tabletDown})`]: {
    mediaOrderActions: {
      maxWidth: 'unset',
      position: 'unset',
      marginBottom: 16
    }
  },
  [`@media screen and (${phonesDown})`]: {
    mediaOrderActions: {
      flexDirection: 'column'
    }
  }
})

export default useStyles
