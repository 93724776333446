import React from 'react'

import Can from '../../../../features/components/Can'
import { BookingsFiltersContext } from './BookingsFiltersContext'
import MultipleControllerSelfAccountsFilter from '../../../../features/components/Filters/MultipleControllerSelfAccountsFilter'
import DateRangeFilter from '../../../../features/components/Filters/DateRangeFilter'
import MultipleControllerMembersFilter from '../../../../features/components/Filters/MultipleControllerMembersFilter'
import MediaOrderDownloadCSV from '../../BookedMediaAndFiles/BookedMediaAndFilesFilters/MediaOrderDownloadCSV'

import { formatCreatedDateRangeLabel, formatDateRangeLabel } from './helpers'

import { BOOKINGS_SELF_ACCOUNT_FILTER } from '../../../../constants/permissions'

import useStyles from './styles'

const BookingsFilters = () => {
  const classes = useStyles()

  return (
    <div className={classes.filtersRow}>
      <div className={classes.rightFiltersRow}>
        <Can I="manage" a={BOOKINGS_SELF_ACCOUNT_FILTER}>
          <MediaOrderDownloadCSV />
          <DateRangeFilter
            Context={BookingsFiltersContext}
            formatDateRangeLabel={formatCreatedDateRangeLabel}
            dateRangeFilterName="createdDateRangeFilter"
            setDateRangeFilterHandlerName="setCreatedDateRangeFilter"
            placeholder={'Created date'}
            isClearable={true}
            isHighlighted={true}
          />
          <DateRangeFilter
            Context={BookingsFiltersContext}
            formatDateRangeLabel={formatDateRangeLabel}
            placeholder={'Go live date'}
            isClearable={true}
            isHighlighted={true}
          />
          <MultipleControllerMembersFilter Context={BookingsFiltersContext} />
          <MultipleControllerSelfAccountsFilter Context={BookingsFiltersContext} />
        </Can>
      </div>
    </div>
  )
}

export default BookingsFilters
