import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useContext, useMemo } from 'react'

import { MediaOrderFormContext } from '../../../MediaOrderFormContext'

import { updateContract } from '../../../../../../modules/actions/contracts'
import { activeContractSelector } from '../../../../../../modules/selectors/contracts'
import { selectedControllerIdSelector } from '../../../../../../modules/selectors/app'

import { MEDIA_PRODUCTS } from '../../fields'
import { transformValuesToBE } from '../../formatters'

export default function useSaveData({ values, allowEdit, allowAutoSave }) {
  const dispatch = useDispatch()
  const activeContract = useSelector(activeContractSelector)
  const controllerId = useSelector(selectedControllerIdSelector)
  const createdContractId = activeContract?.id

  const { contextSelfAccountData, currency } = useContext(MediaOrderFormContext)

  const handleSaveDataToBE = useCallback(
    ({ updatedProductValues }) => {
      if (createdContractId && allowEdit && allowAutoSave) {
        // update contract only if it was created
        // the contract creating is made in the onSubmit function
        const transformedData = transformValuesToBE({
          selfAccountData: contextSelfAccountData,
          controllerId,
          selectedCurrency: currency,
          values: {
            ...values,
            [MEDIA_PRODUCTS]: updatedProductValues
          }
        })

        const contractData = {
          detail: transformedData,
          // in this case the PDF file generation will occur in the background and will not affect the response time
          // as we are not using the PDF file for contract builder, we can set this to false
          generate_pdf: false,
          // the speed of the request
          quotation: true, // BE need to get it passed for correct work
          without_requirements: true, // allows to create contract without terms
          set_amendment_cost: true, // allows to set modified pricing
          account: contextSelfAccountData.id,
          controller: controllerId
        }

        dispatch(updateContract(createdContractId, contractData))
      }
    },
    [createdContractId, allowEdit, allowAutoSave, contextSelfAccountData, controllerId, currency, values, dispatch]
  )

  return useMemo(
    () => ({
      handleSaveDataToBE
    }),
    [handleSaveDataToBE]
  )
}
