import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { parseISO } from 'date-fns'

import DiscountsSuggestions from './DiscountsSuggestions'
import BookingPeriodSelector from './BookingPeriodSelector'
import ErrorMessage from '../../../../../../../../../../components/Form/ErrorMessage'

import { formatCurrency } from '../../../../../../../../../../helpers/numbers'
import { getInventoryPeriod } from '../../../../helpers/periodHelpers'

import { CAMPAIGN_START_PERIOD_OPTION } from '../../../../fields'
import { SelectPeriodsFieldsContext } from './SelectPeriodsFieldsContextProvider'
import { PRODUCT_PERIODS_DATES } from '../../../../../../../fields'
import { AVAILABLE_QUANTITY } from '../../../../../../../ContractBuilder/ProductsManage/ProductsTable/DatesCell/EditPeriods/DatePickerPeriodSelector/hooks/useInventoryPeriods'

function SelectPeriodsFields({ formik, productItemPath, showError, error }) {
  const { values } = formik

  const { inventory, inventoryIsLoading, productPeriod, currencySymbol, productDiscounts } =
    useContext(SelectPeriodsFieldsContext)

  const hasDiscounts = !!productDiscounts?.length

  const productPublicationsDatesPath = `${productItemPath}.${PRODUCT_PERIODS_DATES}`
  const publicationQuantity = getIn(values, productPublicationsDatesPath)?.length

  // periodOptions are generated based on product period
  const periodOptions = useMemo(() => {
    const selectedStartPeriod = getIn(values, `${productItemPath}.${CAMPAIGN_START_PERIOD_OPTION}`)
    const selectedStartPeriodDate = parseISO(selectedStartPeriod)
    const disabledText = ' (Unavailable)'

    if (selectedStartPeriodDate) {
      return inventory.map(
        ({ id, date_start, date_end, available_inventory, product_booked_quantity = 0, period_price }) => {
          // for case when Product was already purchased in Media order and we amend media order - the inventory
          // should be fulfilled with quantity of purchased periods.
          const availableQuantity = available_inventory + product_booked_quantity
          const hasQuantity = availableQuantity > 0
          const isDisabled = !hasQuantity
          // check if the period is under the creative deadline
          const isCreativeDeadlineOverdue = new Date(date_start) < selectedStartPeriodDate
          // // set newDate time to 00:00:00 to compare only dates
          const isDateAvailable = new Date(date_start).setHours(0, 0, 0, 0) >= new Date().setHours(0, 0, 0, 0)

          return {
            inventory: id,
            ...getInventoryPeriod(parseISO(date_start), productPeriod, isDisabled),
            date_start: date_start,
            date_end: date_end,
            disabled: isDisabled,
            disabledText: isDisabled && disabledText,
            [AVAILABLE_QUANTITY]: availableQuantity,
            // If inventory is > 0 and date is >=today but < creative deadline days, show this:
            overdue: hasQuantity && isDateAvailable && isCreativeDeadlineOverdue,
            price: period_price,
            tag: formatCurrency(period_price, { max: 0 }, { symbol: currencySymbol })
          }
        }
      )
    } else {
      return []
    }
  }, [values, productPeriod, productItemPath, inventory, currencySymbol])

  return (
    <>
      {!!periodOptions.length || inventoryIsLoading ? (
        <BookingPeriodSelector
          formik={formik}
          periodOptions={periodOptions}
          productItemPath={productItemPath}
          productPublicationsDatesPath={productPublicationsDatesPath}
          showError={showError}
          error={error}
        />
      ) : (
        <ErrorMessage error="Sorry, there is no available inventory for this media product" />
      )}
      {hasDiscounts && <DiscountsSuggestions publicationQuantity={publicationQuantity} />}
    </>
  )
}

SelectPeriodsFields.propTypes = {
  formik: PropTypes.object,
  productItemPath: PropTypes.string,
  productPublicationsDatesPath: PropTypes.string,
  showError: PropTypes.bool,
  error: PropTypes.string
}

export default SelectPeriodsFields
