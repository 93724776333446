import React from 'react'

import ContentSection from '../../../../../features/components/ContentSection'
import ContentRow from '../../../../../features/components/ContentSection/ContentRow'
import Field from '../../../../../components/Form/Field'
import SpecificationsDocumentUpload from './SpecificationsDocumentUpload'

import { FILE_NAME } from '../fields'

const SpecificationsDocumentFormContent = ({ formik }) => {
  return (
    <ContentSection title="Specifications document">
      <ContentRow title="File name">
        <Field formik={formik} id={FILE_NAME} name={FILE_NAME} placeholder="File name" />
      </ContentRow>
      <ContentRow title="File">
        <SpecificationsDocumentUpload formik={formik} />
      </ContentRow>
    </ContentSection>
  )
}

export default SpecificationsDocumentFormContent
