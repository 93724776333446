import { createStyles } from '../../../../../styles/helpers'

import { drawerFormDescription } from '../../../../../styles/common/drawerForms'

const useStyles = createStyles({
  stepDescriptionWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 14
  },
  stepDescription: {
    extend: drawerFormDescription
  }
})

export default useStyles
