import React from 'react'

import FormDrawerWrapper from '../../../../../features/components/FormDrawerWrapper'
import SpecificationsDocumentCreateForm from './SpecificationsDocumentCreateForm'

import { SPECIFICATIONS_DOCUMENT_CREATE } from '../../../../../constants/forms'

function SpecificationsDocumentCreate() {
  return (
    <FormDrawerWrapper
      formName={SPECIFICATIONS_DOCUMENT_CREATE}
      title="Upload specifications document"
      description="Specifications PDF files that will be combined and attached to a booking confirmation email that detail the creative specifications for the booked media products."
      openButtonText="Upload PDF File"
      closeOnSubmit={true}
    >
      <SpecificationsDocumentCreateForm />
    </FormDrawerWrapper>
  )
}

export default SpecificationsDocumentCreate
