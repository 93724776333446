import React, { useCallback, useContext, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { getIn } from 'formik'
import { useSelector } from 'react-redux'
import { format, isWithinInterval } from 'date-fns'

import PeriodsDateRange from '../Reusable/PeriodsDateRange'
import PeriodsDatePicker from '../Reusable/PeriodsDatePicker'
import SelectedPeriodsList from '../Reusable/SelectedPeriodsList'
import ErrorMessage from '../../../../../../../../../components/Form/ErrorMessage'
import PeriodDateCell from '../../../../../../../../../features/components/MediaOrder/PeriodDateCell'

import { SelectPeriodsContext } from '../../../SelectPeriodsContext'
import { ContractBuilderContext } from '../../../../ContractBuilderContext'
import { useGetDisabledDates } from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/hooks'
import { useInventoryPeriods } from './hooks/useInventoryPeriods'

import calculateProductPrice from '../../../helpers/calculateProductPrice'
import { formatDateToBE } from '../../../../../../../../../constants/dates'
import { sortByStartDateValue } from '../../../../../../MediaOrderCreate/MediaOrderCreateForm/ContractCreateForm/Steps/ProductSetupStep/ProductSetupFields/SelectPeriodsFields/BookingPeriodSelector/DatePickerPeriodSelector/helpers'
import { inventoryLastItemSelector } from '../../../../../../../../../modules/selectors/mediaOrdersProducts'

import { CUSTOM_PRODUCT_PRICE, ORIGINAL_PRODUCT_PRICE, QUANTITY } from '../../../../../fields'
import { PERIOD_DAY } from '../../../../../../../../../constants/mediaOrders'
import { PRODUCT_GROUPED_PUBLICATIONS_DATES } from '../../../../../../fields'

import useStyles from './styles'

// This component allows to select booking period for the product using the date range
// Each of selected date range is represented as a group of periods, in meanwhile we still manage the selected periods separately
// The grouped periods are used ONLY to display the selected date range in this component
// The selected periods are used to manage the selected dates in the form, send to BE and etc..
function DatePickerPeriodSelector({
  formik,
  isNewProduct,
  selectedPeriods,
  productItemPath,
  productPublicationsDatesPath,
  checkInventory
}) {
  const classes = useStyles()
  const { values, setFieldValue, errors, touched } = formik

  const [selectedStartDate, setSelectedStartDate] = useState(null)
  const [isEndDateSelection, setIsEndDateSelection] = useState(false)

  const { skipCostOnPeriodsChange } = useContext(ContractBuilderContext)
  const shouldUpdateCustomCost = isNewProduct || !skipCostOnPeriodsChange
  const { allowToFetchPreviousPeriods, productPeriod, currencySymbol } = useContext(SelectPeriodsContext)
  const inventoryLastItem = useSelector(inventoryLastItemSelector)

  const quantity = getIn(values, `${productItemPath}.${QUANTITY}`)
  const selectedGroupedPeriods = getIn(values, `${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`)

  const { periodOptions, firstPeriod, lastPeriod, availablePeriods } = useInventoryPeriods({
    selectedPeriods,
    selectedStartDate,
    isEndDateSelection
  })
  const disabledDates = useGetDisabledDates({
    firstPeriod,
    lastPeriod,
    availablePeriods,
    isEndDateSelection
  })

  const handleClose = useCallback(() => {
    setIsEndDateSelection(false)
  }, [])

  const handleStartDateSelect = useCallback(startDate => {
    setSelectedStartDate(startDate)
    setIsEndDateSelection(true)
  }, [])

  const setNewPeriods = useCallback(
    newPeriods => {
      // push new periods to manage the selected dates in the form, send to BE and etc..
      // newly added periods should not just be pushed, these should be set by startDate order
      const sortedNewPeriods = [...selectedPeriods, ...newPeriods].sort(sortByStartDateValue)
      // push new group of periods to represent selected date range
      const sortedNewGroupPeriods = [
        ...selectedGroupedPeriods,
        // push group of periods to already selected groups
        newPeriods
        // newly added periods should not just be pushed, these should be set by startDate order
      ].sort((a, b) => new Date(a[0].date_start) - new Date(b[0].date_start))

      setFieldValue(productPublicationsDatesPath, sortedNewPeriods, false)
      setFieldValue(`${productItemPath}.${PRODUCT_GROUPED_PUBLICATIONS_DATES}`, sortedNewGroupPeriods, false)
      // reset prices:
      const calculatedPrice = calculateProductPrice(sortedNewPeriods, 1, 0)
      setFieldValue(`${productItemPath}.${ORIGINAL_PRODUCT_PRICE}`, calculatedPrice.totalPrice, false)
      shouldUpdateCustomCost &&
        setFieldValue(`${productItemPath}.${CUSTOM_PRODUCT_PRICE}`, calculatedPrice.totalPrice, false)
      // reset quantity to 1 when user is setting up periods to avoid inconsistency when there are not enough
      setFieldValue(`${productItemPath}.${QUANTITY}`, 1)
    },
    [
      selectedGroupedPeriods,
      selectedPeriods,
      setFieldValue,
      productItemPath,
      productPublicationsDatesPath,
      shouldUpdateCustomCost
    ]
  )

  const handleDaysPeriodSelection = useCallback(
    ({ endDate, startDate }) => {
      const dateRangeStart = startDate.setHours(0, 0, 0, 0)
      const dateRangeEnd = endDate.setHours(0, 0, 0, 0)

      // select available periods data between selected date range
      const newPeriods = availablePeriods.filter(period => {
        const startPeriod = new Date(period.date_start).setHours(0, 0, 0, 0)
        const endPeriod = new Date(period.date_end).setHours(0, 0, 0, 0)

        const isStartPeriodInDateRange = isWithinInterval(startPeriod, { start: dateRangeStart, end: dateRangeEnd })
        const isEndPeriodInDateRange = isWithinInterval(endPeriod, { start: dateRangeStart, end: dateRangeEnd })

        // return only periods that are within selected date range
        return isStartPeriodInDateRange && isEndPeriodInDateRange
      })

      setNewPeriods(newPeriods)
    },
    [availablePeriods, setNewPeriods]
  )
  const minDate = useMemo(() => {
    if (isEndDateSelection) {
      return selectedStartDate
    } else {
      return firstPeriod ? new Date(firstPeriod) : new Date()
    }
  }, [firstPeriod, isEndDateSelection, selectedStartDate])
  const maxDate = useMemo(() => {
    return inventoryLastItem?.date_end ? new Date(inventoryLastItem?.date_end) : undefined
  }, [inventoryLastItem])

  const CustomDayComponent = useCallback(
    dayDate => {
      const formattedDate = format(dayDate, 'dd')
      // for performance reason we pass only formatted dates as well as make the search for the periodData only inside
      // when the data actually changed
      return (
        <PeriodDateCell
          currencySymbol={currencySymbol}
          formattedDate={formattedDate}
          formattedDateBE={formatDateToBE(dayDate)}
          periodOptions={periodOptions}
        />
      )
    },
    [periodOptions, currencySymbol]
  )

  const commonProps = useMemo(
    () => ({
      allowToFetchPreviousPeriods: allowToFetchPreviousPeriods,
      minDate: allowToFetchPreviousPeriods ? undefined : minDate,
      maxDate: maxDate,
      disabledDates: disabledDates,
      earliestPeriod: firstPeriod ? new Date(firstPeriod) : new Date(),
      // UI:
      periodSelectorClassName: classes.periodSelector,
      CustomDayComponent: CustomDayComponent,
      // callbacks
      onDateRangeChange: handleDaysPeriodSelection,
      onStartDateSelect: handleStartDateSelect,
      onClose: handleClose
    }),
    [
      allowToFetchPreviousPeriods,
      minDate,
      maxDate,
      disabledDates,
      firstPeriod,
      classes.periodSelector,
      CustomDayComponent,
      handleDaysPeriodSelection,
      handleStartDateSelect,
      handleClose
    ]
  )
  const error = getIn(errors, productPublicationsDatesPath)
  const wasTouched = getIn(touched, productPublicationsDatesPath)

  return (
    <>
      <SelectedPeriodsList
        setFieldValue={setFieldValue}
        isNewProduct={isNewProduct}
        selectedQuantity={quantity}
        selectedPeriods={selectedPeriods}
        selectedGroupedPeriods={selectedGroupedPeriods}
        productItemPath={productItemPath}
        productPublicationsDatesPath={productPublicationsDatesPath}
        checkInventory={checkInventory}
      />
      {productPeriod === PERIOD_DAY ? <PeriodsDateRange {...commonProps} /> : <PeriodsDatePicker {...commonProps} />}
      {wasTouched && error && <ErrorMessage error={error} />}
    </>
  )
}

DatePickerPeriodSelector.propTypes = {
  formik: PropTypes.object.isRequired,
  isNewProduct: PropTypes.bool.isRequired,
  productItemPath: PropTypes.string.isRequired,
  productPublicationsDatesPath: PropTypes.string.isRequired,
  selectedPeriods: PropTypes.array.isRequired,
  checkInventory: PropTypes.bool.isRequired
}
export default DatePickerPeriodSelector
