import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import Toggle from '../../../../components/Toggle'

import useStyles from './styles'

const CustomBookingDateFilter = ({ Context }) => {
  const classes = useStyles()
  const { t } = useTranslation()

  const { customBookingDate, setCustomBookingDate } = useContext(Context)

  const onToggleHandler = useCallback(() => {
    setCustomBookingDate(prevState => !prevState)
  }, [setCustomBookingDate])

  return (
    <div className={classes.customBookingDateFilter}>
      <Toggle onToggle={onToggleHandler} isInitiallyEnabled={customBookingDate} />
      <span>{t('Custom booking date')}</span>
    </div>
  )
}

export default CustomBookingDateFilter
