import React from 'react'
import PropTypes from 'prop-types'

import Step from '../../../../../../../features/components/Forms/StepForm/Step'
import ProductSection from './ProductSection'
import SelectOrDeselectAllButton from './SelectOrDeselectAllButton'

function PeriodStep({ formik, availableProducts }) {
  return (
    <Step
      stepTitle="Select which media products and periods to upload this file to"
      stepDescription="You can apply this file to one or more of your booked media periods at once"
      StepDescriptionRightContent={<SelectOrDeselectAllButton formik={formik} />}
    >
      {availableProducts.map(product => {
        return <ProductSection formik={formik} product={product} key={product.id} />
      })}
    </Step>
  )
}

PeriodStep.propTypes = {
  formik: PropTypes.object,
  availableProducts: PropTypes.array
}

export default PeriodStep
