import * as Yup from 'yup'

import {
  BOOKED_QUANTITY,
  INVENTORY_LIST_DATA,
  INVENTORY_QUANTITY,
  INVENTORY_START_DATE
} from '../../../MediaProductForms/MediaProductCreate/MediaProductCreateForm/fields'

export const validationSchema = Yup.object({
  [INVENTORY_LIST_DATA]: Yup.array().of(
    Yup.object()
      .shape({
        [INVENTORY_START_DATE]: Yup.string().required('Start Date Required'),
        [INVENTORY_QUANTITY]: Yup.number().test({
          name: 'Custom inventory quantity validation',
          test: (value, context) => {
            const { parent: values } = context
            const bookedQuantity = values[BOOKED_QUANTITY]

            if (!value) {
              // allow > 0
              return context.createError({ message: 'Required' })
            }

            if (value < bookedQuantity) {
              return context.createError({ message: 'Inventory must be equal to or greater than the booked quantity' })
            }

            return true
          }
        })
      })
      // We allow null values, because when we delete item we set it to null
      .nullable()
  )
})
