import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import RadioBlock from '../../../../../components/Form/RadioBlock'
import Field from '../../../../../components/Form/Field'

import { formatOptionsList } from '../../../../../features/formatters'
import { createJsonFromQueryString } from '../../../../../helpers/url'
import { getMembersListService } from '../../../../../modules/services/selfAccountTeam'

import { EMAIL_RECEIVER, PLATFORM_USER_RECEIVER } from '../fields'
import {
  SEND_BY_EMAIL,
  SEND_TO_PLATFORM_USER,
  USER_OPTION
} from '../../../../../features/components/notificationFormFields/fields'

const defaultOptions = []
const QuotationSendFormContent = ({ formik, selfAccountId }) => {
  const { t } = useTranslation()

  const [isDataLoading, setIsDataLoading] = React.useState(false)
  const formattedSelfAccountTeamMembers = useCallback(selfAccountTeamMembers => {
    return formatOptionsList({
      list: selfAccountTeamMembers,
      labelName: 'full_name',
      valueName: 'user'
    })
  }, [])

  const loadOptions = useCallback(
    async (search, loadedOptions, { next }) => {
      setIsDataLoading(true)
      const response = await getMembersListService({
        search,
        ordering: 'name',
        account: selfAccountId,
        fields: ['full_name', 'user'].join(','),
        ...createJsonFromQueryString(`?${next.split('?')[1]}`)
      })
      const newProductsOptions = formattedSelfAccountTeamMembers(response.results)

      setIsDataLoading(false)

      return {
        options: newProductsOptions,
        hasMore: !!response.next,
        additional: {
          next: response.next
        }
      }
    },
    [formattedSelfAccountTeamMembers, selfAccountId]
  )

  const { values, setFieldValue } = formik

  return (
    <>
      <RadioBlock
        id="platform_user_radio_button"
        name={USER_OPTION}
        value={SEND_TO_PLATFORM_USER}
        selectedValue={values[USER_OPTION]}
        label={t('Select user from our platform')}
        setFieldValue={setFieldValue}
      >
        <Field
          formik={formik}
          name={PLATFORM_USER_RECEIVER}
          placeholder={'Please select user'}
          loadOptions={loadOptions}
          isLoading={isDataLoading}
          shouldFetchDefaultOptions={true}
          options={defaultOptions}
        />
      </RadioBlock>
      <RadioBlock
        id="send_by_email_radio_button"
        name={USER_OPTION}
        value={SEND_BY_EMAIL}
        selectedValue={values[USER_OPTION]}
        label={t('Send by email')}
        setFieldValue={setFieldValue}
      >
        <Field formik={formik} name={EMAIL_RECEIVER} placeholder="Please enter email" />
      </RadioBlock>
    </>
  )
}

export default QuotationSendFormContent
